import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Square from './Square';
import Piece from './Piece';

const DIMENSION = 8;
const COLUMN_NAMES = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];

const fenToData = fen => {
  let pos = fen.split('_')[0];
  pos = pos.replace(/1/g, '.');
  pos = pos.replace(/2/g, '..');
  pos = pos.replace(/3/g, '...');
  pos = pos.replace(/4/g, '....');
  pos = pos.replace(/5/g, '.....');
  pos = pos.replace(/6/g, '......');
  pos = pos.replace(/7/g, '.......');
  pos = pos.replace(/8/g, '........');
  pos = pos.replace(/\//g, '');
  return pos;
};

const dataToPieceType = e => {
  return e === '.' ? '' : e.toLocaleLowerCase();
}

const dataToPieceColor = e => {
  if (e === '.') {
    return '';
  }
  return (e.toLowerCase() === e) ? 'b' : 'w';
}

const styles = theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
});

class BoardView extends Component {
  static propTypes = {
    fen: PropTypes.string,
    size: PropTypes.number.isRequired,
    showNotation: PropTypes.bool,
    style: PropTypes.any,
  };

  static defaultProps = {
    fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR_w_KQkq_-_0_1',
    showNotation: true,
    color: 'w',
  };

  constructor(props) {
    super(props);

    this.state = {
      board: this.createBoardData(props.fen),
    };
  }

  createBoardData(fen) {
    const squares = [];

    const pos = fenToData(fen);

    for (let i = 0; i < pos.length; ++i) {
      const rowIndex = parseInt(i / DIMENSION);
      const columnIndex = i % DIMENSION;
      const columnName = COLUMN_NAMES[columnIndex];
      const position = `${columnName}${DIMENSION - rowIndex}`;
      const type = dataToPieceType(pos[i]);
      const color = dataToPieceColor(pos[i]);

      squares.push({
        rowIndex,
        columnIndex,
        type,
        color,
        position,
        columnName,
      });
    }
    return squares;
  }

  renderSquares(reverseBoard) {
    const { classes } = this.props;
    const { size, showNotation } = this.props;
    const { board } = this.state;
    const squareSize = size / DIMENSION;
    const rowSquares = [];

    board.forEach(square => {
      const {
        rowIndex,
        columnIndex,
        columnName,
        position,
      } = square;

      const squareView = (
        <Square
          key={`square_${rowIndex}_${columnIndex}`}
          size={squareSize}
          showNotation={showNotation}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          columnName={columnName}
          dimension={DIMENSION}
          position={position}
          reverseBoard={reverseBoard}
        />
      );

      if (!rowSquares[rowIndex]) {
        rowSquares[rowIndex] = [];
      }
      rowSquares[rowIndex].push(squareView);
    });

    return rowSquares.map((r, index) => {
      return (
        <div key={`row_${index}`} className={classes.row}>
          {r}
        </div>
      );
    });
  }

  renderPieces(reverseBoard) {
    const { size } = this.props;
    const { board } = this.state;

    return board.map(square => {
      const {
        type,
        color,
        rowIndex,
        columnIndex,
        position,
      } = square;
      if (type) {
        return (
          <Piece
            key={`piece_${rowIndex}_${columnIndex}`}
            type={type}
            color={color}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            pieceSize={size / DIMENSION}
            position={position}
            reverseBoard={reverseBoard}
          />
        );
      }
      return null;
    });
  }

  render() {
    const { classes } = this.props;
    const { fen } = this.props;
    const color = fen.split('_')[1];
    const reverseBoard = color === 'b';

    return (
      <div className={classes.container} style={this.props.style}>
        <div
          style={{
            transform: [
              {
                rotate: reverseBoard ? '180deg' : '0deg',
              },
            ],
          }}
        >
          {this.renderSquares(reverseBoard)}
          {this.renderPieces(reverseBoard)}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BoardView);
