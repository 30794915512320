import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  profile: {
    textAlign: 'center',

    marginTop: theme.spacing(1)
  },

  info: {
    marginTop: theme.spacing(0.5)
  },

  emailAddress: {
    marginTop: -theme.spacing(0.5)
  },
});

class Profile extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const { user } = this.props;

    if (!user.photoURL || !user.displayName || !user.email) {
      return null;
    }

    return (
      <div className={classes.profile}>
        <div className={classes.info}>
          <Typography variant="h6">{user.displayName}</Typography>
          <Typography className={classes.emailAddress} color="textSecondary" variant="body1">{user.email}</Typography>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(Profile);
