import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import settings from '../../../settings';
import ExternalLink from '../../../Components/ExternalLink';

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    maxWidth: 320,
    margin: '0 auto',
  },
  logo: {
    letterSpacing: '-0.0025rem',
    fontWeight: 300,
    color: '#1d0954',
  },
  footer: {
    marginTop: '2rem',
    fontSize: '0.8rem',
    color: theme.palette.gray.main,
  },
  legal: {
    marginTop: '0.5rem',
    '& a': {
      color: theme.palette.gray.dark,
    }
  },
}));

const Base = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h6" className={classes.logo}>
        Chessvision.ai
      </Typography>
      <div className={classes.content}>
        { children }
      </div>
      <div className={classes.footer}>
        <div className={classes.copy}>
          © {new Date().getFullYear()} Chessvision.ai All rights reserved.
        </div>
        <div className={classes.legal}>
          <ExternalLink url={settings.links.TOS}>Terms of Service</ExternalLink>
          <span> / </span>
          <ExternalLink url={settings.links.PRIVACY_POLICY}>Privacy Policy</ExternalLink>
        </div>
      </div>
    </div>
  );
}

export default Base;
