import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  container: {
    height: '100%',
  },
});

class History extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { classes, theme } = this.props;

    const { history, onMoveClick, lastMoveId } = this.props;

    const plys = [];
    if (history) {
      for (let i = 0; i < history.length; i += 2) {
        plys.push([history[i], i+1 < history.length ? history[i+1] : null]);
      }
    }

    const highlightStyle = {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    }
    const normalStyle = {
      backgroundColor: 'inherit'
    }

    return (
      <div className={classes.container}>
        <TableContainer component={Paper} className={classes.container}>
          <Table padding="normal" size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} padding="normal" width="25%">Moves</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { plys.map(([move1, move2], i) => (
                <TableRow key={i}>
                  <TableCell className={classes.moveNumber} width="20%" align="right">
                    {i+1}.
                  </TableCell>
                  <TableCell className={classes.firstHalfMove} width="40%">
                    <Button
                      size="small"
                      variant="text"
                      style={{ textTransform: 'none', ...(2*i === lastMoveId ? highlightStyle : normalStyle) }}
                      onClick={() => { onMoveClick(2*i) }}
                    >
                      {move1.san}
                    </Button>
                  </TableCell>
                  <TableCell width="40%">
                    {move2 && (
                      <Button
                        size="small"
                        variant="text"
                        style={{ textTransform: 'none', ...(2*i+1 === lastMoveId ? highlightStyle : normalStyle) }}
                        onClick={() => { onMoveClick(2*i+1)}}
                      >
                        {move2.san}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(History);
