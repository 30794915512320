import { initializeApp } from 'firebase/app';
import {
  getFirestore, collection, serverTimestamp,
  doc, getDoc, setDoc, updateDoc, deleteDoc,
  query, orderBy, getDocs,
  onSnapshot,
} from 'firebase/firestore';

import settings from './settings';

initializeApp(settings.credentials.firebase);

const db = getFirestore();

const fenToKey = fen => fen.replace(/\//g,'*').split(' ').join('_');
const keyToFen = key => key.replace(/\*/g,'/');


class Users {
  static usersRef = collection(db, 'users');

  static async exists(uid) {
    const snap = await getDoc(doc(this.usersRef, uid));
    return snap.exists();
  }

  static async create(uid, refId) {
    await setDoc(doc(this.usersRef, uid), {
      created: true,
      refId,
    });
  }
};

class AccountDeletionRequestsStorage {
  static requestsRef = collection(db, 'account_deletion_requests');

  static async exists(uid) {
    const snap = await getDoc(doc(this.requestsRef, uid));
    return snap.exists();
  }

  static async create(uid) {
    await setDoc(doc(this.requestsRef, uid), {
      created: serverTimestamp(),
      deleted: false,
      source: 'webapp',
    });
  }
}

class SubscriptionStorage {
  static usersRef = collection(db, 'subscriptions');

  constructor(uid) {
    this.snap = doc(collection(db, 'subscriptions'), uid);
  }

  async get() {
    return await getDoc(this.snap);
  }

}

class FenLibrary {
  constructor(uid) {
    this.ref = collection(db, `users/${uid}/fens`);
  }

  async add(fen, { name }) {
    if (await this.exists(fen)) {
      return false;
    }
    const key = fenToKey(fen);
    const data = {
      created: serverTimestamp(),
      opened: serverTimestamp(),
    }
    if (name) {
      data.name = name;
    }
    await setDoc(doc(this.ref, key), data);
    return true;
  }

  fenToDoc(fen) {
    const key = fenToKey(fen);
    return doc(this.ref, key);
  }

  async updateOpened(fen) {
    const ref = this.fenToDoc(fen);
    const snap = await getDoc(ref);
    if (snap.exists()) {
      await updateDoc(ref, {
        opened: serverTimestamp(),
      });
    }
  }

  async remove(fen) {
    if (!await this.exists(fen)) {
      return;
    }
    await deleteDoc(this.fenToDoc(fen));
  }

  async exists(fen) {
    const ref = this.fenToDoc(fen);
    const snap = await getDoc(ref);
    return snap.exists();
  }

  async getAll() {
    const data = [];
    const q = query(this.ref, orderBy('opened', 'desc'));
    const snap = await getDocs(q);

    snap.forEach((doc) => {
      data.push({
        fen: keyToFen(doc.id),
        ...doc.data(),
      });
    });
    return data;
  }

  observe(fen, callback) {
    return onSnapshot(this.fenToDoc(fen), callback);
  }

}

export { Users, AccountDeletionRequestsStorage, SubscriptionStorage, FenLibrary };
