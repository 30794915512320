import React from 'react';

import * as Chess from 'chess.js';
import ReactPlayer from 'react-player'

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import UndoIcon from '@material-ui/icons/ArrowBackIos';
import ClipboardIcon from '@material-ui/icons/FileCopy';
import { Dialog, DialogContent } from '@material-ui/core';

import Board from './components/Board';
import Hierarchy from './components/Hierarchy';
import EvalBar from './components/EvalBar';
import ExternalLink from '../../Components/ExternalLink';

import { moveUciToObj, computeMainlines, expandForest, prepareForest, urlToVideoId } from './utils';
import { emitEvent, eventTypes } from '../../events';

import settings from '../../settings';

// const load_engine = require("../../load_engine");

const styles = (theme) => ({
  /* responsive size of the player */
  container: {
    maxWidth: 1920,
    margin: '0 auto',
  },
  mainContent: {
    display: 'flex',
  },
  videoWrapper: {
    flex: 62,
    marginLeft: theme.spacing(3),
  },
  videoInputWrapper: {
    display: 'flex',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  videoContainer: {
    position: 'relative',
    paddingTop: '56.25%',
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100% !important',
    height: '100% !important',
  },
  analysisContainer: {
    flex: 38,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  analysisNotAvailable: {
    flex: 38,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  boardContainer: {
    flex: 1,
    flexDirection: 'column',
    marginTop: theme.spacing(2.2),
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
  },
  engineInfo: {
    fontSize: '0.8rem',
    marginRight: '1rem',
  },
  boardWrapper: {
    display: 'flex',
    flex: 1,
    marginTop: theme.spacing(1.8),
    position: 'relative',
  },
  controls: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  fenWrapper: {
    display: 'flex',
    marginTop: '1rem',
  },
  evalContainer: {
    width: '28px',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  movesContainer: {
    maxHeight: '200px',
    overflowY: 'auto',
    fontSize: '0.9rem',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  tagline: {
    flex: 1,
  },
  requestAnalysis: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  requestAnalysisButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'block',
  },
  requestAnalysisStatus: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  restrictedContainer: {
    position: 'absolute',
    width: '80%',
    left: '10%',
    top: '25%',
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  restrictedUnlock: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    zIndex: 999,
    textAlign: 'center',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(3),
  },
  freeVideos: {
    marginTop: theme.spacing(3),
  },
});

const defaultVideoId = '0EmnA84OwwY'; // Simon Williams for Chess.com - Morphy's 5 Most Brilliant Moves

// TODO: remove this as it's for dev only purposes
const fetchPgn = true;

class VideoWatch extends React.Component {

  constructor(props) {
    super(props);
    this.chess = new Chess();
    const params = new URLSearchParams(props.location.search);
    let paramT = params.get('t');
    if (paramT !== null) {
      paramT = parseFloat(paramT);
    }
    this.ts = paramT || 0.1;
    this.state = {
      videoId: props.match?.params.videoId || defaultVideoId,
      analysis: undefined,

      fen: this.chess.fen(),
      curNode: null,
      lastMove: null,

      synced: true,
      syncedToLatestTimestamp: false,

      playing: props.match?.params.videoId || paramT !== null,
      flipped: false,
      userReverseFlipped: false,

      // eval
      engineOn: true,
      evalScore: null,

      // video input
      videoInput: '',

      snackbarMessage: false,

      requestingAnalysis: false,
      analysisStatus: undefined,  // possible values: undefined, null, 'waiting', 'processing', 'finished', 'limited'
      analysisLimited: false,

      // pawn promotion
      pendingMove: null,
      promotionDialog: false,
    };

    this.engineLoaded = false;
    this.engine = this.loadEngine(this.onEngineMessage);
    this.nextEngine = this.loadEngine();
    this.statusInverval = null;
  }

  componentDidMount() {
    this.setVideo(this.state.videoId);
    const interval = setInterval(() => {
      if (!this.engineLoaded) {
        this.getScore();
        clearInterval(interval);
      }
    }, 100);
  }

  componentWillUnmount() {
    this.engine.terminate(this.engine);
    this.engine.terminate(this.nextEngine);
    clearInterval(this.statusInverval);
  }

  // Engine

  loadEngine(onmessage = null) {
    const engine = new Worker('/sf11/stockfish.js');
    if (onmessage !== null) {
      engine.onmessage = onmessage;
    }
    this.send('uci', engine);
    this.send('setoption name UCI_AnalyseMode value true', engine);
    this.send('ucinewgame', engine);
    return engine;
  }

  terminateEngine(engine) {
    engine.terminate();
  }

  swapEngine() {
    this.terminateEngine(this.engine);

    this.engine = this.nextEngine;
    this.engine.onmessage = this.onEngineMessage;

    this.nextEngine = null;
    this.nextEngine = this.loadEngine();
  }

  send(cmd, which) {
    (which || this.engine).postMessage(cmd);
  }

  getScore() {
    if (!this.state.engineOn) {
      return;
    }

    const { fen, evalScore } = this.state;

    this.swapEngine();

    this.setState({
      evalScore: {
        ...evalScore,
        bestMove: null,
        pv: null,
        depth: null,
      }
    }, () => {
      this.send(`position fen ${fen}`);
      this.send('go depth 21');
    });
  }

  onEngineMessage = event => {
    var line;
    if (event && typeof event === "object") {
      line = event.data;
    } else {
      line = event;
    }

    /// Ignore some output.
    if (line === "readyok") {
      this.engineLoaded = true;
    }
    if (line === "uciok" || line === "readyok" || line.substr(0, 11) === "option name") {
      return;
    }

    // reset best move if it's none, happens e.g. for mate, stalemate on the board
    if (line.startsWith('bestmove (none)')) {
      this.setState({
        evalScore: {
          ...this.state.evalScore,
          bestMove: null,
          pv: null,
        }
      });
      return;
    }

    if (line.startsWith('info depth')) {
      const depth = parseInt(line.split(' ')[2]);
      // is game ended?
      // info depth 0 score mate 0
      if (line.includes('mate 0')) {
        this.setState({
          evalScore: {
            bestMove: null,
            pv: null,
            score: 0,
            mate: true,
            stalemate: false,
            result: this.state.fen.split(' ')[1] === 'b' ? '1-0' : '0-1',
          },
        });
        return;
      }

      let score = null;
      try {
        score = line.match(/ score (.*) nodes /)[1];
      } catch (e) {
        if (e instanceof TypeError) {
          // we have stalemate here because the line has 'score cp 0' but doesn't end up with 'nodes'
          this.setState({
            evalScore: {
              bestMove: null,
              pv: null,
              score: 0,
              mate: false,
              stalemate: true,
              result: '½-½',
            },
          });
          return;
        }
      }

      // we don't show eval for low depths
      if (depth < 11) {
        return;
      }

      const mate = score.startsWith('mate');

      let numScore = parseInt(score.split(' ')[1]);

      const color = this.state.fen.split(' ')[1] === 'w' ? 'white' : 'black';
      if (color === 'black') {
        numScore = -numScore;
      }

      const pv = line.match(/ pv (.*) bmc /)[1].split(' ').map(moveUciToObj);
      const bestMove = pv[0];

      this.setState({
        evalScore: {
          score: numScore,
          mate,
          stalemate: false,
          bestMove,
          pv,
          depth,
        }
      });
    }
  }

  toggleEngine() {
    this.setState({
      engineOn: !this.state.engineOn,
    }, () => {
      this.getScore();
    });
  }

  // Video

  handleProgress = ({ playedSeconds }) => {
    this.ts = playedSeconds;
    const { synced } = this.state;
    if (synced) {
      this.sync();
    }
  }

  sync = () => {
    const { analysis } = this.state;

    if (!analysis) {
      return;
    }

    let bestTs = null;
    let resNode = null;

    const dfs = ({ id, timestamps, fen, score, orientation, children }, move) => {
      let goDeeper = false;
      for (const ts of timestamps) {
        if (ts <= this.ts && (bestTs === null || ts > bestTs)) {
          bestTs = ts;
          resNode = { id, fen, score, orientation, move };
        }
        goDeeper |= ts <= this.ts;
      }
      if (goDeeper) {
        let allSubtreeExplored = true;
        for (const { move, child } of children) {
          allSubtreeExplored &= dfs(child, move);
        }
        return allSubtreeExplored;
      }
      return false;
    }
    let allForestExplored = true;
    for (const root of analysis) {
      allForestExplored &= dfs(root, null);
    }

    if (resNode !== null) {
      if (resNode.fen !== this.state.fen) {
        this.setState({
          fen: resNode.fen,
          // TODO: this should be uncommented if the scores are part of the fetched data
          // score: resNode.score,
          curNode: resNode.id,
          flipped: resNode.orientation === "flipped",
          lastMove: resNode.move === null ? null : resNode.move.uci,
          syncedToLatestTimestamp: allForestExplored,
        }, () => {
          this.chess.load(resNode.fen);
          // TODO: this should be commented if we don't compute dynamic store for positions in the forest
          this.getScore();
        });
      }
    } else {
      this.chess.reset();
      this.setState({
        fen: this.chess.fen(),
        score: null,
        curNode: null,
        flipped: false,
        lastMove: null,
      });
    }
  }

  toggleSync() {
    const { synced } = this.state;
    this.setState({
      synced: !synced,
      playing: !synced ? true : false,
    }, () => {
      if (this.state.synced) {
        this.sync();
      }
    });
  }

  // Board

  toggleUserReverseFlipped = () => {
    this.setState({
      userReverseFlipped: !this.state.userReverseFlipped,
    });
  }

  undo = () => {
    this.chess.undo();
    const moves = this.chess.history({ verbose: true });
    const move = moves.length === 0
      ? null
      : moves[moves.length-1];
    this.setState({
      fen: this.chess.fen(),
      lastMove: move === null ? null : `${move.from}${move.to}`,
    }, () => {
      this.getScore();
    });
  }

  handleUserMove = (from, to) => {
    const moves = this.chess.moves({ verbose: true })
    for (const move of moves) {
      if (move.flags.indexOf("p") !== -1 && move.from === from) {
        this.setState({
          pendingMove: [from, to],
          promotionDialog: true,
        });
        return;
      }
    }

    const move = this.chess.move({ from, to, promotion: "x" });
    if (move !== null) {
      this.setState({
        fen: this.chess.fen(),
        lastMove: `${from}${to}`,
        synced: false,
      }, () => {
        this.getScore();
      });
    } else {
      // TODO: is in necessary?
      this.setState({
        fen: this.chess.fen(),
      });
    }
  }

  promotion = e => {
    const { pendingMove } = this.state;
    const from = pendingMove[0];
    const to = pendingMove[1];
    const move = this.chess.move({ from, to, promotion: e });
    if (move !== null) {
      this.setState({
        fen: this.chess.fen(),
        lastMove: `${from}${to}`,
        promotionDialog: false,
      })
    }
  }

  copyToClipboard = (elementId, message) => {
    const e = document.getElementById(elementId);
    e.select();
    try {
      document.execCommand('copy');
      e.focus();
      this.setState({
        snackbarMessage: message,
      });
    } catch (err) {
      alert('Unable to copy to clipboard, please select and copy manually.')
    }
  }

  requestAnalysis() {
    const { user } = this.props;
    const { videoId } = this.state;
    const url = `${settings.videoApiUrl}/videos`;
    this.setState({
      requestingAnalysis: true,
    }, () => {
      return user.getIdToken().then(authToken => {
        const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        };
        const payload = {
          id: videoId,
        };
        fetch(url, {
          method: 'POST',
          headers,
          body: JSON.stringify(payload),
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 403) {
            this.setState({
              requestingAnalysis: false
            }, () => {
              this.props.onUpgradeClick();
            })
            return null;
          } else {
            throw new Error(response.status + " Failed Fetch ");
          }
        })
        .then(data => {
          if (data === null) return;
          this.setState({
            requestingAnalysis: false,
            snackbarMessage: 'Video added to the analysis queue',
            analysisStatus: 'waiting',
          }, () => {
            this.fetchAnalysis();
          });
        })
        .catch(e => {
          this.setState({
            requestingAnalysis: false,
          });
          console.error('EXCEPTION: ', e)
        })
      });
    });
  }

  // PGN

  fetchAnalysis() {
    if (!fetchPgn) {
      return;
    }
    clearInterval(this.statusInverval);
    const { user } = this.props;
    const { videoId } = this.state;
    const url = `${settings.videoApiUrl}/videos/${videoId}`;
    return user.getIdToken().then(authToken => {
      const headers = {
        'accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      };
      fetch(url, { headers })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else if (response.status === 404) {
          this.setState({
            analysis: null,
            analysisStatus: null,
          });
          return null;
        } else {
          throw new Error(response.status + " Failed Fetch ");
        }
      })
      .then(data => {
        if (data === null) return;
        if (data.finished_at !== null) {
          const analysis = computeMainlines(prepareForest(expandForest(data.forest)), 2);
          this.setState({
            analysis,
            analysisStatus: 'finished',
            analysisLimited: data.forest_limited,
          });
        } else if (data.started_at !== null) {
          // video being analyzed
          this.setState({
            analysis: null,
            analysisStatus: 'processing',
          }, () => {
            this.statusInverval = setInterval(() => {
              this.fetchAnalysis();
            }, settings.values.analysisStatusIntervalPing);
          });
        } else {
          // video waiting in the queue for being analyzed
          this.setState({
            analysis: null,
            analysisStatus: 'waiting',
          }, () => {
            this.statusInverval = setInterval(() => {
              this.fetchAnalysis();
            }, settings.values.analysisStatusIntervalPing);
          });
        }
      })
      .catch(e => {
        console.error('EXCEPTION: ', e)
      })
    });
  }

  handleMoveClick = (nodeId) => {
    this.setState({
      synced: true,
      playing: true,
    }, () => {
      const dfsFind = ({ id, timestamps, children }) => {
        if (id === nodeId) {
          const seconds = timestamps[0];
          // add some offset better user experience
          const offset = 0;
          // this.player.currentTime = seconds - offset;
          this.player.seekTo(seconds-offset, 'seconds');
          this.sync();
          return;
        }
        for (const { child } of children) {
          dfsFind(child);
        }
      }
      const { analysis } = this.state;
      for (const root of analysis) {
        dfsFind(root);
      }
    });
  }

  ref = (player) => {
    this.player = player;
  }

  setVideo = (videoId) => {
    this.setState({
      videoId,
      analysis: undefined,
      videoInput: `https://youtube.com/watch?v=${videoId}`,
    }, () => {
      this.fetchAnalysis();
    });
  }

  calcMovable = () => {
    const dests = new Map();
    this.chess.SQUARES.forEach(s => {
      const ms = this.chess.moves({ square: s, verbose: true });
      if (ms.length) {
        dests.set(s, ms.map(m => m.to));
      }
    });
    return {
      free: false,
      dests,
      color: this.chess.turn() === "w" ? "white" : "black",
    };
  }

  render() {
    const { classes } = this.props;
    const { onUpgradeClick } = this.props;
    const { onUpgradeLoading } = this.props;

    const { videoId } = this.state;
    const { fen, lastMove, curNode, flipped } = this.state;
    const { synced } = this.state;
    const { syncedToLatestTimestamp } = this.state;
    const { engineOn } = this.state;
    const { evalScore } = this.state;
    const { userReverseFlipped } = this.state;

    const { analysis } = this.state;
    const { requestingAnalysis, analysisStatus } = this.state;
    const { analysisLimited } = this.state;
    const { playing } = this.state;

    const { videoInput } = this.state;
    const { snackbarMessage } = this.state;

    const { promotionDialog } = this.state;

    const videoIdFromInput = urlToVideoId(videoInput);

    const videoUrl = `https://www.youtube.com/watch?v=${videoId}`;
    const arrows = engineOn && evalScore?.bestMove
      ? [{ ...evalScore.bestMove }]
      : [];

    // let engineLine = null;
    // if (evalScore?.pv) {
    //   engineLine = [];
    //   const engineGame = new Chess(this.chess.fen());
    //   for (const move of evalScore.pv) {
    //     engineLine.push(engineGame.move(move).san);
    //   }
    // }
    return (
      <div className={classes.container}>
        <div className={classes.mainContent}>
          <div className={classes.videoWrapper}>
            <div className={classes.videoInputWrapper}>
              <TextField
                label="YouTube Video Link"
                size="small"
                variant="outlined"
                value={videoInput}
                onChange={(e) => { this.setState({ videoInput: e.target.value })}}
                style={{ flex: 1 }}
              />
              <Button
                onClick={() => { this.setVideo(videoIdFromInput) }}
                size="small"
                variant="contained"
                disabled={videoIdFromInput === null || videoIdFromInput === videoId}
                style={{ marginLeft: '1rem', marginRight: '0.5rem', textTransform: 'none' }}
              >Load video</Button>
            </div>
            <div className={classes.videoContainer}>
              <ReactPlayer
                className={classes.player}
                width={854}
                height={480}
                playing={playing}
                ref={this.ref}
                controls={true}
                url={videoUrl}
                onProgress={this.handleProgress}
                onReady={() => { if (playing) { this.player.seekTo(this.ts, 'seconds'); }}}
              />
            </div>
          </div>
          { analysis === undefined && (
            <div className={classes.analysisContainer} />
          )}
          { analysis && analysis.length === 0 && (
            <div className={classes.analysisNotAvailable}>
              <Typography variant="h6" style={{ textAlign: 'center' }}>No 2d chessboards found in the video</Typography>
              <br />
              <Typography>
                If you think the app should find 2d chessboards in this video, please <ExternalLink url={`mailto:${settings.values.supportEmail}?subject=Chessvision.ai Video analysis empty`}>let us know</ExternalLink>.
              </Typography>
            </div>
          )}
          { analysis && analysis.length > 0 && (
            <div className={classes.analysisContainer}>
              <div className={classes.boardContainer}>
                <div className={classes.topBar}>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={engineOn}
                          onChange={() => this.toggleEngine()}
                        />
                      }
                      label="Engine"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={synced}
                          onChange={() => this.toggleSync()}
                        />
                      }
                      label="Video Sync"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={userReverseFlipped}
                          onChange={() => this.toggleUserReverseFlipped()}
                        />
                      }
                      label="Flip board"
                    />
                  </FormGroup>
                  <div style={{ flex: 1 }} />
                  { engineOn && evalScore?.depth && (
                    <span className={classes.engineInfo}>depth: {evalScore.depth}</span>
                  )}
                </div>
                <div className={classes.boardWrapper}>
                  { analysisLimited && syncedToLatestTimestamp && (
                    <div className={classes.restrictedContainer}>
                      <div className={classes.restrictedUnlock}>
                        <Typography variant="h6">Unlock full analysis for Videos</Typography>
                        <Typography>Upgrade your account to watch all videos with synchronized analysis board and the engine.</Typography>
                        <Button
                          color="secondary"
                          variant="contained"
                          size="large"
                          disabled={onUpgradeLoading}
                          onClick={() => {
                            emitEvent(eventTypes.subscriptionPlansDialogOpenWatch);
                            onUpgradeClick();
                          }}
                          style={{ marginTop: '1rem' }}
                        >Upgrade</Button>
                      </div>
                    </div>
                  )}
                  <div className={classes.evalContainer}>
                    { engineOn && (
                      <EvalBar evalScore={evalScore} flipped={userReverseFlipped ? !flipped : flipped} />
                    )}
                  </div>
                  <div>
                    <Board
                      fen={fen}
                      lastMove={lastMove && [lastMove.substr(0, 2), lastMove.substr(2)]}
                      onMove={this.handleUserMove}
                      flipped={userReverseFlipped ? !flipped : flipped}
                      evalScore={evalScore}
                      arrows={arrows}
                      movable={this.calcMovable()}
                    />
                  </div>
                  <Dialog open={promotionDialog} onClose={() => { this.setState({ promotionDialog: false })}}>
                    <DialogContent>
                      <Button onClick={() => { this.promotion("q")}}>Queen</Button>
                      <Button onClick={() => { this.promotion("r")}}>Rook</Button>
                      <Button onClick={() => { this.promotion("b")}}>Bishop</Button>
                      <Button onClick={() => { this.promotion("n")}}>Knight</Button>
                    </DialogContent>
                  </Dialog>
                </div>
                { !synced && (
                  <div className={classes.controls}>
                    <IconButton
                      color="primary"
                      aria-label="undo"
                      component="span"
                      onClick={this.undo}
                      size="small"
                      disabled={this.chess.history().length === 0}
                    >
                      <UndoIcon />
                    </IconButton>
                  </div>
                )}
                <div className={classes.fenWrapper}>
                  <TextField
                    value={fen}
                    style={{ flex: 1, marginLeft: '3px' }}
                    InputProps={{ id: "read-only-fen", readOnly: true, style: { fontSize: '0.8rem' } }}
                    label="FEN"
                    size="small"
                    variant="outlined"
                  />
                <IconButton
                  style={{ marginTop: '-0.3rem', marginLeft: '0rem' }}
                  onClick={() => { this.copyToClipboard('read-only-fen', 'FEN copied to clipboard!') }}
                  ><ClipboardIcon /></IconButton>
                </div>
              </div>
            </div>
          )}
          { analysis === null && (
            <div className={classes.analysisNotAvailable}>
              <Typography variant="h6" style={{ textAlign: 'center' }}>Video analysis not found</Typography>
              {
              /*
              { !analysisStatus && (
                <div className={classes.requestAnalysis}>
                  <Typography>
                    You can request an analysis for this video. If you do so, it will be added to the analysis queue and you will be informed about the progress on this page.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={requestingAnalysis}
                    className={classes.requestAnalysisButton}
                    onClick={() => this.requestAnalysis()}
                  >Request analysis</Button>
                </div>
              )}
              { analysisStatus === 'waiting' && (
                <div className={classes.requestAnalysisStatus}>
                  <Typography>
                    The video is waiting in the analysis queue...
                  </Typography>
                  <LinearProgress />
                </div>
              )}
              { analysisStatus === 'processing' && (
                <div className={classes.requestAnalysisStatus}>
                  <Typography>
                    The video is being analyzed...
                  </Typography>
                  <LinearProgress />
                </div>
              )}
              */
              }
              <Typography style={{ marginTop: '1rem' }}>
                So far, we have analyzed over 30k YouTube videos dating back to 2015 from these channels: {settings.observedChannels.map(c => <a key={c.value} href={`https://www.youtube.com/channel/${c.value}`} target="_blank" rel="noopener noreferrer">{c.label}</a>).reduce((prev, cur) => [prev, ', ', cur])}
              </Typography>
              <br />
              <Typography>
                If the video you are looking for is from one of the listed channels and was just recently published, please give the app some time to make the analysis available.
              </Typography>
              <br />
              <Typography>
                If you would like to suggest a YouTube channel to observe, feel free to <ExternalLink url={`mailto:${settings.values.supportEmail}?subject=Chessvision.ai Video Channel suggestion`}>let us know</ExternalLink>.
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.movesContainer}>
          { analysis !== undefined && analysis !== null && (
            <Hierarchy
              forest={analysis}
              onMoveClick={this.handleMoveClick}
              curNode={curNode}
            />
          )}
        </div>
        <Snackbar
          open={Boolean(snackbarMessage)}
          autoHideDuration={5000}
          onClose={() => this.setState({ snackbarMessage: false })}
          style={{ width: '75%', alignSelf: 'center' }}
          message={snackbarMessage}
          severity="success"
        />
      </div>
    );
  }
};

export default withStyles(styles)(withRouter(VideoWatch));
