import React, { Component } from 'react';

import PropTypes from 'prop-types';

import validate from 'validate.js';

import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import settings from '../../../settings';

import constraints from '../constraints';

import AuthProviders from './Providers';
import ExternalLink from '../../../Components/ExternalLink';

import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      password: '',
      passwordConfirmation: '',
      errors: null,
    };
  }

  signUp = () => {
    const { emailAddress, password, passwordConfirmation } = this.state;

    const errors = validate({
      emailAddress: emailAddress,
      password: password,
      passwordConfirmation: passwordConfirmation
    }, {
      emailAddress: constraints.emailAddress,
      password: constraints.password,
      passwordConfirmation: constraints.passwordConfirmation
    });

    if (errors) {
      this.setState({ errors });
    } else {
      this.setState({
        errors: null
      }, () => {
        this.props.signUp(emailAddress, password, passwordConfirmation);
      });
    }
  };

  handleKeyPress = (event) => {
    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      this.signUp();
    }
  };

  handleEmailAddressChange = (event) => {
    const emailAddress = event.target.value;

    this.setState({ emailAddress });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;

    this.setState({ password });
  };

  handlePasswordConfirmationChange = (event) => {
    const passwordConfirmation = event.target.value;

    this.setState({ passwordConfirmation });
  };

  handleSignUpClick = () => {
    this.signUp();
  };

  render() {
    // Styles
    const { classes } = this.props;

    // Properties
    const { isPerformingAuthAction } = this.props;

    // Events
    const { onAuthProviderClick } = this.props;

    const { emailAddress, password, passwordConfirmation, errors } = this.state;

    return (
      <div>
        <AuthProviders
          isPerformingAuthAction={isPerformingAuthAction}
          onAuthProviderClick={onAuthProviderClick} />

        <div className={classes.headerWrapper}>
          <div className={classes.headerDivider} />
          <Typography
            className={classes.header}
            >Sign up with email</Typography>
        </div>

        <form>
          <TextField
            autoComplete="email"
            error={!!(errors && errors.emailAddress)}
            fullWidth
            helperText={(errors && errors.emailAddress) ? errors.emailAddress[0] : ''}
            placeholder="E-mail address"
            margin="normal"
            onChange={this.handleEmailAddressChange}
            required
            type="email"
            value={emailAddress}
          />

          <TextField
            size="small"
            autoComplete="new-password"
            error={!!(errors && errors.password)}
            fullWidth
            helperText={(errors && errors.password) ? errors.password[0] : ''}
            placeholder="Password"
            margin="normal"
            onChange={this.handlePasswordChange}
            required
            type="password"
            value={password}
          />

          <TextField
            size="small"
            autoComplete="password"
            error={!!(errors && errors.passwordConfirmation)}
            fullWidth
            helperText={(errors && errors.passwordConfirmation) ? errors.passwordConfirmation[0] : ''}
            placeholder="Confirm password"
            margin="normal"
            onChange={this.handlePasswordConfirmationChange}
            required
            type="password"
            value={passwordConfirmation}
          />
        </form>
        <div className={classes.terms}>
          Creating an account means you’re okay with our <ExternalLink url={settings.links.TOS}>Terms of Service</ExternalLink>, <ExternalLink url={settings.links.PRIVACY_POLICY}>Privacy Policy</ExternalLink>, and our default <ExternalLink url={settings.links.NOTIFICATION_SETTINGS}>Notification Settings</ExternalLink>.
        </div>
        <div className={classes.actions}>
          <Button
            color="primary"
            disabled={(!emailAddress || !password || !passwordConfirmation) || isPerformingAuthAction}
            variant="contained"
            fullWidth
            onClick={this.handleSignUpClick}>Sign Up</Button>
        </div>
        <div className={classes.formHelper}>
          Already have an account?
          <Link to={settings.routes.SIGN_IN}>
            <Button
              variant="text"
              size="small"
              disabled={isPerformingAuthAction}>
              Go to Login</Button>
          </Link>
        </div>
      </div>
    );
  }
}

SignUp.propTypes = {
  isPerformingAuthAction: PropTypes.bool.isRequired,
  signUp: PropTypes.func.isRequired,
  onAuthProviderClick: PropTypes.func.isRequired
};

export default withStyles(styles)(SignUp);
