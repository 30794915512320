import React, { Component } from 'react';

import PropTypes from 'prop-types';

const PIECE_IMAGES = {
  k: {
    w: require('./images/pieces/wK.png'),
    b: require('./images/pieces/bK.png'),
  },
  q: {
    w: require('./images/pieces/wQ.png'),
    b: require('./images/pieces/bQ.png'),
  },
  r: {
    w: require('./images/pieces/wR.png'),
    b: require('./images/pieces/bR.png'),
  },
  b: {
    w: require('./images/pieces/wB.png'),
    b: require('./images/pieces/bB.png'),
  },
  n: {
    w: require('./images/pieces/wN.png'),
    b: require('./images/pieces/bN.png'),
  },
  p: {
    w: require('./images/pieces/wP.png'),
    b: require('./images/pieces/bP.png'),
  },
};

export default class Piece extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    rowIndex: PropTypes.number.isRequired,
    columnIndex: PropTypes.number.isRequired,
    pieceSize: PropTypes.number.isRequired,
    reverseBoard: PropTypes.bool,
  };

  render() {
    const {
      type,
      color,
      rowIndex,
      columnIndex,
      pieceSize,
      reverseBoard,
    } = this.props;
    const pieceImageSource = PIECE_IMAGES[type][color];

    return (
      <img
        style={{
          position: 'absolute',
          top: pieceSize * rowIndex,
          left: pieceSize * columnIndex,
          width: pieceSize,
          height: pieceSize,
          transform: [
            {
              rotate: reverseBoard ? '180deg' : '0deg',
            },
          ],
        }}
        alt=""
        src={pieceImageSource}
      />
    );
  }
}
