import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { getRestrictedText, getRestrictedWord } from '../../../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    // paddingBottom: '1.5rem',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  // solution to auto scalling to width player from: https://github.com/CookPete/react-player/issues/145
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%', /* Player ratio: 100 / (1280 / 720) */
    maxWidth: 'none',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    filter: 'blur(20px)',
  },
  playIcon: {
    fontSize: '64px',
  },
  infoContainer: {
    flex: 1,
    fontSize: '0.8rem',
    marginTop: '0.5rem',
    paddingLeft: 0,
    marginRight: '1rem',
    filter: 'blur(4px)',
  },
  titleWrapper: {
    fontSize: '1.2rem',
  },
  title: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  subtitle: {
    marginTop: '0.2rem',
    '& > span': {
    },
  },
  channelWrapper: {
    marginTop: '0.25rem',
  },
  channel: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  description: {
    marginTop: '0.75rem',
    color: '#606060',
  },
  timestamp: {
    minWidth: 'auto',
  },
}));

const RestrictedResult = ({ thumb }) => {
  const classes = useStyles();

  const title = getRestrictedText(30, 50);
  const timestamp = getRestrictedWord(4, 5);
  const views = getRestrictedWord(3, 5);
  const uploadDate = getRestrictedText(5, 10);
  const channelName = getRestrictedText(8, 20);

  return (
    <div className={classes.container}>
      <div className={classes.playerWrapper}>
        <div style={{ width: '100%', height: '100%' }} className={classes.player}>
          <div style={{ width: '100%', height: '100%', backgroundImage: `url(${thumb})`, backgroundSize: 'cover' }} />
        </div>
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.titleWrapper}>
          <span className={classes.title}>{title}</span>
        </div>
        <div className={classes.channelWrapper}>
          <span className={classes.channel}>{channelName}</span>
        </div>
        <div className={classes.subtitle}>
          <span className={classes.timestamps}>at {timestamp}</span>
          <span className={classes.viewCount}>
            {views} views
          </span> •&nbsp;
          <span className={classes.date}>
            {uploadDate}
          </span>
        </div>
      </div>
    </div>
  );
}

export default RestrictedResult;
