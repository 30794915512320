import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import ErrorIcon from '@material-ui/icons/ErrorOutline';

import EmptyState from '../../Layout/EmptyState';

import { eventTypes, emitEvent } from '../../events';

const styles = (theme) => ({
  root: {
    minHeight: '100vh',
  },
  emptyStateIcon: {
    fontSize: theme.spacing(12)
  },
});

class CheckoutCanceledContent extends Component {

  constructor(props) {
    super(props);
    emitEvent(eventTypes.checkoutCancel);
  }

  render() {
    // Styling
    const { classes } = this.props;

    const { title, text } = this.props;

    return (
      <div className={classes.root}>
        <EmptyState
          icon={<ErrorIcon className={classes.emptyStateIcon} />}
          title={title}
          description={text}
          className={classes.root}
        />
      </div>
    );
  }
}

CheckoutCanceledContent.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default withStyles(styles)(CheckoutCanceledContent);
