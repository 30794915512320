import React from 'react';

import _ from 'lodash';

import Chip from '@material-ui/core/Chip';

import { withStyles } from '@material-ui/core/styles';

import { openingToSelectOption, variationToSelectOption } from '../utils';
import { phases, endgameTypes, themesOptions } from '../data';

const styles = (theme) => ({
  container: {

  },
  chipsWrapper: {
    display: 'flex',

    flexWrap: 'wrap',
    padding: '1rem 0',
  },
  chipWrapper: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  chip: {
  },
});

const findByValue = (arr, value) => arr.find(e => e.value === value);

class Chips extends React.Component {

  render() {
    const { classes } = this.props;
    const { onClick } = this.props;

    const { opening, variation, phase, openness, endgameType, pawnStructure, themes, } = this.props;
    const options = {
      opening, variation, phase, openness, endgameType, pawnStructure, themes,
    };

    const chips = [
      {
        // label: "French Defense: Advance Variation",
        label: "French Defense: Advance Variation",
        values: {
          opening: openingToSelectOption("French Defense"),
          variation: variationToSelectOption("Advance Variation"),
        },
      },
      {
        label: "Sicilian Defense: Najdorf Variation",
        values: {
          opening: openingToSelectOption("Sicilian Defense"),
          variation: variationToSelectOption("Najdorf Variation"),
        },
      },
      {
        label: "King and Pawn Endgame",
        values: {
          phase: findByValue(phases, "endgame"),
          endgameType: findByValue(endgameTypes, "king and pawn"),
        },
      },
      {
        label: "Rook and Pawn Endgame",
        values: {
          phase: findByValue(phases, "endgame"),
          endgameType: findByValue(endgameTypes, "rook and pawn"),
        },
      },
      {
        label: "Exchange Sacrifice",
        values: {
          themes: [findByValue(themesOptions, "exchange_sacrifice")],
        },
      },
    ];
    return (
      <div className={classes.container}>
        <div className={classes.chipsWrapper}>
          { chips.map(chip => {
            const active = Object.entries(options).every(([k, v]) => v === null ? !chip.values[k] : _.isEqual(v, chip.values[k]));
            return (
              <div className={classes.chipWrapper} key={chip.label}>
                <Chip
                  label={chip.label}
                  onClick={() => onClick(chip.values)}
                  className={classes.chip}
                  color={active ? "secondary" : "default"}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Chips);
