import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Select from 'react-select';

import { channels } from '../data';
import { openings, phases, opennesses, endgameTypes, pawnStructures, themesOptions } from '../data';

import { getSelectTheme } from '../../../utils';

const styles = (theme) => ({
  container: {

  },
  selectsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectWrapper: {
    width: '20%',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  select: {
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  themesWrapper: {

  },
});

class Filters extends React.Component {

  render() {
    const { theme, classes } = this.props;
    const { onChange } = this.props;

    const { channel } = this.props;
    const { opening, variation, phase, openness, endgameType, pawnStructure, themes } = this.props;

    const selectTheme = getSelectTheme(theme);

    return (
      <div className={classes.container}>
        <div className={classes.selectsWrapper}>
          <div className={classes.selectWrapper}>
            <Select
              className={classes.select}
              isClearable={true}
              isSearchable={true}
              placeholder="YouTube channel"
              options={channels}
              value={channel}
              onChange={o => onChange('channel', o)}
              theme={selectTheme}
            />
          </div>
          <div className={classes.selectWrapper}>
            <Select
              className={classes.select}
              isClearable={true}
              isSearchable={true}
              placeholder="Opening name"
              options={Object.keys(openings).map(o => ({label: o, value: o}))}
              value={opening}
              onChange={o => onChange('opening', o)}
              theme={selectTheme}
            />
            { opening && (
              <Select
                className={classes.select}
                isClearable={true}
                isSearchable={true}
                placeholder="Variation"
                options={openings[opening.value].map(o => ({label: o, value: o}))}
                value={variation}
                onChange={o => onChange('variation', o)}
                theme={selectTheme}
              />
            )}
          </div>
          <div className={classes.selectWrapper}>
            <Select
              className={classes.select}
              isClearable={true}
              isSearchable={true}
              placeholder="Game phase"
              options={phases}
              value={phase}
              onChange={o => onChange('phase', o)}
              theme={selectTheme}
            />
            { phase && phase.value === 'endgame' && (
              <Select
                className={classes.select}
                isClearable={true}
                isSearchable={true}
                placeholder="Endgame Type"
                options={endgameTypes}
                value={endgameType}
                onChange={o => onChange('endgameType', o)}
                theme={selectTheme}
              />
            )}
          </div>
          <div className={classes.selectWrapper}>
            <Select
              className={classes.select}
              isClearable={true}
              isSearchable={true}
              placeholder="Position openness"
              options={opennesses}
              value={openness}
              onChange={o => onChange('openness', o)}
              theme={selectTheme}
            />
          </div>
          <div className={classes.selectWrapper}>
            <Select
              className={classes.select}
              isClearable={true}
              isSearchable={true}
              placeholder="Pawn structure"
              options={pawnStructures}
              value={pawnStructure}
              onChange={o => onChange('pawnStructure', o)}
              theme={selectTheme}
            />
          </div>
        </div>
        <div className={classes.themesWrapper}>
          <Select
            className={classes.select}
            isMulti
            isClearable={true}
            isSearchable={true}
            placeholder="Themes"
            options={themesOptions}
            value={themes}
            onChange={o => onChange('themes', o)}
            theme={selectTheme}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Filters);
