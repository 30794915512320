import React, { Component } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
} from 'firebase/auth';

// Material-UI
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import Base from './Pages/Base';
import SignIn from './Pages/SignIn';
import SignUp from './Pages/SignUp';
import ResetPassword from './Pages/ResetPassword';
import Terms from './Pages/Terms';
import QuoteIcon from '@material-ui/icons/FormatQuote';

import validate from 'validate.js';
import readingTime from 'reading-time';

import settings from '../../settings';
import { eventTypes, emitEvent } from '../../events';

import constraints from './constraints';

import logo from '../../Assets/logo_transparent.png';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100vh',
  },
  brandingWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  branding: {
    textAlign: 'center',
  },
  headline: {
    color: '#fff',
    fontWeight: 300,
    letterSpacing: '-0.05rem',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  logo: {
    width: '50%',
    maxWidth: 128,
  },
  videoWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    height: 0,
    overflow: 'hidden',
    maxWidth: '100%',
    margin: '0 auto',
    '& object': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    '& embed': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    // boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  quote: {
    color: '#fff',
    fontStyle: 'italic',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  quoteAuthor: {
    textAlign: 'right',
  },
  content: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

// extracts error text from error object
// e.g. error.message is "[auth/invalid-email] An email address must be provided."
// and error.code is "auth/invalid-email"
// the resulting text is "An email address must be provided."
const errorText = e => {
  return e.message.slice(e.code.length + 3);
}

const getErrorMessage = (e) => {
  let title;
  let message;
  switch (e.code) {
    case "auth/invalid-email":
      title = "Invalid email";
      message = "The entered email address is invalid.";
      break;
    case "auth/user-not-found":
      title = "User not found";
      message = "There is no user with the given email address.";
      break;
    case "auth/wrong-password":
      title = "Wrong password";
      message = "The entered password is invalid.";
      break;
    case "auth/weak-password":
      title = "Weak password";
      message = "Password must have at least 6 characters.";
      break;
    case "auth/email-already-in-use":
      title = "Account already exists"
      message = "An account with this email address already exists. If it is you, try to sign in instead.";
      break;
    default:
      title = "Error";
      message = errorText(e);
  }
  return `${title}: ${message}`;
}

class Auth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPerformingAuthAction: false,

      snackbar: {
        autoHideDuration: 0,
        message: '',
        open: false
      },

      // random quote
      // we determine it in the constructor to avoid it being changed after first render
      quote: settings.quotes[Math.floor(Math.random()*settings.quotes.length)],
    };
  }

  signUp = (emailAddress, password, passwordConfirmation) => {
    if (this.state.isSignedIn) {
      return;
    }

    if (!emailAddress || !password || !passwordConfirmation) {
      return;
    }

    const errors = validate({
      emailAddress: emailAddress,
      password: password,
      passwordConfirmation: passwordConfirmation
    }, {
      emailAddress: constraints.emailAddress,
      password: constraints.password,
      passwordConfirmation: constraints.passwordConfirmation
    });

    if (errors) {
      return;
    }

    const { auth } = this.props;

    this.setState({
      isPerformingAuthAction: true
    }, () => {
      createUserWithEmailAndPassword(auth, emailAddress, password).then((value) => {
        emitEvent(eventTypes.signUpEmail);
        this.setState({
          isPerformingAuthAction: false
        });
      }).catch((reason) => {
        this.openSnackbar(getErrorMessage(reason));
      }).finally(() => {
        this.setState({
          isPerformingAuthAction: false
        });
      });
    });
  };

  signIn = (emailAddress, password) => {
    if (this.state.isSignedIn) {
      return;
    }

    if (!emailAddress || !password) {
      return;
    }

    const errors = validate({
      emailAddress: emailAddress,
      password: password,
    }, {
      emailAddress: constraints.emailAddress,
      password: constraints.password
    });

    if (errors) {
      return;
    }

    const { auth } = this.props;

    this.setState({
      isPerformingAuthAction: true
    }, () => {
      signInWithEmailAndPassword(auth, emailAddress, password).then((value) => {
        const user = value.user;
        const displayName = user.displayName;
        const emailAddress = user.email;

        emitEvent(eventTypes.signInEmail);

        this.openSnackbar(`Signed in as ${displayName || emailAddress}`);
      }).catch((reason) => {
        this.openSnackbar(getErrorMessage(reason));
      }).finally(() => {
        this.setState({
          isPerformingAuthAction: false
        });
      });
    });
  };

  signInWithProvider = (provider) => {
    if (this.state.isSignedIn) {
      return;
    }

    if (!provider) {
      return;
    }

    const { auth } = this.props;

    this.setState({
      isPerformingAuthAction: true
    }, () => {
      signInWithPopup(auth, provider).then((value) => {
        if (value.additionalUserInfo) {
          const { isNewUser } = value.additionalUserInfo;
          if (isNewUser) {
            emitEvent(eventTypes.signUpGoogle);
          } else {
            emitEvent(eventTypes.signInGoogle);
          }
        }
        const user = value.user;
        const displayName = user.displayName;
        const emailAddress = user.email;

        this.openSnackbar(`Signed in as ${displayName || emailAddress}`);
      }).catch((reason) => {
        const code = reason.code;

        switch (code) {
          // we silence these "errors"
          case 'auth/cancelled-popup-request':
          case 'auth/popup-closed-by-user':
            return;

          default:
            this.openSnackbar(getErrorMessage(reason));
            return;
        }
      }).finally(() => {
        this.setState({
          isPerformingAuthAction: false
        });
      });
    });
  };

  resetPassword = (emailAddress) => {
    if (this.state.isSignedIn) {
      return;
    }

    if (!emailAddress) {
      return;
    }

    const errors = validate({
      emailAddress: emailAddress
    }, {
      emailAddress: constraints.emailAddress
    });

    if (errors) {
      return;
    }

    const { auth } = this.props;

    this.setState({
      isPerformingAuthAction: true
    }, () => {
      sendPasswordResetEmail(auth, emailAddress).then(() => {
        this.openSnackbar(`Password reset e-mail sent to ${emailAddress}`);
      }).catch((reason) => {
        const code = reason.code;
        const message = reason.message;

        switch (code) {
          case 'auth/invalid-email':
          case 'auth/missing-android-pkg-name':
          case 'auth/missing-continue-uri':
          case 'auth/missing-ios-bundle-id':
          case 'auth/invalid-continue-uri':
          case 'auth/unauthorized-continue-uri':
          case 'auth/user-not-found':
            this.openSnackbar(message);
            return;

          default:
            this.openSnackbar(message);
            return;
        }
      }).finally(() => {
        this.setState({
          isPerformingAuthAction: false
        });
      });
    });
  };

  openSnackbar = (message) => {
    this.setState({
      snackbar: {
        autoHideDuration: readingTime(message).time * 2,
        message,
        open: true
      }
    });
  };

  closeSnackbar = (clearMessage = false) => {
    const { snackbar } = this.state;

    this.setState({
      snackbar: {
        message: clearMessage ? '' : snackbar.message,
        open: false
      }
    });
  };

  render() {
    const { classes } = this.props;

    const { disagreed, createUser, signOut } = this.props;

    const {
      isPerformingAuthAction,
      snackbar,
    } = this.state;

    const { quote } = this.state;

    const youtubeUrl = 'https://www.youtube.com/embed/LtI3F2fzkRo';

    return (
      <div className={classes.container}>
        <div className={classes.brandingWrapper}>
          <div className={classes.branding}>
            <img src={logo} className={classes.logo} alt="Chessvision.ai logo" />
            <Typography variant="h4" className={classes.headline}>
              {settings.messages.tagline}
            </Typography>
            <div className={classes.videoWrapper}>
              <iframe
                title="Showcase Video"
                src={youtubeUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                className={classes.video}
                allowFullScreen>
              </iframe>
            </div>
            <div className={classes.quote}>
              <Typography className={classes.quoteText}>
                <QuoteIcon />
                {quote.text}
                <QuoteIcon />
              </Typography>
              <Typography className={classes.quoteAuthor}>-- {quote.author}</Typography>
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <Base>
            { disagreed
              ? (
                <Terms
                  onAccept={createUser}
                  onReject={signOut}
                />
              )
              : (
                <Switch>
                  <Route path={settings.routes.HOME} exact>
                    <SignIn
                      isPerformingAuthAction={isPerformingAuthAction}
                      onAuthProviderClick={this.signInWithProvider}
                      signIn={this.signIn}
                    />
                  </Route>
                  <Route path={settings.routes.SIGN_UP} exact>
                    <SignUp
                      isPerformingAuthAction={isPerformingAuthAction}
                      onAuthProviderClick={this.signInWithProvider}
                      signUp={this.signUp}
                    />
                  </Route>
                  <Route path={settings.routes.RESET_PASSWORD} exact>
                    <ResetPassword
                      isPerformingAuthAction={isPerformingAuthAction}
                      resetPassword={this.resetPassword}
                    />
                  </Route>
                  <Route>
                    <Redirect to={settings.routes.HOME} />
                  </Route>
                </Switch>

              )
            }
          </Base>
        </div>
        <Snackbar
          autoHideDuration={snackbar.autoHideDuration}
          message={snackbar.message}
          open={snackbar.open}
          onClose={this.closeSnackbar}
        />
      </div>
    );
  }
}

Auth.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default withStyles(styles)(Auth);
