import React from 'react';

import CheckoutButton from './CheckoutButton';
import CustomerPortalButton from './CustomerPortalButton';

import settings from '../settings';

export const SubscribeButton = ({ plan, ...rest }) => {
  const url = `${settings.apiUrl}/checkout_sessions/subscriptions`;
  const payload = {
    plan,
    success_url: `${settings.hostUrl}${settings.routes.CHECKOUT_SUBSCRIPTION_SUCCESSFUL}`,
    cancel_url: `${settings.hostUrl}${settings.routes.CHECKOUT_SUBSCRIPTION_CANCELED}`,
  };
  return (
    <CheckoutButton url={url} payload={payload} {...rest} />
  );
}

export const ChromeExtensionSubscribeButton = ({ plan, ...rest }) => {
  const url = `${settings.apiUrl}/checkout_sessions/subscriptions`;
  const payload = {
    plan,
    success_url: `${settings.hostUrl}${settings.routes.CHROME_EXTENSION_CHECKOUT_SUBSCRIPTION_SUCCESSFUL}`,
    cancel_url: `${settings.hostUrl}${settings.routes.CHROME_EXTENSION_CHECKOUT_SUBSCRIPTION_CANCELED}`,
  };
  return (
    <CheckoutButton url={url} payload={payload} {...rest} />
  );
}

export { CustomerPortalButton };
