import React from 'react';

import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import FileUploadIcon from '@material-ui/icons/Publish';
import FlipIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import Chess from 'chess.js';

import pgnParser from 'pgn-parser';

import History from './History';
// import Board from './Board/chessboardjsx';
import Board from './Board/chessground';

import Book from './Book';
import Videos from './Videos';
import EditMode from './EditMode';
import ChessableCourses from './ChessableCourses';

import { parseFenFromUrl, validateFen } from '../../utils';

const styles = (theme) => ({
  container: {
    display: 'flex',
    maxWidth: 1600,
    margin: '0 auto',
    justifyContent: 'space-evenly',
  },
  boardWrapper: {
    flex: 4,
  },
  board: {},
  boardButtons: {
    display: 'flex',
  },
  fenInputWrapper: {
    display: 'flex',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    height: 'calc(100vh - 48px)',
    marginRight: theme.spacing(1),
  },
  history: {
    overflowY: 'auto',
    marginTop: theme.spacing(3),
  },
  book: {
    marginTop: theme.spacing(2),
    overflowY: 'auto',
    marginBottom: theme.spacing(2),
    // flex: 2,
  },
  chessable: {
    // flex: 1,
    marginBottom: theme.spacing(2),
  },
  videos: {
    flex: 4,
    maxHeight: `calc(100vh - 48px - ${theme.spacing(3)}px)`,
    marginTop: theme.spacing(3),
    overflowY: 'auto',
  },
  editWrapper: {
    flex: 4,
    marginTop: theme.spacing(3),
  },
});

// TODO: remove this flag, it's only for dev purposes
const showVideos = true;
const START_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

const cloneGame = (game, moveNumber = null) => {
  const history = game.history();

  // recovering the starting fen
  let pgn = game.pgn();
  if (game.history().length === 0) {
    // it is important to add a blank line for the PGN if there are no moves
    // made because if there are no moves made, Chess.js generates a PGN that
    // it could not load later
    pgn += '\n';
  }
  const tmp = new Chess();
  tmp.load_pgn(pgn);
  for (let i = history.length-1; i >= 0; --i) {
    tmp.undo();
  }
  const fen = tmp.fen();

  const clone = new Chess(fen);
  if (moveNumber !== null && moveNumber >= 0) {
    for (let i = 0; i < history.length && i <= moveNumber; ++i) {
      clone.move(history[i]);
    }
  }
  return clone;
}

class BoardExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fen: (props.match?.params.fen && parseFenFromUrl(props.match.params.fen, '_', ' ')) || START_FEN,
      orientation: 'white',

      lastMoveId: -1,

      fenInput: '',

      // videos
      results: null,
      nextResultsId: null,
      loading: false,
      loadingMore: false,

      // pgn load
      pgnImport: '',
      pgnLoadDialog: false,

      // edit mode
      editMode: false,

      // pawn promotion
      pendingMove: null,
      promotionDialog: false,
    }
    this.game = new Chess(this.state.fen);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  onMove = (from, to) => {
    const clone = cloneGame(this.game, this.state.lastMoveId);
    const moves = clone.moves({ verbose: true })
    for (const move of moves) {
      if (move.flags.indexOf("p") !== -1 && move.from === from) {
        this.setState({
          pendingMove: [from, to],
          promotionDialog: true,
        });
        return;
      }
    }

    // see if the move is legal
    const move = clone.move({
      from,
      to,
      promotion: "x",
    });
    if (move === null) {
      this.setState({
        fen: clone.fen(),
      });
    } else {
      this.game = clone;
      this.setState({
        fen: this.game.fen(),
        lastMoveId: this.state.lastMoveId + 1,
      }, () => {
        const e = document.getElementById('history-container');
        e.scrollTop = e.scrollHeight;
      })
    }
  };

  promotion = e => {
    const { pendingMove } = this.state;
    const from = pendingMove[0];
    const to = pendingMove[1];
    const clone = cloneGame(this.game, this.state.lastMoveId);
    const move = clone.move({ from, to, promotion: e });
    if (move !== null) {
      this.game = clone;
      this.setState({
        fen: clone.fen(),
        lastMoveId: this.state.lastMoveId + 1,
        promotionDialog: false,
      }, () => {
        const e = document.getElementById('history-container');
        e.scrollTop = e.scrollHeight;
      })
    }
  }

  moveToFront() {
    const clone = cloneGame(this.game, -1);
    this.setState({
      lastMoveId: -1,
      fen: clone.fen(),
    }, () => {
      const e = document.getElementById('history-container');
      e.scrollTop = e.scrollHeight - e.clientHeight;
    });
  }

  undo() {
    const newLastMoveId = Math.max(-1, this.state.lastMoveId - 1);
    const clone = cloneGame(this.game, newLastMoveId);
    this.setState({
      lastMoveId: newLastMoveId,
      fen: clone.fen(),
    });
  }

  redo() {
    const history = this.game?.history();
    const newLastMoveId = Math.min(this.state.lastMoveId + 1, history.length - 1);
    const clone = cloneGame(this.game, newLastMoveId);
    this.setState({
      lastMoveId: newLastMoveId,
      fen: clone.fen(),
    })
  }

  moveToEnd() {
    const history = this.game?.history();
    const clone = cloneGame(this.game, history.length - 1);
    this.setState({
      lastMoveId: history.length - 1,
      fen: clone.fen(),
    }, () => {
      const e = document.getElementById('history-container');
      e.scrollTop = e.scrollHeight;
    });
  }

  handleBookMoveClick = (uci) => {
    const from = uci.substr(0, 2);
    const to = uci.substr(2, 2);
    this.onMove(from, to);
  }

  handleHistoryMoveClick = (newLastMoveId) => {
    const clone = cloneGame(this.game, newLastMoveId);

    this.setState({
      lastMoveId: newLastMoveId,
      fen: clone.fen(),
    });
  }

  setFen = (fen) => {
    this.game = new Chess(fen);
    this.setState({
      fen: this.game.fen(),
      fenFromInput: '',
      lastMoveId: -1,
    });
  }

  openPgnLoadDialog = () => {
    this.setState({ pgnLoadDialog: true });
  }

  closePgnLoadDialog = () => {
    this.setState({ pgnLoadDialog: false });
  }

  loadPgn = () => {
    const { pgnImport } = this.state;
    let pgn = null;
    try {
      pgn = pgnParser.parse(pgnImport);
    } catch (error) {
      if (error.name === 'SyntaxError') {
        alert('Invalid PGN');
      }
      return;
    }

    let fen = START_FEN;
    for (const header of pgn[0].headers) {
      if (header.name === 'FEN') {
        fen = header.value;
      }
    }
    this.game = new Chess(fen);
    for (const { move } of pgn[0].moves) {
      this.game.move(move);
    }
    this.setState({
      fen,
      lastMoveId: -1,
    }, () => {
      this.closePgnLoadDialog();
    });
  }

  calcMovable = () => {
    const dests = new Map();
    const clone = cloneGame(this.game, this.state.lastMoveId);
    clone.SQUARES.forEach(s => {
      const ms = clone.moves({ square: s, verbose: true });
      if (ms.length) {
        dests.set(s, ms.map(m => m.to));
      }
    });
    return {
      free: false,
      dests,
      color: clone.turn() === "w" ? "white" : "black",
    };
  }

  handleEdit = (fen) => {
    this.setState({ editMode: false });
    this.setFen(fen);
  }

  handleKeyDown = e => {
    if (e.key === 'ArrowLeft') {
      this.undo();
    } else if (e.key === 'ArrowRight') {
      this.redo()
    }
  }

  render() {
    const { classes } = this.props;
    const { user, onUpgradeClick } = this.props;
    const { onUpgradeLoading } = this.props;
    const { fen, orientation, lastMoveId } = this.state;
    const { fenInput } = this.state;
    const { editMode } = this.state;
    const { promotionDialog } = this.state;

    const history = this.game?.history({ verbose: true });
    const lastMove = history && history.length
      ? history[lastMoveId]
      : null;

    const { pgnLoadDialog, pgnImport } = this.state;
    let pgnImportValid = false;
    if (pgnImport !== '') {
      try {
        pgnParser.parse(pgnImport);
        pgnImportValid = true;
      } catch (error) {}
    }

    const fenFromInput = validateFen(fenInput);
    return (
      <>
        <div className={classes.container}>
          <div className={classes.sidebar}>
            <div className={classes.history} id="history-container">
              <History
                lastMoveId={lastMoveId}
                history={history}
                onMoveClick={this.handleHistoryMoveClick}
              />
            </div>
            <div className={classes.book}>
              <Book
                fen={fen}
                handleMoveClick={this.handleBookMoveClick}
              />
            </div>
            { fen !== START_FEN && (
              <div className={classes.chessable}>
                <ChessableCourses fen={fen} />
              </div>
            )}
          </div>
          { editMode
          ? (
            <div className={classes.editWrapper}>
              <EditMode
                fen={fen}
                onApply={this.handleEdit}
                onCancel={() => { this.setState({ editMode: false })}}
              />
            </div>
          )
          : (
            <div className={classes.boardWrapper}>
              <div className={classes.fenInputWrapper}>
                <TextField
                  label="FEN"
                  size="small"
                  variant="outlined"
                  value={fenInput || fen}
                  onChange={(e) => { this.setState({ fenInput: e.target.value })}}
                  style={{ flex: 1 }}
                />
                <Button
                  onClick={() => { this.setFen(fenFromInput) }}
                  size="small"
                  variant="contained"
                  disabled={fenFromInput === null || fenFromInput === fen}
                  style={{ marginLeft: '1rem', marginRight: '0.5rem', textTransform: 'none' }}
                >Set FEN</Button>
              </div>
              <div className={classes.board}>
                <Board
                  fen={fen}
                  onMove={this.onMove}
                  orientation={orientation}
                  lastMove={lastMove ? [lastMove.from, lastMove.to] : null}
                  movable={this.calcMovable()}
                />
                <div className={classes.boardButtons}>
                  <IconButton
                    variant="outlined"
                    onClick={() => { this.moveToFront() }}
                    disabled={lastMoveId === -1}
                  ><DoubleArrowIcon style={{ transform: 'rotate(180deg)' }}/></IconButton>
                  <IconButton
                    variant="outlined"
                    onClick={() => { this.undo() }}
                    disabled={lastMoveId === -1}
                  ><ArrowBackIcon /></IconButton>
                  <IconButton
                    variant="outlined"
                    onClick={() => { this.redo() }}
                    disabled={!history || lastMoveId === history.length - 1}
                  ><ArrowForwardIcon /></IconButton>
                  <IconButton
                    variant="outlined"
                    onClick={() => { this.moveToEnd() }}
                    disabled={!history || lastMoveId === history.length - 1}
                  ><DoubleArrowIcon /></IconButton>
                  <div style={{ flex: 1}} />
                  <Tooltip title="Flip board">
                    <IconButton
                      onClick={() => { this.setState({ orientation: orientation === 'white' ? 'black' : 'white' }) }}
                    ><FlipIcon /></IconButton>
                  </Tooltip>
                  <Tooltip title="Edit board">
                    <IconButton
                      onClick={() => { this.setState({ editMode: true }) }}
                    ><EditIcon /></IconButton>
                  </Tooltip>
                  <Tooltip title="Load PGN">
                    <IconButton
                      onClick={() => { this.openPgnLoadDialog() }}
                      style={{ marginRight: '1rem' }}
                    ><FileUploadIcon /></IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
          <div className={classes.videos}>
            { showVideos && (
              <Videos
                user={user}
                fen={fen}
                onUpgradeClick={onUpgradeClick}
                onUpgradeLoading={onUpgradeLoading}
              />
            )}
          </div>
          <Dialog
            open={pgnLoadDialog}
            onClose={this.closePgnLoadDialog}
          >
            <DialogTitle>Import PGN</DialogTitle>
            <DialogContent>
              <span>
                Paste a PGN here to load the first game from the PGN to the Board Explorer.
              </span>
              <br />
              <br />
              <textarea
                id="pgn-import-textarea"
                cols={65}
                rows={20}
                className={classes.pgnImportTextarea}
                onChange={(e) => { this.setState({ pgnImport: e.target.value }) }}
                value={pgnImport}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="default"
                variant="text"
                onClick={() => { this.closePgnLoadDialog() }}
              >Cancel</Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => { this.loadPgn() }}
                disabled={!pgnImportValid}
              >Load PGN</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={promotionDialog} onClose={() => { this.setState({ promotionDialog: false })}}>
            <DialogContent>
              <Button onClick={() => { this.promotion("q")}}>Queen</Button>
              <Button onClick={() => { this.promotion("r")}}>Rook</Button>
              <Button onClick={() => { this.promotion("b")}}>Bishop</Button>
              <Button onClick={() => { this.promotion("n")}}>Knight</Button>
            </DialogContent>
          </Dialog>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(BoardExplorer));
