import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    width: '28px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '28px',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  barsWrapper: {
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
    width: '100%',
    zIndex: -1,
  },
  bar: {
    position: 'absolute',
    left: 0,
    height: '100%',
    width: '100%',
    transition: 'transform 0.1s ease-in',
  },
  score: {
    position: 'absolute',
    left: 0,
    zIndex: 999,
    fontSize: '0.7rem',
    textAlign: 'center',
    display: 'inline-block',
    width: '100%',
    padding: '8px 0',
    letterSpacing: '-0.05rem',
  },
  black: {
    backgroundColor: '#403d39',
  },
  white: {
    backgroundColor: '#fff',
  }
}));

const EvalBar = ({ flipped, evalScore }) => {
  const classes = useStyles();
  if (evalScore === null || evalScore.score === undefined) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress size={20} />
      </div>
    )
  }

  const { score, mate, stalemate, result } = evalScore;
  const whiteBetter = mate
    ? (score > 0 || (score === 0 && result === '1-0'))
    : score >= 0;
  const offsetPercents = mate === false
    ? Math.max(0, 50 - score / 10)
    : score > 0 || (score === 0 && result === "1-0") ? 0 : 100;
  const scoreText = mate === false
    ? (
      stalemate
      ? result
      : `${score >= 0 ? '+' : ''}${Math.round(score / 100 * 10) / 10}`
    )
    : (
      score !== 0
      ? (score >= 0 ? '+M' : '-M') + Math.abs(score)
      : result
    );

  return (
    <div className={classes.container} style={{ transform: `rotate(${flipped ? '180deg' : 0})` }}>
      <div className={classes.score} style={{
          color: whiteBetter ? 'black' : 'white',
          bottom: whiteBetter ? 0 : 'auto',
          top: !whiteBetter ? 0 : 'auto',
          transform: `rotate(${flipped ? '180deg' : 0})`,
      }}>
        {scoreText}
      </div>
      <div className={classes.barsWrapper}>
        <div className={`${classes.bar} ${classes.black}`}></div>
        <div className={`${classes.bar} ${classes.white}`} style={{ transform: `translate3d(0, ${offsetPercents}%, 0)` }}></div>
      </div>
    </div>
    );
};

export default EvalBar;
