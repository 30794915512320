import React, { Component } from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';

import DialogContentText from '@material-ui/core/DialogContentText';

import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import EmailIcon from '@material-ui/icons/Email';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import Profile from '../../Layout/Profile';

import ConfirmationDialog from '../../Dialogs/Confirmation';
import { CustomerPortalButton } from '../../Buttons/';

import { AccountDeletionRequestsStorage } from '../../db';

import settings from '../../settings';

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(0),
  },

  dialogContentTextUserComplete: {
    marginTop: theme.spacing(1)
  },

  listItemContainer: {
    display: 'flex',
  },

  listItem: {
    paddingRight: '1rem',
  },

  listItemSecondaryAction: {
    transform: 'none',
    float: 'none',
    marginLeft: '1rem',
    position: 'static',
    minWidth: '14rem',
  },

  actionButtons: {
  },
  actionButtonWrapper: {
    marginBottom: '0.5rem',
  },
});

const AccountType = ({ subscription }) => {
  if (subscription === undefined) {
    return '';
  }
  if (subscription === null || subscription.ended_at !== null) {
    return (
      <span>Free, limited functionality</span>
    );
  }
  return (
    <React.Fragment>
      <span>Member, unlimited functionality</span>
      { subscription.cancel_at_period_end && (
        <span>
          <br />
          Cancels at {moment.unix(subscription.current_period_end).format('LLLL')}
        </span>
      )}
    </React.Fragment>
  );
}

class AccountTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountDeletionInProgress: undefined,
      deleteStep: null,
      deleteConfirmText: '',

      cancelSubscriptionDialog: {
        open: false
      },
      isFetchingSubsription: false,
      subscription: undefined,
    };
    this.handleCancelSubscription = this.handleCancelSubscription.bind(this);
  }

  async componentDidMount() {
    await this.fetchAccountDeletionInfo();
    await this.fetchSubscription();
  }

  async fetchAccountDeletionInfo() {
    const { user } = this.props;
    const exists = await AccountDeletionRequestsStorage.exists(user.uid);
    this.setState({
      accountDeletionInProgress: exists,
    });
  }

  advanceDeleteDialog = () => {
    const { deleteStep } = this.state;
    this.setState({
      deleteStep: deleteStep === null ? 1 : deleteStep + 1,
    });
  }

  async createAccountDeletionRequest() {
    const { user } = this.props;
    await AccountDeletionRequestsStorage.create(user.uid);
    this.setState({
      accountDeletionInProgress: true,
      deleteStep: 3,
    });
  }

  dismissDeleteDialog = () => {
    this.setState({
      deleteStep: null,
      deleteConfirmText: '',
    });
  }

  async fetchSubscription() {
    const { getSubscription } = this.props;

    this.setState({
      isFetchingSubsription: true,
    }, async () => {
      const sub = await getSubscription();
      if (sub !== null) {
        this.setState({
          isFetchingSubsription: false,
          subscription: sub,
        });
      } else {
        this.setState({
          isFetchingSubsription: false,
          subscription: null,
        });
      }
    });
  }

  handleCancelSubscription() {
    const { onCancelSubscriptionClick } = this.props;

    this.setState({
      isFetchingSubsription: true,
    }, () => {
      onCancelSubscriptionClick()
      .then(subscription => {
        this.setState({
          isFetchingSubsription: false,
          subscription,
        });
        this.closeCancelSubscriptionDialog();
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isFetchingSubsription: false,
        });
        this.closeCancelSubscriptionDialog();
      });
    });
  }

  openCancelSubscriptionDialog = () => {
    this.setState({
      cancelSubscriptionDialog: {
        open: true
      }
    });
  };

  closeCancelSubscriptionDialog = (callback) => {
    this.setState({
      cancelSubscriptionDialog: {
        open: false
      }
    }, () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const {
      user, isPerformingAuthAction, onUpgradeClick,
    } = this.props;

    const {
      cancelSubscriptionDialog, isFetchingSubsription, subscription,
    } = this.state;

    const isUserComplete = (user.photoURL && user.displayName && user.email);

    const { accountDeletionInProgress } = this.state;
    const { deleteStep, deleteConfirmText } = this.state;

    return (
      <React.Fragment>
        <Profile user={user} extraTopMargin />

        <DialogContentText classes={{ root: classes.root }} className={isUserComplete && classes.dialogContentTextUserComplete}>
          Here's some info about your account.
        </DialogContentText>

        <List>
          {user.email &&
            <ListItem>
              <ListItemIcon>
                <Tooltip title="E-mail address">
                  <EmailIcon />
                </Tooltip>
              </ListItemIcon>

              <ListItemText primary={user.email} />
            </ListItem>
          }

          <ListItem classes={{ root: classes.listItem, container: classes.listItemContainer }}>
            <ListItemIcon>
              <Tooltip title="Account Type">
                <AccessTimeIcon />
              </Tooltip>
            </ListItemIcon>

            { settings.earlyAccess
              ? (
                <ListItemText
                  primary="Account Type"
                  secondary="Early Access"
                />
              )
              : (
                <>
                  <ListItemText
                    primary="Account Type"
                    secondary={<AccountType subscription={subscription} />}
                  />
                  <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                    { isFetchingSubsription &&
                      <CircularProgress />
                    }
                    { !isFetchingSubsription && subscription !== undefined && (subscription === null || subscription.ended_at) &&
                      <Button color="secondary" user={user} disabled={isPerformingAuthAction} variant="contained" onClick={onUpgradeClick}>Upgrade</Button>
                    }
                    {
                      /*
                      !isFetchingSubsription && subscription !== undefined && subscription !== null && subscription.ended_at === null && subscription.cancel_at_period_end &&
                      <Button color="secondary" disabled={isPerformingAuthAction} variant="contained" onClick={onExtendClick}>Extend</Button>
                      */
                    }
                    { !isFetchingSubsription && subscription !== undefined && subscription !== null && subscription.ended_at === null && !subscription.cancel_at_period_end &&
                      <div className={classes.actionButtons}>
                        <div className={classes.actionButtonWrapper}>
                          <CustomerPortalButton user={user} size="small" text="Update Billing Info" />
                        </div>
                        <div className={classes.actionButtonWrapper}>
                          <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={this.openCancelSubscriptionDialog}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    }
                  </ListItemSecondaryAction>
                </>
              )
            }
          </ListItem>
          {
          /*
          {user.metadata.lastSignInTime &&
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Last sign-in">
                  <AccessTimeIcon />
                </Tooltip>
              </ListItemIcon>

              <ListItemText primary="Last sign-in" secondary={moment(user.metadata.lastSignInTime).format('LLLL')} />
            </ListItem>
          }

          {user.metadata.creationTime &&
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Signed up">
                  <AccessTimeIcon />
                </Tooltip>
              </ListItemIcon>

              <ListItemText primary="Signed up" secondary={moment(user.metadata.creationTime).format('LLLL')} />
            </ListItem>
          }
          */
          }
          <Divider style={{ marginTop: 64 }} />
          <ListItem>
          { accountDeletionInProgress && (
            <span>
              Account deletion is in progress. 
              It will take at most 7 business days to delete the account, but usually, it will happen sooner.
              We will send a confirmation email to {user.email} when the deletion is completed.
            </span>
          )}
          { accountDeletionInProgress === false && (
            <Button
              onClick={this.advanceDeleteDialog}
              disabled={deleteStep !== null}
            >Delete Account</Button>
          )}
          </ListItem>
        </List>

        <ConfirmationDialog
          open={cancelSubscriptionDialog.open}

          title="Cancel subscription?"
          content={`If you cancel the subscription you'll be able to fully use the App${subscription !== undefined && subscription !== null ? ' until ' + moment.unix(subscription.current_period_end).format('LLLL') : ''}. After this date, you won't be not able to use all funtionality of the App. Do you still want to cancel the subscription?`}
          okText="Do Not Cancel"
          cancelText="Cancel Subscription"
          disableCancelButton={isFetchingSubsription}
          highlightOkButton

          onClose={this.closeCancelSubscriptionDialog}
          onCancelClick={this.handleCancelSubscription}
          onOkClick={this.closeCancelSubscriptionDialog}
        />

        { /* Delete account step dialogs */ }
        <ConfirmationDialog
          open={deleteStep === 1}
          title="Do you want to start the account deletion process?"
          content={`If you have any active auto-renewable subscriptions, cancel them before continuing to ensure that your billinng will not continue.`}
          okText="Cancel"
          cancelText="Delete account"
          highlightOkButton

          onClose={this.dismissDeleteDialog}
          onCancelClick={this.advanceDeleteDialog}
          onOkClick={this.dismissDeleteDialog}
        />
        <ConfirmationDialog
          open={deleteStep === 2}
          title="Delete account"
          content={`If you want to delete the account for email ${user.email} with all associated data, write DELETE in the below input and then click the Delete Account button. This process is irreversible.`}
          okText="Cancel"
          cancelText="Delete account"
          highlightOkButton
          disableCancelButton={deleteConfirmText !== 'DELETE'}

          onClose={this.dismissDeleteDialog}
          onCancelClick={async () => { await this.createAccountDeletionRequest() }}
          onOkClick={this.dismissDeleteDialog}
        >
          <TextField
            size="small"
            margin="none"
            onChange={e => { this.setState({ deleteConfirmText: e.target.value.toUpperCase() }) }}
            variant="outlined"
            helperText="type DELETE above"
            value={deleteConfirmText}
          />
        </ConfirmationDialog>
        <ConfirmationDialog
          open={deleteStep === 3}
          title="Account deletion process started"
          content={`We received your request to start the account deletion process. It will take at most 7 business days to delete the account, but usually, it will happen sooner. We will send a confirmation email to ${user.email} when the deletion is completed.`}
          okText="Ok"
          highlightOkButton

          onClose={this.dismissDeleteDialog}
          onOkClick={this.dismissDeleteDialog}
        />

      </React.Fragment>
    );
  }
}

AccountTab.propTypes = {
  classes: PropTypes.object.isRequired,

  user: PropTypes.object.isRequired,
  isPerformingAuthAction: PropTypes.bool.isRequired,

  onCancelSubscriptionClick: PropTypes.func.isRequired,
  onUpgradeClick: PropTypes.func.isRequired,
  getSubscription: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccountTab);
