import React, { Component } from 'react';

import validate from 'validate.js';

import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import constraints from '../constraints';
import settings from '../../../settings';

import styles from './styles';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      errors: null,
    };
  }

  resetPassword = () => {
    const { emailAddress } = this.state;

    const errors = validate({
      emailAddress: emailAddress
    }, {
      emailAddress: constraints.emailAddress
    });

    if (errors) {
      this.setState({ errors });
    } else {
      this.setState({
        errors: null
      }, () => {
        this.props.resetPassword(emailAddress);
      });
    }
  };

  handleKeyPress = (event) => {
    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      event.preventDefault();

      this.resetPassword();
    }
  };

  handleEmailAddressChange = (event) => {
    const emailAddress = event.target.value;
    this.setState({ emailAddress });
  };

  handleResetPasswordClick = () => {
    this.resetPassword();
  };

  render() {
    const { classes } = this.props;

    // Properties
    const { isPerformingAuthAction } = this.props;

    const { emailAddress, errors } = this.state;

    return (
      <div>
        <div className={classes.headerWrapper}>
          <div className={classes.headerDivider} />
          <Typography
            className={classes.header}
            >Reset password</Typography>
        </div>

        <div className={classes.formHelper}>
          An e-mail will be sent to your e-mail address containing instructions on how to reset your password.
        </div>

        <form>
          <TextField
            autoComplete="email"
            autoFocus
            error={!!(errors && errors.emailAddress)}
            fullWidth
            helperText={(errors && errors.emailAddress) ? errors.emailAddress[0] : ''}
            margin="normal"
            onChange={this.handleEmailAddressChange}
            placeholder="E-mail address"
            required
            type="email"
            value={emailAddress}
          />
        </form>

        <div className={classes.actions}>
          <Button color="primary" disabled={!emailAddress || isPerformingAuthAction} variant="contained" onClick={this.handleResetPasswordClick}>Reset Password</Button>
        </div>

        <div className={classes.formHelper}>
          <Link to={settings.routes.SIGN_IN}>
            <Button
              variant="text"
              size="small"
              disabled={isPerformingAuthAction}>
              Back to Login</Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ResetPassword);
