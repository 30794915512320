import React from 'react';

import Chessground from 'react-chessground'
import 'react-chessground/dist/styles/chessground.css'

class Board extends React.Component {
  render () {
    const { fen, lastMove, onMove, orientation, movable } = this.props;
    const turn = fen.split(' ')[1] === 'w' ? 'white' : 'black';
    return (
      <Chessground
        fen={fen}
        turnColor={turn}
        style={{
          width: "calc(40vw - 24px)",
          height: "calc(40vw - 24px)",
          maxWidth: "calc(1600px * 4 / 10 - 24px)",
          maxHeight: "calc(1600px * 4 / 10 - 24px)",
        }}
        lastMove={lastMove}
        onMove={onMove}
        orientation={orientation}
        selectable={{ enabled: true }}
        movable={movable}
      />
    );
  }
}

export default Board;
