import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Result from './Result';

const useStyles = makeStyles(theme => ({
  container: {
  },
  resultWrapper: {
    paddingBottom: '1rem',
  },
  empty: {
    paddingBottom: '1rem',
    textAlign: 'center',
  },
  emptyHeader: {
    fontSize: '1.5rem',
  }
}));

const Results = ({ results }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      { results.length === 0 && (
        <div className={classes.empty}>
          <Typography className={classes.emptyHeader}>
            No videos found
          </Typography>
          <Typography variant="body1">
            We constantly analyze new videos being published on YouTube so the results might appear here in the future.
          </Typography>
        </div>
      )}
      { results.length > 0 && results.map(({ video, channel, occurrences }) => {
        const timestamps = occurrences.map(occ => occ.timestamps).flat();

        // it is important to sort because they may come from
        // different occurrences
        timestamps.sort((a, b) => a-b);

        // filter out timestamps with a gap less than 30s to the previus one
        let finalTimestamps = []
        const thresh = 30;
        for (const ts of timestamps) {
          if (finalTimestamps.length === 0 || (ts - finalTimestamps[finalTimestamps.length-1]) >= thresh) {
            finalTimestamps.push(ts);
          }
        }

        // we link to 1s before the exact timestamp so that it improves
        // user's experience while starting watching the video

        // we max with 1 because apparently YouTube doesn't understand timestamp 0
        // in the video urls, i.e. when there is a t=0 in the URL, YouTube seems
        // to ignore it and opens the video at the last remembered timestamp, i.e.
        // the timestamp you finished watching the video last time
        finalTimestamps = finalTimestamps.map(t => Math.max(1, t-1));
        return (
          <div key={`${video.id}-${finalTimestamps.join(';')}`} className={classes.resultWrapper}>
            <Result timestamps={finalTimestamps} video={video} channel={channel} />
          </div>
        );
      })}
    </div>

  );
}

export default Results;
