import React from 'react';

import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube';

import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import TimeAgo from 'react-timeago'

import {
  truncate, nFormatter, toTitleCase, timestampFormatter,
} from '../../utils';

import settings from '../../settings';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      paddingBottom: '1rem',
    },
  },
  // solution to auto scalling to width player from: https://github.com/CookPete/react-player/issues/145
  playerWrapper: {
    maxWidth: '450px',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      paddingTop: '56.25%', /* Player ratio: 100 / (1280 / 720) */
      maxWidth: 'none',
    },
  },
  player: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  playIcon: {
    fontSize: '64px',
  },
  infoContainer: {
    flex: 1,
    fontSize: '0.8rem',
    paddingLeft: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0.5rem',
      paddingLeft: 0,
      marginRight: '1rem',
    }
  },
  titleWrapper: {
    fontSize: '1.2rem',
  },
  title: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  subtitle: {
    marginTop: '0.2rem',
    '& > span': {
    },
  },
  channelWrapper: {
    marginTop: '0.75rem',
  },
  channel: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  description: {
    marginTop: '0.75rem',
    // color: '#606060',
  },
  actions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const formatTags = ({
  opening, variation, phase, endgame_type, openness, pawn_structure,
  doubled_pawns, connected_passed_pawns, opposition, development_lead,
  same_side_castling, opposite_side_castling,
  pawn_sacrifice, piece_sacrifice, exchange_sacrifice, queen_sacrifice,
}) => {
  let arr = [];
  if (opening) {
    arr.push(variation ? `${opening}: ${variation}` : opening);
  }
  if (endgame_type) {
    arr.push(`${endgame_type} endgame`);
  } else if (phase) {
    arr.push(`${phase}`);
  }
  if (openness) {
    arr.push(`${openness} position`);
  }
  if (pawn_structure) {
    arr.push(`${pawn_structure} pawn structure`);
  }
  if (doubled_pawns) {
    arr.push(`Doubled Pawns`);
  }
  if (connected_passed_pawns) {
    arr.push(`Connected Passed Pawns`);
  }
  if (opposition) {
    arr.push(`Opposition`);
  }
  if (development_lead) {
    arr.push(`Lead in Development`);
  }
  if (same_side_castling) {
    arr.push(`Same Side Castling`);
  }
  if (opposite_side_castling) {
    arr.push(`Opposite Side Castling`);
  }

  if (queen_sacrifice) {
    arr.push(`Queen Sacrifice`);
  } else if (exchange_sacrifice) {
    arr.push(`Exchange Sacrifice`);
  } else if (piece_sacrifice) {
    arr.push(`Piece Sacrifice`);
  } else if (pawn_sacrifice) {
    arr.push(`Pawn Sacrifice`);
  }

  arr = arr.map(e => toTitleCase(e)
  .replaceAll(' And ', ' and ')
  .replaceAll(' Versus ', ' vs. '));
  return arr.join(" • ");
};
const Result = ({ fen, timestamp, full_tags, video, channel }) => {
  const classes = useStyles();

  // we link to 1s before the exact timestamp so that it improves
  // user's experience while starting watching the video
  timestamp = timestamp - 1;

  // we max with 1 because apparently YouTube doesn't understand timestamp 0
  // in the video urls, i.e. when there is a t=0 in the URL, YouTube seems
  // to ignore it and opens the video at the last remembered timestamp, i.e.
  // the timestamp you finished watching the video last time
  timestamp = Math.max(1, timestamp);
  const url = `https://www.youtube.com/watch?v=${video.id}&t=${timestamp}`;

  const watchUrl = `${settings.routes.VIDEO_WATCH}/${video.id}?t=${timestamp}`;

  return (
    <div className={classes.container}>
      <div className={classes.playerWrapper}>
        <Hidden xsDown>
          <ReactPlayer
            url={url}
            controls={true}
            light={true}
            width={450}
            height={300}
            playing={true}
            playIcon={<PlayCircleFilledWhiteIcon style={{ fontSize: '96px', fill: 'red', backgroundColor: '#fff', borderRadius: '50%' }} />}
            className={classes.player}
          />
        </Hidden>
        <Hidden smUp>
          <ReactPlayer
            url={url}
            controls={true}
            light={true}
            width="100%"
            height="100%"
            playing={true}
            playIcon={<PlayCircleFilledWhiteIcon style={{ fontSize: '96px', fill: 'red', backgroundColor: '#fff', borderRadius: '50%' }} />}
            className={classes.player}
          />
        </Hidden>
      </div>
      <div className={classes.infoContainer}>
        <Typography className={classes.titleWrapper}>
          <a href={url} target="_blank" rel="noopener noreferrer" className={classes.title}>{video.title}</a>
        </Typography>
        <div className={classes.subtitle}>
          <span className={classes.timestamp}>at {timestampFormatter(timestamp)}</span> •&nbsp;
          <span className={classes.viewCount}>
            {nFormatter(video.views)} views
          </span> •&nbsp;
          <span className={classes.date}>
            <TimeAgo date={video.upload_date} />
          </span>
        </div>
        <div className={classes.subtitle}>
          <span className={classes.tags}>
            {formatTags(full_tags)}
          </span>
        </div>
        <div className={classes.channelWrapper}>
          <a href={`https://youtube.com/channel/${channel.id}`} target="_blank" rel="noopener noreferrer" className={classes.channel}>{channel.name}</a>
        </div>
        <Hidden only="xs">
          <div className={classes.description}>
            {truncate(video.description, 120)}
          </div>
        </Hidden>
        <div className={classes.actions}>
          <Button
            component={Link}
            variant="outlined"
            to={watchUrl}
            color="primary"
            style={{ textTransform: 'none', minWidth: 'auto' }}
          >Watch with Chessvision.ai</Button>
        </div>
      </div>
    </div>
  );
}

export default Result;
