import Switch from '@material-ui/core/Switch';

import { withStyles } from '@material-ui/styles';

const styles = (theme) => ({
  switchBase: {
    color: 'black',
    '&$checked': {
      color: 'white',
    },
    '&$checked + $track': {
      backgroundColor: 'gray',
    },
  },
  checked: {},
  track: {
    backgroundColor: 'gray'
  },
});

export default withStyles(styles)(Switch);
