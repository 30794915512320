import React, { Component } from 'react';

import validate from 'validate.js';

import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import constraints from '../constraints';

import AuthProviders from './Providers';

import { withStyles } from '@material-ui/core/styles';

import settings from '../../../settings';

import styles from './styles';


class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      password: '',
      errors: null,
    };
  }

  signIn = () => {
    const { emailAddress, password } = this.state;

    const errors = validate({
      emailAddress: emailAddress,
      password: password
    }, {
      emailAddress: constraints.emailAddress,
      password: constraints.password
    });

    if (errors) {
      this.setState({ errors });
    } else {
      this.setState({
        errors: null
      }, () => {
        this.props.signIn(emailAddress, password);
      });
    }
  };

  handleKeyPress = (event) => {
    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      this.signIn();
    }
  };

  handleEmailAddressChange = (event) => {
    const emailAddress = event.target.value;
    this.setState({ emailAddress });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;
    this.setState({ password });
  };

  render() {
    // Styles
    const { classes } = this.props;

    // Properties
    const { isPerformingAuthAction } = this.props;

    // Events
    const { onAuthProviderClick } = this.props;

    const { emailAddress, password, errors } = this.state;

    return (
      <div>
        <AuthProviders
          isPerformingAuthAction={isPerformingAuthAction}
          onAuthProviderClick={onAuthProviderClick}
        />
        <div className={classes.headerWrapper}>
          <div className={classes.headerDivider} />
          <Typography
            className={classes.header}
            >Sign in with email</Typography>
        </div>
        <form>
          <TextField
            size="small"
            autoComplete="email"
            error={!!(errors && errors.emailAddress)}
            fullWidth
            helperText={(errors && errors.emailAddress) ? errors.emailAddress[0] : ''}
            margin="normal"
            onChange={this.handleEmailAddressChange}
            placeholder="E-mail address"
            required
            type="email"
            value={emailAddress}
          />
          <TextField
            size="small"
            autoComplete="current-password"
            error={!!(errors && errors.password)}
            fullWidth
            helperText={(errors && errors.password) ? errors.password[0] : ''}
            margin="normal"
            onChange={this.handlePasswordChange}
            placeholder="Password"
            required
            type="password"
            value={password}
          />

          <div className={`${classes.formHelper} ${classes.formHelperSmall}`} >
            Forgot your password?
            <Link to={settings.routes.RESET_PASSWORD}>
              <Button
                variant="text"
                size="small"
                disabled={isPerformingAuthAction}>
                Reset it</Button>
            </Link>
          </div>
        </form>
        <div className={classes.actions}>
          <Button
            color="primary"
            disabled={(!emailAddress || !password) || isPerformingAuthAction}
            variant="contained"
            fullWidth
            onClick={() => { this.signIn() }}>Login</Button>
        </div>
        <div className={classes.formHelper}>
          Don't have an account?
          <Link to={settings.routes.SIGN_UP}>
            <Button
              variant="text"
              size="small"
              disabled={isPerformingAuthAction}>
              Sign up</Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SignIn);
