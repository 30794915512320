import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  notation: {
    position: 'absolute',
    fontSize: 8,
    fontWeight: 'bold',
  },
  moveIndicator: {
    width: 24,
    height: 24,
    opacity: 0.3,
    backgroundColor: '#208530',
    borderRadius: 12,
  },
});

class Square extends Component {
  static propTypes = {
    size: PropTypes.number.isRequired,
    showNotation: PropTypes.bool,
    rowIndex: PropTypes.number.isRequired,
    position: PropTypes.string.isRequired,
    columnName: PropTypes.string.isRequired,
    columnIndex: PropTypes.number.isRequired,
    dimension: PropTypes.number.isRequired,
    reverseBoard: PropTypes.bool,
  };

  renderNotations(isBlack) {
    const { classes } = this.props;
    const {
      showNotation,
      rowIndex,
      columnIndex,
      columnName,
      dimension,
      reverseBoard,
    } = this.props;
    const notations = [];
    const transform = [
      {
        rotate: reverseBoard ? '180deg' : '0deg',
      },
    ];

    if (showNotation) {
      if (columnIndex + 1 === dimension) {
        notations.push(
          <Typography
            key={'row_notations'}
            className={classes.notation}
            style={{
              color: isBlack ? '#B58863' : '#F0D9B5',
              top: 0,
              right: 0,
              transform,
            }}
          >
            {dimension - rowIndex}
          </Typography>,
        );
      }

      if (rowIndex + 1 === dimension) {
        notations.push(
          <Typography
            key={'column_notation'}
            className={classes.notation}
            style={{
              color: isBlack ? '#B58863' : '#F0D9B5',
              bottom: 0,
              left: 0,
              transform,
            }}
          >
            {columnName}
          </Typography>,
        );
      }
    }

    return notations;
  }

  render() {
    const { classes } = this.props;
    const {
      size,
      rowIndex,
      columnIndex,
    } = this.props;
    const isBlack = (rowIndex + columnIndex) % 2 === 0;
    const backgroundColor = isBlack ? '#F0D9B5' : '#B58863';

    return (
      <div
        className={classes.container}
        style={{
          backgroundColor,
          width: size,
          height: size,
        }}
      >
        {this.renderNotations(isBlack)}
      </div>
    );
  }
}

export default withStyles(styles)(Square);
