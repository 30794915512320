import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import EmptyState from '../../Layout/EmptyState';

import { messageExtension } from '../../utils';

import { eventTypes, emitEvent } from '../../events';
import settings from '../../settings';

const styles = (theme) => ({
  root: {
    // backgroundColor: theme.palette.primary.light,
    minHeight: '100vh',
  },
  emptyStateIcon: {
    fontSize: theme.spacing(12),
    color: theme.palette.success.main,
  },
});

class CheckoutSuccessfulContent extends Component {
  constructor(props) {
    super(props);
    emitEvent(eventTypes.checkoutSuccess);
  }

  componentDidMount() {
    messageExtension({ action: 'upgrade_successful' });
  }

  render() {
    // Styling
    const { classes } = this.props;

    const { title, text } = this.props;

    return (
      <div className={classes.root}>
        <EmptyState
          success
          icon={<CheckIcon className={classes.emptyStateIcon} />}
          title={title}
          description={text}
        />
      </div>
    );
  }
}

CheckoutSuccessfulContent.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default withStyles(styles)(CheckoutSuccessfulContent);
