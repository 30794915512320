import React, { Component } from 'react';

import gray from '@material-ui/core/colors/grey';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import { eventTypes, emitEvent } from '../events';

import settings from '../settings';

const styles = (theme) => ({
  buttonDisabled: {
    backgroundColor: `${gray[300]} !important`,
  },
  progress: {
    marginRight: theme.spacing(1),
  }
});

class CustomerPortalButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecting: false,
    };
    this.beginRedirect = this.beginRedirect.bind(this);
  }

  beginRedirect() {
    const { user } = this.props;

    emitEvent(eventTypes.customerPortalOpen);

    this.setState({
      redirecting: true,
    });

    return user.getIdToken().then(authToken => {
      const url = `${settings.apiUrl}/customer_portal_sessions`
      const payload = {
        'return_url': settings.hostUrl,
      };
      return fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: new Headers({
         'Authorization': `Bearer ${authToken}`,
         'Accept': 'application/json',
         'Content-Type': 'application/json',
        }),
      })
      .then(response => response.json())
      .then(session => {
        window.location.href = session.url;
      })
      .catch(error => {
        this.setState({
          redirecting: false,
        });
      });
    });
  }

  render() {
    // Styling
    const { classes } = this.props;

    const { disabled, variant, size, text, color, fullWidth } = this.props;
    const { redirecting } = this.state;
    return (
      <Button
        color={color || "primary"}
        variant={variant || "outlined"}
        size={size || "normal"}
        onClick={this.beginRedirect}
        disabled={redirecting || disabled}
        classes={{ root: classes.button, disabled: classes.buttonDisabled }}
        fullWidth={fullWidth || false}
      >
        {redirecting &&
          <CircularProgress size={20} className={classes.progress} />
        }
        {text}
      </Button>
    )
  }
}

CustomerPortalButton.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(CustomerPortalButton);
