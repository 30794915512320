import settings from './settings';

const storage = window.localStorage;

const defaults = {
  'book/type': settings.values.book.defaults.type,
  'book/speeds': settings.values.book.defaults.speeds,
  'book/ratings': settings.values.book.defaults.ratings,
};

class UserStorage {
  static get(key) {
    const val = storage.getItem(key);
    if (val === null) {
      return defaults[key];
    }
    return JSON.parse(val);
  }
  static set(key, value) {
    storage.setItem(key, JSON.stringify(value));
  }
}

export default UserStorage;
