import React from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';

import UserStorage from '../../../user_storage';
import settings from '../../../settings';
import { getSelectTheme, nFormatter } from '../../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    height: '1rem',
    overflow: 'hidden',
    display: 'flex',
    background: 'black',
  },
  bar: {
    height: '100%',
    fontSize: '0.7rem',
    lineHeight: '1.1rem',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  white: {
    backgroundColor: '#efefef',
    color: 'black',
  },
  draws: {
    backgroundColor: 'gray',
    color: 'white',
  },
  black: {
    backgroundColor: '#403d39',
    color: 'white',
  },
  text: {
    letterSpacing: '-0.015rem',
  },
}));

const Bar = ({ white, black, draws }) => {
  const classes = useStyles();
  const total = white + black + draws;
  const showThresh = 0.20;
  return (
    <div className={classes.container}>
      <div className={`${classes.bar} ${classes.white}`} style={{ width: `${white/total*100}%` }}>
        { white/total > showThresh && (
          <span className={classes.text}>
            {Math.round(white/total*100)}%
          </span>
        )}
      </div>
      <div className={`${classes.bar} ${classes.draws}`} style={{ width: `${draws/total*100}%` }}>
        { draws/total > showThresh && (
          <span className={classes.text}>
            {Math.round(draws/total*100)}%
          </span>
        )}
      </div>
      <div className={`${classes.bar} ${classes.black}`} style={{ width: `${black/total*100}%` }}>
        { black/total > showThresh && (
          <span className={classes.text}>
            {Math.round(black/total*100)}%
          </span>
        )}
      </div>
    </div>
  )
}

const styles = theme => ({
  loadingContainer: {
  },
  container: {
    position: 'relative',
  },
  header: {
    // backgroundColor: '#eee',
    // backgroundColor: theme.palette.orange.light,
  },
  settingsButton: {
    position: 'absolute',
    right: 0,
    top: '0.25rem',
  },
  inputContainer: {
    marginBottom: theme.spacing(3),
  },
  bodyCell: {
    padding: theme.spacing(0.5),
  },
  moveRow: {
    padding: theme.spacing(1),
    // backgroundColor: '#eee',
    '&:hover': {
      backgroundColor: theme.palette.gray.main,
      cursor: 'pointer',
    },
  },
});

class Book extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      moves: undefined,
      type: UserStorage.get('book/type'),
      speeds: UserStorage.get('book/speeds'),
      ratings: UserStorage.get('book/ratings'),
      settingsOpen: false,
      settingsType: UserStorage.get('book/type'),
      settingsSpeeds: UserStorage.get('book/speeds'),
      settingsRatings: UserStorage.get('book/ratings'),
    };
  }

  componentDidMount() {
    this.fetchMoves();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.fen !== prevProps.fen) {
      this.fetchMoves();
    }
  }

  // uses Lichess API: https://lichess.org/api#tag/Opening-Explorer
  fetchMoves() {
    const { fen } = this.props;
    const { type, speeds, ratings } = this.state;

    const makeArrayParams = (k, vals) => vals.map(v => `${k}[]=${v}`).join('&');

    const url = encodeURI(
      `https://explorer.lichess.ovh/${type}` +
      `?fen=${fen}` +
      `&variant=standard` +
      `&${makeArrayParams('speeds', speeds)}` +
      `&${makeArrayParams('ratings', ratings)}`
    );

    fetch(url)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 400) {
        // 400 is returned if there are no moves
        return { moves: [] };
      } else {
        throw new Error(`Server Error: ${response.status} ${response.statusText}`);
      }
    })
    .then(data => {
      this.setState({
        moves: data.moves,
      });
    })
    .catch(err => {
      console.error(err);
    })
  }

  openSettings = () => {
    this.setState({
      settingsOpen: true,
      settingsType: UserStorage.get('book/type'),
      settingsSpeeds: UserStorage.get('book/speeds'),
      settingsRatings: UserStorage.get('book/ratings'),
    });
  }

  setSettings = () => {
    // TODO: do validation so that at least one speed and at least one rating is selected

    const { settingsType, settingsSpeeds, settingsRatings } = this.state;

    this.setState({
      settingsOpen: false,
      type: settingsType,
      speeds: settingsSpeeds,
      ratings: settingsRatings,
    }, () => {
      UserStorage.set('book/type', settingsType);
      UserStorage.set('book/speeds', settingsSpeeds);
      UserStorage.set('book/ratings', settingsRatings);
      this.fetchMoves();
    });
  }

  render() {
    const { theme, classes } = this.props;

    const { handleMoveClick } = this.props;

    const { moves } = this.state;
    const {
      settingsOpen, settingsType, settingsSpeeds, settingsRatings,
    } = this.state;

    if (moves === undefined) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress color="primary" />
        </div>
      );
    }

    const selectTheme = getSelectTheme(theme);

    return (
      <div className={classes.container}>
        <IconButton
          className={classes.settingsButton}
          onClick={this.openSettings}
          size="small"
        ><SettingsIcon size="small" /></IconButton>
        <TableContainer component={Paper}>
          <Table padding="none" size="small" style={{ textAlign: 'center', }}>
            <TableHead className={classes.header}>
              <TableRow>
                <TableCell align="center" padding="normal" width="25%">Move</TableCell>
                <TableCell align="center" padding="normal" width="15%">Games</TableCell>
                <TableCell align="left" padding="normal" width="60%">Results</TableCell>
              </TableRow>
            </TableHead>
            { moves.length === 0
              ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3} style={{ textAlign: 'center' }}>No games found</TableCell>
                  </TableRow>
                </TableBody>
              )
              : (
                <TableBody>
                  {moves.map(({ uci, san, white, draws, black }) => (
                    <TableRow
                      key={san}
                      className={classes.moveRow}
                      onClick={() => { handleMoveClick(uci)}}>
                      <TableCell align="center" width="25%" className={classes.bodyCell}>
                        {san}
                      </TableCell>
                      <TableCell align="center" width="15%" className={classes.bodyCell}>
                        {nFormatter(white+black+draws, 0)}</TableCell>
                      <TableCell align="center" width="60%" className={classes.bodyCell}>
                        <div style={{ marginRight: '0.5rem' }}>
                          <Bar white={white} black={black} draws={draws} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )
            }
          </Table>
        </TableContainer>
        <Dialog
          open={settingsOpen}
          onClose={() => { this.setState({ settingsOpen: false})}}
          maxWidth="lg"
        >
          <DialogTitle>Book settings</DialogTitle>
          <DialogContent>
            <div className={classes.inputContainer}>
              <Typography>Book type</Typography>
              <Select
                className={classes.select}
                placeholder="Book type"
                options={settings.values.book.choices.type.map(v => ({label: v, value: v}))}
                value={{ label: settingsType, value: settingsType }}
                onChange={({ value }) => { this.setState({ settingsType: value })}}
                theme={selectTheme}
              />
            </div>
            <div className={classes.inputContainer}>
              <Typography>Game speeds</Typography>
              <Select
                className={classes.select}
                isMulti
                isClearable={false}
                placeholder="Game speeds"
                options={settings.values.book.choices.speeds.map(v => ({label: v, value: v}))}
                value={settingsSpeeds.map(v => ({ label: v, value: v }))}
                onChange={(arr) => { this.setState({ settingsSpeeds: arr.map(e => e.value)}) }}
                theme={selectTheme}
              />
            </div>
            <div className={classes.inputContainer}>
              <Typography>Player ratings</Typography>
              <Select
                className={classes.select}
                isMulti
                isClearable={false}
                placeholder="Game ratings"
                options={settings.values.book.choices.ratings.map(v => ({label: v, value: v}))}
                value={settingsRatings.map(v => ({ label: v, value: v }))}
                onChange={(arr) => { this.setState({ settingsRatings: arr.map(e => e.value)}) }}
                theme={selectTheme}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => { this.setState({ settingsOpen: false })}}
            >Cancel</Button>
            <Button
              onClick={this.setSettings}
              variant="contained"
              color="primary"
            >Set</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Book);
