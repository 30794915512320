import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AccountTab from '../../Tabs/Settings/Account';
// import AppearanceTab from '../Tabs/Settings/Appearance';

const styles = (theme) => ({
  container: {
    maxWidth: 600,
    margin: '0 auto',
    marginTop: theme.spacing(4),
  },
  tabs: {
    marginBottom: theme.spacing(1)
  },
  closeButton: {
    marginRight: theme.spacing(2),
  },
});

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0
    };
  }

  changeTab = (event, value) => {
    this.setState({
      selectedTab: value
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const {
      user,
      isPerformingAuthAction,
      onUpgradeClick,
      onExtendClick,
    } = this.props;

    // Events
    const {
      onCancelSubscriptionClick,
      getSubscription,
    } = this.props;

    const { selectedTab } = this.state;

    return (
      <div className={classes.container}>
        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.changeTab}
          value={selectedTab}
          variant="fullWidth"
          // NOTE: this TabIndicatorProps is for the case when we only have one tab
          TabIndicatorProps={{ style: { width: '100%' }}}
        >
          <Tab label="Account" />
        </Tabs>

        {selectedTab === 0 &&
        <AccountTab
            user={user}
            isPerformingAuthAction={isPerformingAuthAction}
            onCancelSubscriptionClick={onCancelSubscriptionClick}
            getSubscription={getSubscription}
            onUpgradeClick={onUpgradeClick}
            onExtendClick={onExtendClick}
        />
        }
        {/*
        {selectedTab === 1 &&
        <AppearanceTab
            user={user}
            isPerformingAuthAction={isPerformingAuthAction}
            layoutDirection={layoutDirection}
            onLayoutDirectionChange={onLayoutDirectionChange}
        />
        }
        */}
    </div>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,

  user: PropTypes.object.isRequired,
  isPerformingAuthAction: PropTypes.bool.isRequired,

  onUpgradeClick: PropTypes.func.isRequired,
  onExtendClick: PropTypes.func.isRequired,
  onCancelSubscriptionClick: PropTypes.func.isRequired,
  getSubscription: PropTypes.func.isRequired,
};

export default withStyles(styles)(Settings);
