import React from 'react';

import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteIcon from '@material-ui/icons/Delete';
import ClipboardIcon from '@material-ui/icons/FileCopy';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Checkbox from '@material-ui/core/Checkbox';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import TextField from '@material-ui/core/TextField';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import Board from './Board';

import { FenLibrary } from '../../db';

import { validateFen } from '../../utils';

import settings from '../../settings';

const cardWidth = 280;
const boardWidth = 248;

const styles = theme => ({
  container: {
    maxWidth: 1200,
    margin: '0 auto',
    marginTop: theme.spacing(4),
  },
  gridItem: {
  },
  cardItem: {
    height: '100%',
  },
  cardContent: {
    width: cardWidth,
  },
  cardAddClosed: {
    height: '100%',
    width: cardWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: cardWidth,
  },
  cardAddOpen: {
    height: '100%',
    width: cardWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: cardWidth,
  },
  header: {
    textAlign: 'center',
    fontSize: '1.5rem',
    color: '#999',
    paddingBottom: theme.spacing(3),
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchText: {
    // marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'flex',
  },
  sorting: {
    display: 'flex',
    alignItems: 'center',
  },
  sortButton: {
    textTransform: 'none',
  },
  otherContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 48px)',
  },
  exportActions: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  infoContainer: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  topActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    fontStyle: 'italic',
  },
  turn: {
    fontSize: '0.8rem',
  },
  opened: {
    fontSize: '0.8rem',
  },
  diagramsExportTextarea: {
    marginTop: '1rem',
  },
  exportCheckbox: {
    color: theme.palette.text.primary,
    marginTop: '-1rem',
    marginRight: '-0.75rem',
  }
});

const diagramToPgn = ({ fen, name }) => {
  let out = '[Result "*"]\n';
  out += `[FEN "${fen.replaceAll('_', ' ')}"]`;
  if (name) {
    out += `\n{ ${name} }`;
  }
  out += '\n\n*\n';
  return out;
};

const normalizeName = s => s.toLowerCase().trim();

// sorting implementation follows
// https://material-ui.com/components/tables/
function descendingComparator(a, b, fn) {
  if (fn(b) < fn(a)) {
    return -1;
  }
  if (fn(b) > fn(a)) {
    return 1;
  }
  return 0;
}

function getComparator(order, fn) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, fn)
    : (a, b) => -descendingComparator(a, b, fn);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

class DiagramLibrary extends React.Component {

  constructor(props) {
    super(props);
    this.library = new FenLibrary(this.props.user.uid);

    this.state = {
      fenToDelete: false,
      data: [],
      loading: true,
      lastError: null,

      // export
      selectedDiagrams: null,
      diagramsToExport: null,
      diagramsCopiedToClipboard: false,

      // add
      addOpen: false,
      addFen: '',
      addName: '',
      addLoading: false,

      // sorting
      order: 'desc',
      orderBy: 'opened',

      // filtering
      searchQuery: '',
    }
  }

  async componentDidMount() {
    try {
      const data = await this.library.getAll();
      this.setState({
        data,
        loading: false,
      });
    } catch(e) {
      if (e.code === 'firestore/permission-denied') {
        this.setState({
          lastError: {
            code: 'access-denied',
            message: 'Permission denied, check your login status.',
          },
          loading: false,
        });
      } else {
        this.setState({
          lastError: {
            code: e.code,
            message: e.message,
          },
          loading: false,
        });
      }
    }
  }

  async deleteFen() {
    const { fenToDelete, data } = this.state;
    await this.library.remove(fenToDelete);
    this.setState({
      fenToDelete: false,
      data: data.filter(item => item.fen !== fenToDelete),
    });
  }

  async addFen(fen, name) {
    this.setState({
      addLoading: true,
    }, async () => {
      const exists = await this.library.exists(fen);
      if (exists) {
        this.setState({
          lastError: {
            message: 'You already saved a diagram with this FEN',
          },
          addLoading: false,
        });
        return;
      }
      const added = await this.library.add(fen, { name });
      if (added) {
        const now = new Date().getTime() / 1000;
        const addedItem = {
          fen: fen.split(' ').join('_'),
          name,
          opened: {
            seconds: now,
          },
          created: {
            seconds: now,
          }
        };
        this.setState({
          addOpen: false,
          addFen: '',
          addName: '',
          addLoading: false,
          data: [addedItem, ...this.state.data],
        });
      } else {
        this.setState({
          addLoading: false,
          lastError: {
            message: 'Something went wrong :(',
          },
        });
      }
    });
  }

  exportDiagrams = (diagrams) => {
    this.setState({
      diagramsToExport: diagrams.map(d => ({ fen: d.fen, name: d.name })),
    });
  }

  copyToClipboard = () => {
    const e = document.getElementById('diagrams-export-textarea');
    e.select();
    try {
      document.execCommand('copy');
      e.focus();
      this.setState({
        diagramsCopiedToClipboard: true,
      });
    } catch (err) {
      alert('Unable to copy to clipboard, please select and copy manually.')
    }
  }

  renderItem = ({ fen, name, opened }) => {
    const { classes } = this.props;
    const turn = fen.split('_')[1] === 'w' ? 'White' : 'Black';
    const boardCellWidth = boardWidth; // TODO: maybe need to be set dynamicly based on screen width?

    const { selectedDiagrams } = this.state;

    const onSelectionChange = () => {
      if (selectedDiagrams.map(d => d.fen).includes(fen)) {
        this.setState({
          selectedDiagrams: selectedDiagrams.filter(d => d.fen !== fen),
        });
      } else {
        this.setState({
          selectedDiagrams: [...selectedDiagrams, {fen, name}],
        });
      }
    }

    return (
      <Card variant="outlined" className={classes.cardItem}>
        <CardContent className={classes.cardContent}>
          <div className={classes.topActions}>
            { selectedDiagrams !== null && (
              <Checkbox
                checked={selectedDiagrams.map(d => d.fen).includes(fen)}
                onChange={onSelectionChange}
                className={classes.exportCheckbox}
              />
            )}
          </div>
          <div style={{ width: boardCellWidth, height: boardCellWidth }}>
            <Board size={boardCellWidth} fen={fen} />
          </div>
          <div className={classes.infoContainer}>
            { name && <Typography className={classes.title}>{name}</Typography>}
            <Typography className={classes.turn}>{turn} to play</Typography>
            <Typography className={classes.opened}>Opened: <TimeAgo date={opened.seconds * 1000} /></Typography>
          </div>
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'center', position: 'relative', flexDirection: 'column' }} disableSpacing>
          <div>
            <Button
              component={Link}
              to={`${settings.routes.BOARD_EXPLORER}/${fen}`}
              size="small"
              color="primary"
              style={{ textTransform: 'none', minWidth: 'auto' }}
            >Open in Board Explorer</Button>
            <IconButton
              onClick={() => this.setState({ fenToDelete: fen, })}
              style={{ position: 'absolute', right: '0.5rem', bottom: '0.5rem', textTransform: 'none', minWidth: 'auto' }}
            ><DeleteIcon fontSize="small"/></IconButton>
          </div>
          <div>
            <Button
              component="a"
              target="_blank"
              href={`https://lichess.org/analysis/${fen}`}
              size="small"
              color="primary"
              style={{ textTransform: 'none', minWidth: 'auto' }}
            >Open in Lichess</Button>
          </div>
        </CardActions>
      </Card>
    );
  }

  toggleSort = (newOrderBy) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === newOrderBy && order === 'asc';
    this.setState({
      order: isAsc ? 'desc' : 'asc',
      orderBy: newOrderBy,
    });
  }

  handleSearchQueryChange = e => {
    this.setState({
      searchQuery: e.target.value,
    });
  }

  render() {
    const { classes } = this.props;
    const { data, loading } = this.state;
    const { fenToDelete } = this.state;
    const { lastError } = this.state;
    const { selectedDiagrams, diagramsToExport, diagramsCopiedToClipboard } = this.state;
    const { addOpen, addFen, addName, addLoading } = this.state;
    const { order, orderBy } = this.state;
    const { searchQuery } = this.state;

    const fenFromInput = validateFen(addFen);

    if (loading) {
      return (
        <div className={classes.otherContainer}>
          <CircularProgress color="primary" />
        </div>
      );
    }

    if (lastError?.code === 'access-denied') {
      return (
        <div className={classes.otherContainer}>
          <Typography variant="h5">Access denied</Typography>
          <Typography>Please check your login status.</Typography>
        </div>
      );
    }

    const normalizedSearchQuery = normalizeName(searchQuery);
    const filteredData = data.filter(e => searchQuery === '' || e.name && normalizeName(e.name).includes(normalizedSearchQuery));
    const orderValueGetter = orderBy === 'name' ? (e => e.name ? normalizeName(e.name) : '') : e => e.opened.seconds;
    const sortedData = stableSort(filteredData, getComparator(order, orderValueGetter));

    return (
      <div className={classes.container}>
        <Typography className={classes.header}>Here are all the diagrams you saved here or in our mobile app</Typography>
        <div className={classes.subheader}>
          <div className={classes.sorting}>
            Order by:
            <Button
              onClick={() => this.toggleSort('opened')}
              className={classes.sortButton}
            >
              Recent
              { orderBy === 'opened' && order === 'desc' && <ArrowDownwardIcon /> }
              { orderBy === 'opened' && order === 'asc' && <ArrowUpwardIcon /> }
            </Button>
            <Button
              onClick={() => this.toggleSort('name')}
              className={classes.sortButton}
            >
              Name
              { orderBy === 'name' && order === 'desc' && <ArrowDownwardIcon /> }
              { orderBy === 'name' && order === 'asc' && <ArrowUpwardIcon /> }
            </Button>
          </div>
          <div className={classes.search}>
            <TextField
              label="Search by name"
              placeholder="Name"
              variant="outlined"
              onChange={this.handleSearchQueryChange}
              className={classes.searchText}
              value={searchQuery}
            />
          </div>
          <div className={classes.exportActions}>
            <Button
              onClick={() => { this.setState({ selectedDiagrams: selectedDiagrams === null ? [] : null })}}
            >{selectedDiagrams === null ? 'Select diagrams' : 'Cancel selection'}
            </Button>
            {selectedDiagrams && selectedDiagrams.length > 0 && (
              <Button
                onClick={() => { this.exportDiagrams(selectedDiagrams) }}
              >Export selected</Button>
            )}
            <Button
              onClick={() => { this.exportDiagrams(data)}}
            >Export All</Button>
          </div>
        </div>
        <Grid container spacing={3} style={{ marginTop: '0.5rem' }}>
          <Grid item>
            { addOpen
            ? (
            <Card variant="outlined" className={classes.cardItem}>
              <CardContent className={classes.cardAddOpen}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="FEN"
                  value={addFen}
                  onChange={e => { this.setState({ addFen: e.target.value })}}
                />
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Name"
                  value={addName}
                  helperText="Leave it empty if you want"
                  onChange={e => { this.setState({ addName: e.target.value })}}
                  style={{ marginTop: '1rem' }}
                />
                <Button
                  onClick={() => this.addFen(fenFromInput, addName)}
                  variant="contained"
                  disabled={fenFromInput === null || addLoading}
                  style={{ marginTop: '2rem' }}
                ><>{addLoading && <CircularProgress size={18} style={{ marginRight: '0.5rem' }}/>}<span>Add</span></></Button>
              </CardContent>
            </Card>
            )
            : (
            <Card variant="outlined" className={classes.cardItem}>
              <CardContent className={classes.cardAddClosed}>
                <IconButton
                  onClick={() => { this.setState({ addOpen: true })}}
                ><ControlPointIcon color="primary" style={{ fontSize: '64px' }} /></IconButton>
              </CardContent>
            </Card>
            )}
          </Grid>
          { sortedData.map(item => (
            <Grid item key={item.fen}>
              {this.renderItem(item)}
            </Grid>
          ))}
        </Grid>
        <Dialog open={!!fenToDelete} onClose={() => { this.setState({ fenToDelete: false})}}>
          <DialogTitle>Delete diagram</DialogTitle>
          <DialogContent>
            Are you sure to delete this position from your Diagram Library?
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => { this.setState({ fenToDelete: false })}}
            >Cancel</Button>
            <Button
              variant="outlined"
              onClick={async () => { await this.deleteFen() }}
            >Delete</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={diagramsToExport}
          onClose={() => { this.setState({ diagramsToExport: null})}}
        >
          <DialogTitle>Export diagrams</DialogTitle>
          <DialogContent>
            <span>
              You can copy the PGN with the diagrams and export it to any other tool that supports PGN import, e.g. <a href="https://lichess.org/study" target="_blank" rel="noreferrer noopener">Lichess Studies</a> - paste it there as PGN while creating a study. This will create a new study with a single chapter for each of the positions you selected, including your comments.
            </span>
            <br />
            <span>Click here to copy PGN to clipboard:</span>
            <IconButton
              color="primary"
              aria-label="copy to clipboard picture"
              component="span"
              onClick={this.copyToClipboard}
            >
              <ClipboardIcon />
            </IconButton>
            <textarea
              id="diagrams-export-textarea"
              readOnly
              cols={65}
              rows={20}
              className={classes.diagramsExportTextarea}
              value={diagramsToExport?.map(diagramToPgn).join('\n')} />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => { this.setState({ diagramsToExport: null })}}
            >Cancel</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={diagramsCopiedToClipboard}
          autoHideDuration={5000}
          onClose={() => this.setState({ diagramsCopiedToClipboard: false })}
          style={{ width: '75%', alignSelf: 'center' }}
          message="Diagrams copied!"
          severity="success"
        />

        <Snackbar
          open={lastError !== null}
          autoHideDuration={5000}
          onClose={() => this.setState({ lastError: null })}
          style={{ width: '75%', alignSelf: 'center' }}
          severity="error"
          message={lastError && lastError.message}
        />
      </div>
    );
  }
};

export default withStyles(styles)(DiagramLibrary);
