// React
import React, { Component } from 'react';

// Material-UI
import { MuiThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import CssBaseline from '@material-ui/core/CssBaseline';

import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';

import readingTime from 'reading-time';

// Firebase
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

// Custom
import settings from './settings';
import getRefId from './ref_ids';

import Launch from './Pages/Launch';
import Auth from './Pages/Auth';
import App from './Pages/App';

import { messageExtension } from './utils';

import { Users } from './db';

import { identifyUser, visitPage, eventTypes, emitEvent } from './events';

import { getTheme } from './theme';

const app = initializeApp(settings.credentials.firebase);
// eslint-disable-next-line
const analytics = getAnalytics(app);

const auth = getAuth();

// TODO: how to do this with firebase v9 and is it really necessary? what does it do?
// auth.useDeviceLanguage();

class Site extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isAuthReady: false,
      isSignedIn: false,
      disagreed: false,
      snackbar: {
        autoHideDuration: 0,
        message: '',
        open: false
      },
      themeMode: localStorage.getItem('chessvision/theme-mode') || settings.defaultTheme,
    };

    // to redirect after sign in
    this.nextLocation = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      visitPage();
    }
  }

  redirectToNextLocation = () => {
    const { history } = this.props;
    if (this.nextLocation !== null) {
      history.push(this.nextLocation);
      this.nextLocation = null;
    }
  }

  setDisagreed = (value, tryRedirect) => {
    this.setState({ disagreed: value }, () => {
      if (!value && tryRedirect) {
        this.redirectToNextLocation();
      }
    })
  }

  async createUser(user, tryRedirect) {
    const { cookies } = this.props;
    let refId = cookies.get('chessvision-ref_id');
    if (refId === undefined) {
      refId = null;
    }
    try {
      await Users.create(user.uid, refId);
      this.setDisagreed(false, tryRedirect);
    } catch (e) {
      alert(e);
    }
  }

  componentDidMount() {
    const { cookies } = this.props;
    const ref_id = getRefId(this.props.location.search);
    if (ref_id !== null) {
      cookies.set('chessvision-ref_id', ref_id, { path: '/' });
    }

    this._isMounted = true;

    const { themeMode } = this.state;
    document.documentElement.setAttribute('data-color-scheme', themeMode);

    this.removeAuthObserver = onAuthStateChanged(auth, async (user) => {
      let shouldRedirectToNext = false;
      if (user) {
        identifyUser(user);
        if (!await Users.exists(user.uid)) {
          if (user.providerData.map(e => e.providerId).includes('password')) {
            // create user because he just created an account with email/password
            // so he accepted the terms in the form
            await this.createUser(user, false);
            shouldRedirectToNext = true;
          } else {
            this.setDisagreed(true);
          }
        } else {
          shouldRedirectToNext = true;
        }
      } else {
        this.nextLocation = this.props.location;
      }
      if (this._isMounted) {
        this.setState({
          isAuthReady: true,
          isSignedIn: !!user,
          user,
        }, () => {
          if (!!user && shouldRedirectToNext) {
            this.redirectToNextLocation();
          }
        });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.removeAuthObserver();
  }

  setThemeMode = (newThemeMode) => {
    this.setState({
      themeMode: newThemeMode,
    }, () => {
      document.documentElement.setAttribute('data-color-scheme', newThemeMode);
      localStorage.setItem('chessvision/theme-mode', newThemeMode);
    });
  }

  logout = () => {
    this.setState({
      isPerformingAuthAction: true
    }, () => {
      signOut(auth).then(() => {
        emitEvent(eventTypes.signOut);
        this.setDisagreed(false);
        messageExtension({
          action: 'sign_out',
        });
        this.props.history.push('/');
        this.openSnackbar('Signed out');
      }).catch((reason) => {
        const code = reason.code;
        const message = reason.message;
        this.openSnackbar(`${code}: ${message}`);
      }).finally(() => {
        this.setState({
          isPerformingAuthAction: false
        });
      });
    });
  };

  openSnackbar = (message) => {
    this.setState({
      snackbar: {
        autoHideDuration: readingTime(message).time * 2,
        message,
        open: true
      }
    });
  };

  closeSnackbar = (clearMessage = false) => {
    const { snackbar } = this.state;
    this.setState({
      snackbar: {
        message: clearMessage ? '' : snackbar.message,
        open: false
      }
    });
  };

  render() {
    const {
      isAuthReady,
      isSignedIn,
      user,
      disagreed,
      snackbar,
    } = this.state;

    const { themeMode } = this.state;

    const computedTheme = themeMode === 'dark' && (!isAuthReady || (isSignedIn && !disagreed))
    ? 'dark' : 'light';

    return (
      <MuiThemeProvider theme={getTheme(computedTheme)}>
        <CssBaseline />
        { !isAuthReady && (
          <Launch />
        )}
        { isAuthReady && (!isSignedIn || disagreed) && (
          <Auth
            auth={auth}
            user={user}
            disagreed={disagreed}
            createUser={async () => { await this.createUser(user, true) }}
            signOut={this.logout} />
        )}

        { isAuthReady && isSignedIn && !disagreed &&  (
          <App
            user={user}
            auth={auth}
            signOut={this.logout}
            themeMode={themeMode}
            onSetThemeMode={this.setThemeMode}
          />
        )}
        <Snackbar
          autoHideDuration={snackbar.autoHideDuration}
          message={snackbar.message}
          open={snackbar.open}
          onClose={this.closeSnackbar}
        />
      </MuiThemeProvider>
    );
  }
}

export default withRouter(withCookies(Site));
