import React from 'react';

import { Link, withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SunIcon from '@material-ui/icons/Brightness7';
import MoonIcon from '@material-ui/icons/Brightness2';

import { emitEvent, eventTypes } from '../../events';
import settings from '../../settings';

const styles = theme => ({
  logo: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  mainLinks: {
    flex: 1,
    display: 'flex',
    marginLeft: theme.spacing(5),
    justifyContent: 'flex-start',
    '& a': {
      textDecoration: 'none',
    },
    '& button': {
      fontWeight: theme.palette.mode === 'dark' ? 400 : 300,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '4px',
      },
    },
  },
  activeLink: {
    fontWeight: '500 !important',
  },
  accountType: {
    marginRight: theme.spacing(1),
  },
  appsButton: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  appLink: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  themeIcon: {
    marginRight: theme.spacing(1),
  }
});

class Bar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: null,
      appsMenu: null,
      isPremium: undefined,
    };
  }

  async componentDidMount() {
    const { getSubscription } = this.props;

    const sub = await getSubscription();
    if (sub !== null) {
      this.setState({
        isPremium: sub.ended_at === null,
      });
    } else {
      this.setState({
        isPremium: false,
      });
    }
  }

  openMenu = (event) => {
    this.setState({ menu: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ menu: null });
  };

  openAppsMenu = (event) => {
    this.setState({ appsMenu: event.currentTarget });
  };

  closeAppsMenu = () => {
    this.setState({ appsMenu: null });
  };

  handleSettingsClick = () => {
    this.closeMenu();
    this.props.onSettingsClick();
  };

  render() {
    const { classes } = this.props;
    const { isPerformingAuthAction, onUpgradeClick, onSignOutClick } = this.props;
    const { onUpgradeLoading } = this.props;
    const { themeMode, onSetThemeMode } = this.props;
    const { menu, appsMenu } = this.state;
    const { isPremium } = this.state;

    const mainLinks = [
      { name: "Board Explorer", route: settings.routes.BOARD_EXPLORER },
      { name: "Video Search", route: settings.routes.VIDEO_SEARCH },
      { name: "Watch Video", route: settings.routes.VIDEO_WATCH },
      { name: "Diagram Library", route: settings.routes.DIAGRAM_LIBRARY },
    ];

    const { pathname } = this.props.location;

    return (
      <AppBar position="static" style={{ backgroundColor: themeMode === 'dark' ? '#000' : '#f5f5f5'}}>
        <Toolbar variant="dense" style={{ display: 'flex' }}>
          <Link to={settings.routes.HOME} className={classes.logo}>
            <Typography variant="h6" component="div">
              Chessvision.ai
            </Typography>
          </Link>

          <div className={classes.mainLinks}>
            { mainLinks.map(({ name, route }) => (
              <Link to={route} key={name}>
                <Button variant="text" className={route === pathname ? classes.activeLink : null}>{name}</Button>
              </Link>
            ))}
            <Button
              className={classes.appsButton}
              variant="text"
              onClick={this.openAppsMenu}
              endIcon={<ArrowDropDownIcon />}
            >Apps</Button>

            <Menu
              anchorEl={appsMenu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              open={Boolean(appsMenu)}
              onClose={this.closeAppsMenu}
            >
              { settings.products.map(({ title, url }) => (
                <MenuItem
                  key={title}
                  className={classes.appLink}
                  component="a"
                  href={url}
                  target="_blank"
                  rel="noreferrer noopener"
                >{title}</MenuItem>
              ))}
            </Menu>
          </div>

          { settings.earlyAccess
            ? (
              <div className={classes.accountType}>
                <Typography variant="button" color="secondary">EARLY ACCESS</Typography>
              </div>
            )
            : (
              <div className={classes.accountType}>
                { isPremium === undefined &&
                  <CircularProgress className={classes.circularProgress} size="1rem" />
                }
                { isPremium === true &&
                  <Typography variant="button" color="secondary">MEMBER</Typography>
                }
                { isPremium === false &&
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={isPerformingAuthAction || onUpgradeLoading}
                    onClick={() => {
                      emitEvent(eventTypes.subscriptionPlansDialogOpenAppBar);
                      onUpgradeClick();
                    }}
                  >
                    Upgrade
                  </Button>
                }
              </div>
            )
          }

          <>
            <IconButton disabled={isPerformingAuthAction} onClick={this.openMenu}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>

            <Menu
              anchorEl={menu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              open={Boolean(menu)}
              onClose={this.closeMenu}
            >
              {/*
              <MenuItem component={Link} to={settings.routes.BOARD_EXPLORER} disabled={isPerformingAuthAction} onClick={this.closeMenu}>Board Explorer</MenuItem>
              <MenuItem component={Link} to={settings.routes.VIDEO_SEARCH} disabled={isPerformingAuthAction} onClick={this.closeMenu}>Video Search</MenuItem>
              <MenuItem component={Link} to={settings.routes.VIDEO_WATCH} disabled={isPerformingAuthAction} onClick={this.closeMenu}>Video Watch</MenuItem>
              <MenuItem component={Link} to={settings.routes.DIAGRAM_LIBRARY} disabled={isPerformingAuthAction} onClick={this.closeMenu}>Diagram Library</MenuItem>
              <Divider />
              */}
              <MenuItem onClick={() => onSetThemeMode(themeMode === 'light' ? 'dark' : 'light')} disableRipple>
                { themeMode === 'light'
                  ? <MoonIcon fontSize="small" className={classes.themeIcon} />
                  : <SunIcon fontSize="small" className={classes.themeIcon} />
                }
                { themeMode === 'light' ? 'Dark Mode' : 'Light Mode'}
              </MenuItem>
              { /* new method with /settings page */}
              <MenuItem component={Link} to={settings.routes.SETTINGS} disabled={isPerformingAuthAction} onClick={this.closeMenu}>Settings</MenuItem>
              { /* old method of having settings dialog 
              <MenuItem disabled={isPerformingAuthAction} onClick={this.handleSettingsClick}>Settings</MenuItem>
              /* }
              { /*
              <MenuItem disabled={isPerformingAuthAction} onClick={this.handleTutorialsClick}>Tutorials</MenuItem>
              <MenuItem component={Link} to={settings.routes.FAQ} disabled={isPerformingAuthAction} onClick={this.closeMenu}>FAQ</MenuItem>
              */ }
              <MenuItem
                component="a"
                href={`mailto:${settings.values.supportEmail}?subject=Chessvision.ai general support request`}
                rel="noopener noreferrer"
                target="_blank"
                disabled={isPerformingAuthAction}
                onClick={this.closeMenu}
              >
                Email Support
              </MenuItem>
              { /*
              <MenuItem
                component="a"
                disabled={isPerformingAuthAction}
                onClick={() => { window.$crisp.push(['do', 'chat:open']); this.closeMenu()}}
              >
                Chat Support
              </MenuItem>
              */ }
              <MenuItem disabled={isPerformingAuthAction} onClick={onSignOutClick}>Logout</MenuItem>
            </Menu>
          </>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withRouter(Bar));
