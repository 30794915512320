import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Paper from '@material-ui/core/Paper';

import Launch from '../../../Pages/Launch';
import SubscriptionPlans from './SubscriptionPlans';

import settings from '../../../settings';

const styles = theme => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(8),
  },
  plans: {
    marginTop: theme.spacing(4),
  },
});

class ChromeExtensionUpgrade extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discount: undefined,
    };
  }

  componentDidMount() {
    this.fetchDiscount();
  }

  fetchDiscount = () => {
    const { user } = this.props;
    return user.getIdToken().then(authToken => {
      const url = `${settings.apiUrl}/ebook_reader_subscriptions`;
      return fetch(url, {
        headers: new Headers({
         'Authorization': `Bearer ${authToken}`,
        }),
      })
      .then(response => response.json())
      .then(data => {
        this.setState({
          discount: data["active"],
        });
      });
    });
  }

  render() {
    const { classes } = this.props;
    const { user } = this.props;

    const { discount } = this.state;

    if (discount === undefined) {
      return (
        <Launch />
      );
    }
    return (
      <Paper className={classes.container}>
        <Typography variant="h2">Upgrade your account</Typography>
        <Typography>and unlock unlimited access to all the features</Typography>
        <br />
        <div className={classes.plans}>
          <SubscriptionPlans user={user} discount={discount} />
        </div>
      </Paper>
    );
  }

}

export default withStyles(styles)(ChromeExtensionUpgrade);
