import React from 'react';

import Chessground from 'react-chessground';
import 'react-chessground/dist/styles/chessground.css';

class Board extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.arrows !== prevProps.arrows && this.props.arrows.length === 0) {
      this.ref.current.cg.setAutoShapes([]);
    }
  }

  render () {
    const { fen, lastMove, onMove, flipped, arrows, movable } = this.props;
    // following this on how to draw arrow moves
    // https://github.com/openingtree/openingtree/blob/57610873e273402711a9fe7545c8cf6e4ae91b05/src/pres/MainContainer.js#L130
    const size = "calc(min(calc(1920px * 0.38), 38vw) - 66px)";
    const turn = fen.split(' ')[1] === 'w' ? 'white' : 'black';
    return (
      <Chessground
        ref={this.ref}
        fen={fen}
        turnColor={turn}
        width={size}
        height={size}
        lastMove={lastMove}
        onMove={onMove}
        orientation={flipped ? "black" : "white"}
        selectable={{ enabled: true }}
        drawable={{
          enabled: true,
          visible: true,
          autoShapes: arrows.map(({ from, to }) => ({ orig: from, dest: to, brush: 'green' }))
        }}
        movable={movable}
      />
    );
  }
}

export default Board;
