import React from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    fontSize: '0.5rem',
    textAlign: 'right',
  },
});

class Sorters extends React.Component {

  render() {
    const { classes } = this.props;
    const { onChange, sortBy } = this.props;

    return (
      <div className={classes.container}>
        <FormControl component="fieldset">
          <RadioGroup row aria-label="sort" name="sort" value={sortBy} onChange={onChange}>
            <FormControlLabel value="popular" control={<Radio size="small" />} label="Most popular" />
            <FormControlLabel value="recent" control={<Radio size="small" />} label="Newest" />
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles)(Sorters);
