import React from 'react';

import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import { truncate, getRestrictedText, getRestrictedWord } from '../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  // solution to auto scalling to width player from: https://github.com/CookPete/react-player/issues/145
  playerWrapper: {
    maxWidth: '430px',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      paddingTop: '56.25%', /* Player ratio: 100 / (1280 / 720) */
      maxWidth: 'none',
    },
  },
  player: {
    filter: 'blur(20px)',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  playIcon: {
    fontSize: '64px',
  },
  infoContainer: {
    flex: 1,
    fontSize: '0.8rem',
    paddingLeft: '1.5rem',
    filter: 'blur(4px)',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0.5rem',
      paddingLeft: 0,
      marginRight: '1rem',
    },
  },
  titleWrapper: {
    fontSize: '1.2rem',
  },
  title: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  subtitle: {
    marginTop: '0.2rem',
    '& > span': {
    },
  },
  channelWrapper: {
    marginTop: '0.75rem',
  },
  channel: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  description: {
    marginTop: '0.75rem',
    // color: '#606060',
  },
  actions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const RestrictedResult = ({ thumb }) => {
  const classes = useStyles();

  const title = getRestrictedText(30, 50);
  const timestamp = getRestrictedWord(4, 5);
  const views = getRestrictedWord(3, 5);
  const uploadDate = getRestrictedText(5, 10);
  const fullTags = getRestrictedText(20, 30);
  const channelName = getRestrictedText(8, 20);
  const description = getRestrictedText(30, 120);

  return (
    <div className={classes.container}>
      <div className={classes.playerWrapper}>
        <Hidden xsDown>
          <div
            style={{ width: 430, height: 280 }}
            className={classes.player}
          >
            <div style={{ width: '100%', height: '100%', backgroundImage: `url(${thumb})`, backgroundSize: 'cover' }} />
          </div>
        </Hidden>
        <Hidden smUp>
          <div
            style={{ width: '100%', height: '100%', backgroundImage: `url(${thumb})`, backgroundSize: 'cover' }}
            className={classes.player}
          />
        </Hidden>
      </div>
      <div className={classes.infoContainer}>
        <Typography className={classes.titleWrapper}>
          <span className={classes.title}>{title}</span>
        </Typography>
        <div className={classes.subtitle}>
          <span className={classes.timestamp}>at {timestamp}</span> •&nbsp;
          <span className={classes.viewCount}>
            {views} views
          </span> •&nbsp;
          <span className={classes.date}>
            {uploadDate}
          </span>
        </div>
        <div className={classes.subtitle}>
          <span className={classes.tags}>
            {fullTags}
          </span>
        </div>
        <div className={classes.channelWrapper}>
          <span className={classes.channel}>{channelName}</span>
        </div>
        <Hidden only="xs">
          <div className={classes.description}>
            {truncate(description, 120)}
          </div>
        </Hidden>
        <div className={classes.actions}>
        </div>
      </div>
    </div>
  );
}

export default RestrictedResult;
