import React, { Component} from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as ChessableLogo } from '../../../Assets/chessable_logo.svg';

import { eventTypes, emitEvent } from '../../../events';
import settings from '../../../settings';

const styles = (theme) => ({
  root: {
    textAlign: 'center',
  },
  content: {
    paddingBottom: 0,
  },
  text: {
    fontSize: '1.1rem',
  },
  actions: {
    justifyContent: 'center',
  },
});

class ChessableCourses extends Component {

  state = {
    numCourses: undefined,
    coursesUrl: null,
    buttonShown: false,
  };

  componentDidMount() {
    const { fen } = this.props;
    this.fetchRelatedCourses(fen);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.fen !== this.props.fen) {
      this.fetchRelatedCourses(this.props.fen);
    }
  }

  fetchRelatedCourses(fen) {
    this.setState({
      numCourses: undefined,
    }, () => {
      const url = `${settings.apiUrl}/chessable_courses?fen=${fen.split(' ', 2).join('_')}`;
      const params = {
        mode: 'cors',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        },
      }
      fetch(url, params)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(data => {
        const { buttonShown } = this.state;
        const { num_courses: numCourses, courses_url: coursesUrl } = data;
        if (!buttonShown && numCourses > 0) {
          emitEvent(eventTypes.chessableCoursesShow, { fen });
        }
        this.setState({
          buttonShown: numCourses > 0,
          numCourses,
          coursesUrl,
        });
      })
      .catch(err => {
        this.setState({
          buttonShown: false,
          numCourses: 0,
          coursesUrl: null,
        });
      })
    });
  }

  render() {
    const { fen } = this.props;
    const { classes } = this.props;
    const { numCourses, coursesUrl } = this.state;

    if (numCourses === undefined) {
      return <CircularProgress size={16} color="primary" />
    }

    if (numCourses === 0) {
      return '';
    }

    return (
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <ChessableLogo style={{ fill: '#034fdf', width: 134 }} />
          <Typography
            variant="h5"
            component="h5"
            className={classes.text}
          >
            <b>{numCourses >= 10 ? '10+' : numCourses}</b> course{numCourses > 1 ? 's' : ''} with this position
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            size="medium"
            href={`${coursesUrl}?utm_source=chessvision-ai&utm_medium=partner&utm_campaign=board_explorer`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => { emitEvent(eventTypes.chessableCoursesClick, { fen }); }}
            color="secondary"
            variant="contained"
          >See courses</Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(ChessableCourses);
