import React from 'react';

import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import settings from '../../../settings';

import ExternalLink from '../../../Components/ExternalLink';

import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

class Terms extends React.Component {
  render() {
    // Styles
    const { classes } = this.props;

    const { onAccept, onReject } = this.props;

    return (
      <div>
        <div className={classes.headerWrapper}>
          <div className={classes.headerDivider} />
          <Typography className={classes.header}>
            Confirm account creation
          </Typography>
        </div>
        <div className={classes.terms}>
          Creating an account means you’re okay with our <ExternalLink url={settings.links.TOS}>Terms of Service</ExternalLink>, <ExternalLink url={settings.links.PRIVACY_POLICY}>Privacy Policy</ExternalLink>, and our default <ExternalLink url={settings.links.NOTIFICATION_SETTINGS}>Notification Settings</ExternalLink>.
        </div>
        <div className={classes.actions}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            style={{ marginTop: '1rem', textTransform: 'none' }}
            onClick={onAccept}>Create account</Button>
          <Button
            variant="outlined"
            fullWidth
            style={{ marginTop: '1rem', textTransform: 'none' }}
            onClick={onReject}>Logout</Button>
        </div>
      </div>
    );
  }
}

Terms.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(Terms);
