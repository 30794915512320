import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';

import AccountTab from '../Tabs/Settings/Account';
// import AppearanceTab from '../Tabs/Settings/Appearance';

const styles = (theme) => ({
  tabs: {
    marginBottom: theme.spacing(1)
  },
  closeButton: {
    marginRight: theme.spacing(2),
  },
});

class SettingsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0
    };
  }

  handleKeyPress = (event) => {
    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      this.props.onClose();
    }
  };

  changeTab = (event, value) => {
    this.setState({
      selectedTab: value
    });
  };

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const {
      fullScreen,
      open,
      user,
      isPerformingAuthAction,
      onUpgradeClick,
      onExtendClick,
    } = this.props;

    // Events
    const {
      onClose,
      onCancelSubscriptionClick,
      getSubscription,
    } = this.props;

    const { selectedTab } = this.state;

    return (
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose} onKeyPress={this.handleKeyPress}>
        <DialogTitle>Settings</DialogTitle>

        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.changeTab}
          value={selectedTab}
          variant="fullWidth"
          TabIndicatorProps={{ style: { width: '100%' }}}
        >
          <Tab label="Account" />
        </Tabs>

        <DialogContent>
          {selectedTab === 0 &&
            <AccountTab
              user={user}
              isPerformingAuthAction={isPerformingAuthAction}
              onCancelSubscriptionClick={onCancelSubscriptionClick}
              getSubscription={getSubscription}
              onUpgradeClick={onUpgradeClick}
              onExtendClick={onExtendClick}
            />
          }
          {/*
          {selectedTab === 1 &&
            <AppearanceTab
              user={user}
              isPerformingAuthAction={isPerformingAuthAction}
              layoutDirection={layoutDirection}
              onLayoutDirectionChange={onLayoutDirectionChange}
            />
          }
          */}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" className={classes.closeButton} onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SettingsDialog.propTypes = {
  classes: PropTypes.object.isRequired,

  fullScreen: PropTypes.bool,
  open: PropTypes.bool.isRequired,

  user: PropTypes.object.isRequired,
  isPerformingAuthAction: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
  onUpgradeClick: PropTypes.func.isRequired,
  onExtendClick: PropTypes.func.isRequired,
  onCancelSubscriptionClick: PropTypes.func.isRequired,
  getSubscription: PropTypes.func.isRequired,
};

export default withStyles(styles)(SettingsDialog);
