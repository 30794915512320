import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@material-ui/icons/Check';

import grey from '@material-ui/core/colors/grey';

import { SubscribeButton } from '../Buttons';

import settings from '../settings';

const styles = (theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  card: {
    backgroundColor: theme.palette.background.default,
  },
  cardHeaderTitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  cardHeaderSubtitle: {
    fontSize: '2rem',
    fontWeight: 300,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingBottom: theme.spacing(3),
  },
  price: {
    fontSize: '1.8rem',
    fontWeight: 800,
  },
  discount: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  discountPrice: {
    textDecoration: 'line-through',
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    marginRight: theme.spacing(1),
  },
  discountInfo: {
    fontSize: '1rem',
    color: theme.palette.secondary.main,
  },
  priceUnit: {
    color: grey[600],
    fontSize: '1rem',
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  feature: {
    display: 'flex',
    color: grey[700],
    padding: '0.25rem 0',
  },
});

const bullets = [
  <span>Unlimited <b>Board Explorer</b>, <b>Video Search</b>, and <b>Video Watch</b> for 30k+ chess videos</span>,
  <span>Unlimited <b>Video Search</b> in the <b>Chrome Extension</b> and <b>Mobile Apps</b></span>,
  'Lifetime free updates and priority support',
];

class SubscriptionPlansDialog extends Component {

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { open } = this.props;

    // Custom Properties
    const {
      user,
      title,
    } = this.props;
    const { discount } = this.props;

    // Dialog Events
    const { onClose } = this.props;

    if (!onClose) {
      return null;
    }

    return (
      <Dialog open={open} onClose={onClose} onKeyPress={this.handleKeyPress} maxWidth='md' fullWidth>
        {title && <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>}

        <DialogContent>
          <Container className={classes.container} maxWidth='md'>
            {settings.subscriptionTiers.map(tier => (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={tier.title} xs={12} sm={5}>
                <Card className={classes.card}>
                  <CardHeader
                    title={tier.subtitle}
                    subheader={tier.title}
                    titleTypographyProps={{ align: 'center', color: 'secondary', variant: 'h4' }}
                    subheaderTypographyProps={{ align: 'center', variant: 'body1' }}
                    classes={{ root: classes.cardHeader, title: classes.cardHeaderTitle, subheader: classes.cardHeaderSubtitle }}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography variant="h2" className={classes.price}>
                        { discount
                          ? (
                            <>
                              <div className={classes.discount}>
                                <span className={classes.discountInfo}>discount for eBook Reader Member</span>
                                <div>
                                  <span className={classes.discountPrice}>${tier.price}</span>
                                  <span>${tier.discountPrice}</span>
                                  <span className={classes.priceUnit}>/{tier.unit}</span>
                                </div>
                              </div>
                            </>
                          )
                          : (
                            <>
                              <span>${tier.price}</span>
                              <span className={classes.priceUnit}>/{tier.unit}</span>
                            </>
                          )
                        }
                      </Typography>
                    </div>
                    <ul>
                      {bullets.map((line, i) => (
                        <Typography component="li" variant="body1" align="left" key={i} className={classes.feature}>
                          <ListItemIcon style={{ marginTop: '-2px', minWidth: '2.5rem' }}>
                            <CheckIcon style={{color: 'green' }} fontSize="medium" />
                          </ListItemIcon>
                          <span>{line}</span>
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <SubscribeButton user={user} plan={tier.plan} color={tier.buttonColor} disabled={false} text={tier.buttonText} fullWidth />
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Container>
        </DialogContent>
      </Dialog>
    );
  }
}

SubscriptionPlansDialog.defaultProps = {
  isPerformingOkAction: false,
};

SubscriptionPlansDialog.propTypes = {
  open: PropTypes.bool.isRequired,

  user: PropTypes.object.isRequired,
  title: PropTypes.string,

  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(SubscriptionPlansDialog);
