import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import ReactPlayer from 'react-player/youtube';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

import TimeAgo from 'react-timeago';

import { nFormatter, timestampFormatter } from '../../../../utils';
import settings from '../../../../settings';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'block',
    // paddingBottom: '1.5rem',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  // solution to auto scalling to width player from: https://github.com/CookPete/react-player/issues/145
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%', /* Player ratio: 100 / (1280 / 720) */
    maxWidth: 'none',
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  playIcon: {
    fontSize: '64px',
  },
  infoContainer: {
    flex: 1,
    fontSize: '0.8rem',
    marginTop: '0.5rem',
    paddingLeft: 0,
    marginRight: '1rem',
  },
  titleWrapper: {
    fontSize: '1.2rem',
  },
  title: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      opacity: 0.7,
    },
  },
  subtitle: {
    marginTop: '0.2rem',
    '& > span': {
    },
  },
  channelWrapper: {
    marginTop: '0.25rem',
  },
  channel: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      opacity: 0.7,
    },
  },
  description: {
    marginTop: '0.75rem',
    color: '#606060',
  },
  timestamp: {
    minWidth: 'auto',
  },
}));

const Result = ({ timestamps, video, channel }) => {
  const classes = useStyles();

  const getUrl = ts => `https://www.youtube.com/watch?v=${video.id}&t=${ts}`;

  const [timestamp, setTimestamp] = useState(timestamps[0]);
  const [light, setLight] = useState(true);

  const watchUrl = `${settings.routes.VIDEO_WATCH}/${video.id}?t=${timestamp}`;

  return (
    <div className={classes.container}>
      <div className={classes.playerWrapper}>
        <ReactPlayer
          url={getUrl(timestamp)}
          controls={true}
          light={light}
          width="100%"
          height="100%"
          playing={true}
          playIcon={<PlayCircleFilledWhiteIcon style={{ fontSize: '96px', fill: 'red', backgroundColor: '#fff', borderRadius: '50%' }} />}
          className={classes.player}
        />
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.titleWrapper}>
          <a href={getUrl(timestamp)} target="_blank" rel="noopener noreferrer" className={classes.title}>{video.title}</a>
        </div>
        <div className={classes.channelWrapper}>
          <a href={`https://youtube.com/channel/${channel.id}`} target="_blank" rel="noopener noreferrer" className={classes.channel}>{channel.name}</a>
        </div>
        <div className={classes.subtitle}>
          <span className={classes.timestamps}>at {timestamps.map(t =>
              <Button key={`ts=${t}`} className={classes.timestamp} variant="text" size="small"onClick={() => { setTimestamp(t); setLight(false) }}>{timestampFormatter(t)}</Button>).reduce((prev, cur) => [prev, '|', cur])}</span> •&nbsp;
          <span className={classes.viewCount}>
            {nFormatter(video.views)} views
          </span> •&nbsp;
          <span className={classes.date}>
            <TimeAgo date={video.upload_date} />
          </span> •&nbsp;
          <Button
            component={Link}
            variant="outlined"
            to={watchUrl}
            color="primary"
            size="small"
            style={{ textTransform: 'none', minWidth: 'auto' }}
          >Watch with Chessvision.ai</Button>
        </div>
      </div>
    </div>
  );
}

export default Result;
