import settings from './settings';

const eventTypes = {
  // Landing.js
  signUpGoogle: {
    name: 'sign_up',
    method: 'google',
  },
  signInGoogle: {
    name: 'sign_in',
    method: 'google',
  },

  signUpEmail: {
    name: 'sign_up',
    method: 'email',
  },
  signInEmail: {
    name: 'sign_in',
    method: 'email',
  },

  // Chrome Extension
  signInChromeExtension: {
    name: 'sign_in_chrome_extension',
  },

  // App.js
  signOut: {
    name: 'sign_out',
  },


  // CheckoutButton
  checkoutBegin: {
    name: 'checkout_begin',
  },

  // CheckoutCanceled
  checkoutCancel: {
    name: 'checkout_cancel',
  },

  // CheckoutSuccessful
  checkoutSuccess: {
    name: 'checkout_success',
  },

  // CustomerPortalButton.js
  customerPortalOpen: {
    name: 'customer_portal_open',
  },

  // Top right Menu
  videoTutorialsDialogOpen: {
    name: 'video_tutorials_dialog_open',
  },

  // subscription plans dialog open
  subscriptionPlansDialogOpenAppBar: {
    name: 'subscription_plans_dialog_open',
    'entrypoint': 'app_bar',
  },
  subscriptionPlansDialogOpenExplorer: {
    name: 'subscription_plans_dialog_open',
    'entrypoint': 'explorer',
  },
  subscriptionPlansDialogOpenSearch: {
    name: 'subscription_plans_dialog_open',
    'entrypoint': 'search',
  },
  subscriptionPlansDialogOpenWatch: {
    name: 'subscription_plans_dialog_open',
    'entrypoint': 'watch',
  },
  subscriptionPlansDialogOpenFromChromeExtension: {
    name: 'subscription_plans_dialog_open',
    'entrypoint': 'chrome_extension',
  },

  // App.js
  subscriptionPlansDialogCancel: {
    name: 'subscription_plans_dialog_cancel',
  },

  // Chessable courses
  chessableCoursesShow: {
    name: 'chessable_courses_show',
  },
  chessableCoursesClick: {
    name: 'chessable_courses_click',
  },
};

const emitEvent = (eventType, options = {}) => {
  if (!settings.useAnalytics) {
    return;
  }

  const { name, ...params } = eventType;

  // window.analytics is binded to Segment in public/index.html
  window.analytics.track(name, { ...params, ...options });
}

const identifyUser = user => {
  if (!settings.useAnalytics) {
    return;
  }

  const payload = { email: user.email }
  if (user.displayName) {
    payload.name = user.displayName;
  }
  window.analytics.identify(user.uid, payload);
}

const visitPage = () => {
  if (!settings.useAnalytics) {
    return;
  }

  window.analytics.page();
}

export {
  eventTypes,
  emitEvent,
  identifyUser,
  visitPage,
};
