import React from 'react';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import ExternalLink from '../../Components/ExternalLink';

import settings from '../../settings';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 1024,
    margin: '0 auto',
    marginTop: theme.spacing(4),
  },
  header: {
    textAlign: "center",
  },
  subheader: {
    textAlign: "center",
  },
  items: {
    marginTop: theme.spacing(3),
  },
  item: {
    textDecoration: 'none',
    '& a': {
      textDecoration: 'none',
    },
  },
  card: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

const items = [
  {
    title: 'Board Explorer',
    description: 'Play your moves and explore YouTube videos matching positions on the board. Load PGN of your games and use the videos to improve.',
    route: settings.routes.BOARD_EXPLORER,
  },
  {
    title: 'Video Search',
    description: 'Search YouTube chess videos by content with various filters. The results point to the exact timestamps in the videos.',
    route: settings.routes.VIDEO_SEARCH,
  },
  {
    title: 'Watch Videos with PGN',
    description: 'Watch chess videos from YouTube with synchronized analysis board, engine, and list of moves.',
    route: settings.routes.VIDEO_WATCH,
  },
  {
    title: 'Diagram Library',
    description: 'Explore diagrams you save in our Mobile App and load them to the Board Explorer.',
    route: settings.routes.DIAGRAM_LIBRARY,
  },
];

const Home = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.header}>
        Welcome to Chessvision.ai
      </Typography>
      <Typography variant="body2" className={classes.subheader}>
        We make chess content interactive.
      </Typography>
      { settings.earlyAccess
        ? (
          <Typography style={{ marginTop: '1rem', textAlign: 'center', color: 'rgba(214, 79, 0, 0.9)', fontWeight: 400}}>
            The app is in Early Access now. The idea is to listen to your feedback before the official release.
            In the Early Access, all the features are available for free without any limitations.
            After playing with the app, please fill <ExternalLink url={settings.surveyUrl}>short survey</ExternalLink> so we can improve before the release.
          </Typography>
        )
        : (
          <Typography style={{ marginTop: '1rem', textAlign: 'center', color: 'rgba(214, 79, 0, 0.9)', fontWeight: 400}}>
            Get the most out of over 30k YouTube chess videos.
          </Typography>
        )
      }
      <Grid container spacing={3} className={classes.items}>
      { items.map(({ title, description, route }) => (
        <Grid key={title} item xs={12} sm={6} md={6} className={classes.item}>
          <Link to={route}>
            <Card variant="outlined" className={classes.card}>
              <CardContent>
                <Typography
                  variant="h6"
                  >{title}</Typography>
                <Typography
                  variant="body2"
                >{description}</Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
      </Grid>
    </div>
  );
}

export default Home;
