import React from 'react';

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

import Filters from './components/Filters';
import Chips from './components/Chips';
import Sorters from './components/Sorters';
import Results from './Results';
import RestrictedResult from './RestrictedResult';

import { emitEvent, eventTypes } from '../../events';
import { getRandomThumbs } from '../../utils';
import settings from '../../settings';

const styles = (theme) => ({
  container: {
    maxWidth: '1024px',
    margin: '0 auto',
    marginTop: theme.spacing(4),
  },
  welcomeContainer: {
    minHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1rem',
  },
  header: {
    fontSize: '3rem',
    fontWeight: 800,
    letterSpacing: '-0.025em',
  },
  subheader: {
    fontSize: '1.25rem',
    maxWidth: '48rem',
    color: grey[500],
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1.25rem',
  },
  loading: {
    display: 'block',
    margin: '8rem auto',
  },
  sortersWrapper: {
    padding: '0.5rem 0',
  },
  resultsContainer: {
    marginTop: '1rem',
    padding: '0rem 1rem',
  },
  moreResultsContainer: {
    marginTop: '1rem',
    paddingBottom: '3rem',
    display: 'flex',
    justifyContent: 'center',
  },
  restrictedContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  restrictedUnlock: {
    position: 'absolute',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    zIndex: 999,
    textAlign: 'center',
    top: theme.spacing(10),
    padding: theme.spacing(3),
    borderRadius: theme.spacing(3),
  },
});

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: null,
      opening: null,
      variation: null,
      phase: null,
      // phase: { label: "Endgame", value: "endgame" },
      openness: null,
      endgameType: null,
      pawnStructure: null,
      themes: null,

      sortBy: 'popular',

      results: null,
      nextResultsId: null,

      loading: false,
      loadingMore: false,
      numRestricted: 0,
    };
  }

  handleFilterChange = (key, obj) => {
    const updates = { [key]: obj };
    if (key === 'opening') {
      updates.variation = null;
    }
    if (key === 'phase') {
      updates.endgameType = null;
    }
    this.setState(updates, () => {
      this.fetchResults();
    });
  }

  handleChipClick = (values) => {
    const updates = {
      channel: null,
      opening: null,
      variation: null,
      phase: null,
      endgameType: null,
      openness: null,
      pawnStructure: null,
      themes: null,
    };
    Object.assign(updates, values);
    this.setState(updates, () => {
      this.fetchResults();
    });
  }

  handleSortChange = (e) => {
    this.setState({
      sortBy: e.target.value,
    }, () => {
      this.fetchResults();
    });
  }

  getQueryString = () => {
    const {
      opening, variation, phase, openness, endgameType, pawnStructure, themes,
    } = this.state;

    let query = [];
    if (opening !== null) {
      query.push(`opening=${opening.value.toLowerCase()}`);
    }
    if (variation !== null) {
      query.push(`variation=${variation.value.toLowerCase()}`);
    }

    if (phase !== null) {
      query.push(`phase=${phase.value}`);
    }
    if (endgameType !== null) {
      query.push(`endgame_type=${endgameType.value}`);
    }

    if (openness !== null) {
      query.push(`openness=${openness.value}`);
    }

    if (pawnStructure !== null) {
      query.push(`pawn_structure=${pawnStructure.value}`);
    }

    if (themes !== null) {
      for (const theme of themes) {
        query.push(theme.value);
      }
    }

    if (query.length === 0) {
      return null;
    }
    query.sort();
    const queryStr = query.join(";");
    return queryStr;
  };


  fetchResults = (queryMore = false) => {
    const queryStr = this.getQueryString();
    if (queryStr === null) {
      return;
    }

    const { resultsId } = this.state;

    this.setState({
      loading: !queryMore,
      loadingMore: queryMore,
      resultsId: queryMore ? resultsId : null,
    }, () => {
      const { user } = this.props;
      const { channel } = this.state;
      let url = `${settings.videoApiUrl}/search_by_tags?tags=${queryStr}&`;
      if (channel !== null) {
        url += `channel_id=${channel.value}&`;
      }
      url += `ordering=${this.state.sortBy}`;

      if (queryMore) {
        url += `&next_results_id=${this.state.nextResultsId}`;
      }
      return user.getIdToken().then(authToken => {
        const headers = {
          'accept': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        };
        fetch(url, { headers })
        .then(response => response.json())
        .then(data => {
          const {
            channel_id: channelId,
            tags, results: newResults, next_results_id: nextResultsId,
            num_restricted: numRestricted,
          } = data;
          const queryStrNow = this.getQueryString();
          // check if the response corresponds to the current filters
          const channelIdNow = this.state.channel ? this.state.channel.value : null;
          if (channelId === channelIdNow && tags === queryStrNow) {
            this.setState({
              results: queryMore ? [...this.state.results, ...newResults] : newResults,
              loading: false,
              loadingMore: false,
              nextResultsId,
              numRestricted,
            });
          }
        });
      });
    });
  }

  render() {
    const { classes } = this.props;
    const { onUpgradeClick } = this.props;
    const { onUpgradeLoading } = this.props;

    const { channel } = this.state;
    const { opening, variation, phase, openness, endgameType, pawnStructure, themes } = this.state;
    const { sortBy } = this.state;
    const { results, nextResultsId, loading, loadingMore } = this.state;
    const { numRestricted } = this.state;

    const atLeastOneDefined = opening || phase || openness || pawnStructure || (themes && themes.length > 0);
    return (
      <div className={classes.container}>
        <div className={classes.filtersWrapper}>
          <Filters
            channel={channel}
            opening={opening}
            variation={variation}
            phase={phase}
            openness={openness}
            endgameType={endgameType}
            pawnStructure={pawnStructure}
            themes={themes}
            onChange={this.handleFilterChange}
          />
        </div>
        <div className={classes.chipsWrapper}>
          <Chips
            opening={opening}
            variation={variation}
            phase={phase}
            openness={openness}
            endgameType={endgameType}
            pawnStructure={pawnStructure}
            themes={themes}
            onClick={this.handleChipClick}
          />
        </div>
        { loading && atLeastOneDefined && (
          <CircularProgress className={classes.loading} />
        )}
        { !loading && (results ===  null || !atLeastOneDefined) && (
          <div className={classes.welcomeContainer}>
            <Typography className={classes.header} variant="h1" align="center">
              Search YouTube chess videos by content
            </Typography>
            <Typography className={classes.subheader} align="center">
              Define one or more filters, or use the predefined searches. <br />
              The results point to the exact timestamps in the videos.
            </Typography>
          </div>
        )}
        { !loading && (results !== null && atLeastOneDefined) && (
          <div className={classes.resultsContainer}>
            <div className={classes.sortersWrapper}>
              { results.length > 0 && (
                <Sorters
                  sortBy={sortBy}
                  onChange={this.handleSortChange}
                />
              )}
            </div>
            <Results results={results} />
            { numRestricted === 0
            ? (
              <>
              { nextResultsId && loadingMore && (
                <div className={classes.moreResultsContainer}>
                  <CircularProgress className={classes.loading} />
                </div>
              )}
              { nextResultsId && !loadingMore && (
                <div className={classes.moreResultsContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => { this.fetchResults(true)}}>More Results</Button>
                </div>
              )}
              </>
            )
            : (
              <div className={classes.restrictedContainer}>
                <div className={classes.restrictedUnlock}>
                  <Typography variant="h6">More results available</Typography>
                  <Typography>Upgrade your account to get unlimited access</Typography>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    disabled={onUpgradeLoading}
                    onClick={() => {
                      emitEvent(eventTypes.subscriptionPlansDialogOpenSearch);
                      onUpgradeClick();
                    }}
                    style={{ marginTop: '1rem' }}
                  >Upgrade</Button>
                </div>
                <div className={classes.restrictedResults}>
                  { getRandomThumbs(numRestricted).map((thumb, i) => <RestrictedResult key={`RestrictedResult-${i}`} thumb={thumb} /> )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Search);
