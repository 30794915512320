import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { getFunctions, httpsCallable } from 'firebase/functions';

import ExternalLink from '../../../Components/ExternalLink';

import { messageExtension } from '../../../utils';

import { eventTypes, emitEvent } from '../../../events';
import settings from '../../../settings';

const styles = theme => ({
  container: {
    maxWidth: 1024,
    margin: '0 auto',
    marginTop: theme.spacing(4),
  },
  header: {
    textAlign: "center",
  },
  subheader: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
  loading: {
    display: 'block',
    margin: '4rem auto',
    textAlign: 'center',
  },
  successIcon: {
    display: 'block',
    margin: '0 auto',
    marginBottom: theme.spacing(2),
    color: 'green',
  },
  failureIcon: {
    display: 'block',
    margin: '0 auto',
    marginBottom: theme.spacing(2),
    color: 'red',
  }
});

const functions = getFunctions();

class ChromeExtensionAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false,
      isPerformingAuthAction: false,
    };
  }
  componentDidMount() {
    this.extensionSignIn();
  }

  extensionSignIn = () => {
    const { user } = this.props;

    if (user) {
      this.setState({
        isPerformingAuthAction: true
      }, () => {
        const createCustomAuthToken = httpsCallable(functions, 'createCustomAuthToken');
        createCustomAuthToken().then(result => {
          const { token } = result.data;
          messageExtension({
            action: 'sign_in',
            token,
          }, (response) => {
            const { success } = response;
            if (success !== undefined) {
              emitEvent(eventTypes.signInChromeExtension, { success });
              this.setState({
                isPerformingAuthAction: false,
                authorized: success,
              });
            }
          });
        });
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { isPerformingAuthAction, authorized } = this.state;

    return (
      <div className={classes.container}>
        <Typography variant="h4" className={classes.header}>
          Login to Chrome Extension
        </Typography>
        { isPerformingAuthAction && (
          <div className={classes.subheader}>
            <Typography variant="body2">
              Logging in progress...
            </Typography>
            <CircularProgress className={classes.loading} />
          </div>
        )}
        { !isPerformingAuthAction && authorized && (
          <div className={classes.subheader}>
            <CheckCircleIcon className={classes.successIcon} fontSize="large" />
            <Typography variant="body2">
              You are now <b>logged into</b> your Chrome Extension.
            </Typography>
            <Typography variant="body2">
              Get back to it and explore new features!
            </Typography>
          </div>
        )}
        { !isPerformingAuthAction && !authorized && (
          <div className={classes.subheader}>
            <CancelIcon className={classes.failureIcon} fontSize="large" />
            <Typography variant="body2">
              An error occurred while logging in to the Chrome Extension.
            </Typography>
            <Typography variant="body2">
              If you think this should not have happened please <ExternalLink url={`mailto:${settings.values.supportEmail}`}>email us</ExternalLink>.
            </Typography>
          </div>
        )}
      </div>
    );
  }

}

export default withStyles(styles)(ChromeExtensionAuth);
