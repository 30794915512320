import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  }
}));

const Launch = () => {
  const classes = useStyles();
  return (
    <CircularProgress className={classes.circularProgress} />
  );
}

export default Launch;
