const styles = (theme) => ({
  headerWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  headerDivider: {
    display: 'inline-block',
    width: '100%',
    height: '1px',
    borderBottom: `1px solid ${theme.palette.gray.main}`,
    marginBottom: '4px',
    position: 'absolute',
    marginTop: '-1px',
    top: '50%',
    left: 0,
    zIndex: 0,
  },
  header: {
    color: theme.palette.gray.dark,
    backgroundColor: theme.palette.background.default,
    padding: '0 10px',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    zIndex: 1,
  },
  formHelper: {
    fontSize: '0.8rem',
    color: theme.palette.gray.main,
    '& a': {
      color: theme.palette.gray.dark,
    },
    '& button': {
      verticalAlign: 'baseline',
      textTransform: 'none',
      color: theme.palette.gray.dark,
    }
  },
  formHelperSmall: {
    fontSize: '0.7rem',
    textAlign: 'right',
    '& button': {
      fontSize: '0.7rem',
    }
  },
  terms: {
     marginTop: 10,
     marginBottom: 10,
     color: '#aaa',
     fontSize: '0.8rem',
     '& a': {
       color: '#aaa',
     },
  },
  actions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  secondaryActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem',
    alignItems: 'center',
    '& button': {
      textTransform: 'none',
      opacity: 0.5,
    },
  },
});

export default styles;
