import React from 'react';

import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import googleLogo from '../../../Assets/google-logo.png'
import appleLogo from '../../../Assets/apple-logo.png'

const useStyles = makeStyles(theme => ({
  buttons: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(1.5),
    textTransform: 'none',
    '&:hover': {
      opacity: 0.8,
    },
  },
  disabledButton: {
    color: '#fff !important',
    backgroundColor: `${theme.palette.gray.main} !important`,
  },
  google: {
    backgroundColor: '#4285f4',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4285f4',
    },
  },
  apple: {
    backgroundColor: '#000',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#000',
    },
  },
}));

const AuthProviders = ({ isPerformingAuthAction, onAuthProviderClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.buttons}>
      <Button
        className={`${classes.button} ${classes.google}`}
        classes={{ disabled: classes.disabledButton }}
        disabled={isPerformingAuthAction}
        fullWidth
        startIcon={<img src={googleLogo} height={24} alt="Google logo"/>}
        onClick={() => onAuthProviderClick(new GoogleAuthProvider())}>
        Sign in with Google
      </Button>
      <Button
        className={`${classes.button} ${classes.apple}`}
        classes={{ disabled: classes.disabledButton }}
        disabled={isPerformingAuthAction}
        fullWidth
        startIcon={<img src={appleLogo} height={18} alt="Apple logo" style={{ filter: 'invert(1)'}} />}
        onClick={() => onAuthProviderClick(new OAuthProvider('apple.com'))}>
        <span>Sign in with Apple</span>
      </Button>
    </div>
  );
}

export default AuthProviders;
