import Chess from 'chess.js';

import thumb1 from './Assets/placeholders/thumbs/1.jpg';
import thumb2 from './Assets/placeholders/thumbs/2.jpg';
import thumb3 from './Assets/placeholders/thumbs/3.jpg';
import thumb4 from './Assets/placeholders/thumbs/4.jpg';
import thumb5 from './Assets/placeholders/thumbs/5.jpg';
import thumb6 from './Assets/placeholders/thumbs/6.jpg';
import thumb7 from './Assets/placeholders/thumbs/7.jpg';
import thumb8 from './Assets/placeholders/thumbs/8.jpg';
import thumb9 from './Assets/placeholders/thumbs/9.jpg';
import thumb10 from './Assets/placeholders/thumbs/10.jpg';

import settings from './settings';

// general

export function debounce(cb, delay = 100) {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  }
}

// extension related

export const messageExtension = (payload, callback) => {
  if (window.chrome && window.chrome.runtime) {
    for (const extensionID of settings.chromeExtensionIDs) {
      window.chrome.runtime.sendMessage(extensionID, payload, callback);
    }
  }
}

// chess related

export const flipPosition = (position) => {
  const rows = position.split('/');
  const flippedRows = rows.map(row => [...row].reverse().join('')).reverse();
  const flippedPosition = flippedRows.join('/');
  return flippedPosition;
};

export const reverseFenOrientation = fen => {
  const [position, ...rest] = fen.split(' ');
  return [flipPosition(position), ...rest].join(' ');
}

export function parseFenFromUrl(fen) {
  const separators = ['_', '+'];
  let res = fen;
  for (const sep of separators) {
    res = res.split(sep).join(' ');
  }
  return res;
}

export const validateFen = (fen) => {
  fen = fen.trim();
  fen = fen.split('_').join(' '); // to accept '_' separator from input
  const parts = fen.split(' ');
  const defaults = ['w', 'KQkq', '-', '0', '1'];
  while (parts.length < 6) {
    parts.push(defaults[parts.length - 1]);
  }
  fen = parts.join(' ');
  const board = new Chess();
  const isValid = board.load(fen);
  return isValid ? board.fen() : null;
}

// general

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max + 1 - min) + min); // Both max and min are inclusive
}

// source: https://stackoverflow.com/a/12646864/948918
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export const getRestrictedWord = (minLen = 2, maxLen = 5) => {
  const len = getRandomInt(minLen, maxLen);
  const chars = ['X', 'x'];
  let res = "";
  while (res.length < len) {
    res += chars[getRandomInt(0, chars.length - 1)];
  }
  return res;
}

export const getRestrictedText = (minLen = 1, maxLen = 10) => {
  const len = getRandomInt(minLen, maxLen);
  let res = "";
  while (res.length < len) {
    res += getRestrictedWord(minLen = 2, maxLen = 10);
    if (res.length + 3 <= len) {
      res += " ";
    }
  }
  return res.slice(0, len);
}

export const getRandomThumbs = (n) => {
  const thumbs = [thumb1, thumb2, thumb3, thumb4, thumb5, thumb6, thumb7, thumb8, thumb9, thumb10];
  shuffleArray(thumbs);
  return thumbs.slice(0, n);
}

export const nFormatter = (num, digits) => {
  // implementation source: https://stackoverflow.com/a/9462382/948918
  const si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "G" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; --i) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const truncate = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export const dateFromInt = dateInt => {
  // from format YYYYMMDD
  const day = dateInt % 100;
  const month = Math.floor(dateInt / 100) % 100;
  const year = Math.floor(dateInt / 10000);
  return new Date(year, month, day);
};

export const timestampFormatter = timestamp => {
  let hoursPrefix = '';
  if (timestamp >= 3600) {
    hoursPrefix = `${Math.floor(timestamp / 3600)}:`;
    timestamp %= 3600;
  }
  return `${hoursPrefix}${String(Math.floor(timestamp / 60)).padStart(2, '0')}:${String(timestamp % 60).padStart(2, '0')}`;
}

export const toTitleCase = (phrase) => {
  // implementation source: https://stackoverflow.com/a/43376967/948918
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// theming for react-select
// using: https://github.com/JedWatson/react-select/issues/3692#issuecomment-671084399
export const getSelectTheme = theme => {
  return (selectTheme) => ({
    ...selectTheme,
    colors: {
      /*
      * multiValue(remove)/color:hover
      */
      danger: 'purple',

      /*
       * multiValue(remove)/backgroundColor(focused)
       * multiValue(remove)/backgroundColor:hover
       */
      dangerLight: theme.palette.grey[200],

      /*
       * control/backgroundColor
       * menu/backgroundColor
       * option/color(selected)
       */
      neutral0: theme.palette.background.default,

      /*
        * control/backgroundColor(disabled)
       */
      neutral5: "orange",

      /*
       * control/borderColor(disabled)
       * multiValue/backgroundColor
       * indicators(separator)/backgroundColor(disabled)
       */
      neutral10: theme.palette.background.paper,

      /*
       * control/borderColor
       * option/color(disabled)
       * indicators/color
       * indicators(separator)/backgroundColor
       * indicators(loading)/color
       */
      neutral20: theme.palette.grey['A200'],

      /*
       * control/borderColor(focused)
       * control/borderColor:hover
       */
      // this should be the white, that's normally selected
      neutral30: theme.palette.text.primary,

      /*
       * menu(notice)/color
       * singleValue/color(disabled)
       * indicators/color:hover
       */
      neutral40: theme.palette.text.primary,

      /*
       * placeholder/color
       */
      // seen in placeholder text
      neutral50: theme.palette.grey['A200'],

      /*
       * indicators/color(focused)
       * indicators(loading)/color(focused)
       */
      neutral60: theme.palette.secondary.main,
      neutral70: theme.palette.secondary.main,

      /*
       * input/color
       * multiValue(label)/color
        * singleValue/color
       * indicators/color(focused)
       * indicators/color:hover(focused)
       */
      neutral80: theme.palette.text.primary,

      // no idea
      neutral90: "pink",

      /*
       * control/boxShadow(focused)
       * control/borderColor(focused)
       * control/borderColor:hover(focused)
       * option/backgroundColor(selected)
       * option/backgroundColor:active(selected)
       */
      primary: theme.palette.text.primary,

      /*
       * option/backgroundColor(focused)
       */
      primary25: theme.palette.secondary.light,

      /*
       * option/backgroundColor:active
       */
      primary50: theme.palette.background.paper,
      primary75: theme.palette.background.paper,
    }
  });
}
