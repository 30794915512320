import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CopyIcon from '@material-ui/icons/FileCopy';

const styles = theme => ({
  root: {
    fontSize: '1.1em',
    opacity: 0.7,
    lineHeight: '1.3em',
  },
  game: {
    margin: theme.spacing(2),
  },
  moves: {
    margin: theme.spacing(1),
  },
  sanContainer: {
    whiteSpace: 'nowrap',
  },
  san: {
    opacity: 1,
    cursor: 'pointer',
    fontWeight: '700',
    '&:hover': {
      background: theme.palette.gray.main,
    },
  },
  sanActive: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  start: {
    cursor: 'pointer',
    display: 'inline-block',
    '&:hover': {
      background: theme.palette.gray.main,
    },
  },
  startActive: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  variation: {
  },
  copySequenceButton: {
    marginLeft: theme.spacing(2),
  },
});

const sequenceToPGN = (startingFen, textPgn) => {
  let out = '[Result "*"]\n';
  out += `[FEN "${startingFen}"]`;
  out += '\n\n';
  out += textPgn;
  return out;
};

class Hierarchy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pgnCopiedToClipboard: false,
    };
  }

  // using https://stackoverflow.com/a/60745953/948918
  copySequenceToClipboard = (seqId) => {
    const seqE = document.getElementById(`sequence-${seqId}`);
    const startingFen = seqE.getAttribute('fen');
    const textPgn = seqE.textContent;
    const e = document.createElement('textarea');
    e.value = sequenceToPGN(startingFen, textPgn);
    document.body.appendChild(e);
    e.select();
    try {
      document.execCommand('copy');
      e.focus();
      e.remove();
      this.setState({
        pgnCopiedToClipboard: true,
      });
    } catch (err) {
      e.remove();
      alert('Unable to copy to clipboard, please select and copy manually.')
    }
  }

  renderGame = (v, gameId) => {
    const { classes } = this.props;
    const { onMoveClick, curNode } = this.props;

    const turn = v.fen.split(" ")[1] === "w" ? "white" : "black";
    return (
      <React.Fragment>
        <div>
          <div
            className={`${classes.start} ${v.id === curNode ? classes.startActive : ''}`}
            onClick={() => onMoveClick(v.id)}
          >Sequence {gameId}
          </div>
          <Tooltip title="Copy sequence as PGN">
            <IconButton
              aria-label="copy-sequence"
              className={classes.copySequenceButton}
              onClick={() => { this.copySequenceToClipboard(gameId)}}
            ><CopyIcon /></IconButton>
          </Tooltip>
        </div>
        <div id={`sequence-${gameId}`} className={classes.moves} fen={v.fen}>
          { turn === "black" && (
            <span>1. ...</span>
          )}
          {this.renderNode(v, 1)}
        </div>
      </React.Fragment>
    )
  }

  renderNode = (v, moveId) => {
    const { classes } = this.props;
    const { onMoveClick, curNode } = this.props;
    if (v.children.length === 0) {
      return '';
    }

    const turn = v.fen.split(" ")[1] === "w" ? "white" : "black";
    const nextMoveId = turn === "white" ? moveId : moveId + 1;
    const main = v.children.filter(u => u.child.mainline)[0];
    const others = v.children.filter(u => !u.child.mainline);
    return (
      <React.Fragment>
        { turn === "white"
          ? (
            <span className={classes.sanContainer}>
              <span> {moveId}. </span>
              <span
                className={`${classes.san} ${main.child.id === curNode ? classes.sanActive : ''}`}
                onClick={() => onMoveClick(main.child.id)}
              > {main.move.san}</span>
            </span>
          )
          : (
            <span
              className={`${classes.san} ${main.child.id === curNode ? classes.sanActive : ''}`}
              onClick={() => onMoveClick(main.child.id)}
            > {main.move.san}</span>
          )
        }
        <React.Fragment>
          {others.map(u => {
            return (
              <span key={u.child.id} className={classes.variation}>
                <span> (</span>
                <span>{moveId}. </span>
                { turn === "black" && (
                  <span>...</span>
                )}
                <span
                  className={`${classes.san} ${u.child.id === curNode ? classes.sanActive : ''}`}
                  onClick={() => onMoveClick(u.child.id)}
                > {u.move.san}</span>
                {this.renderNode(u.child, nextMoveId)}
                <span>)</span>
              </span>
            );
          })}
        </React.Fragment>
        <React.Fragment>
          {this.renderNode(main.child, nextMoveId)}
        </React.Fragment>
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;
    const { forest } = this.props;
    const { pgnCopiedToClipboard } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.games}>
          { forest.map((root, gameId) => <div key={root.id} className={classes.game}>{this.renderGame(root, gameId+1)}</div>) }
        </div>
        <Snackbar
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'center',
         }}
         open={pgnCopiedToClipboard}
         autoHideDuration={2000}
         onClose={() => { this.setState({ pgnCopiedToClipboard: false })}}
         message="PGN copied!"
         severity="success"
       />
      </div>
    );
  }
}

export default withStyles(styles)(Hierarchy);
