import settings from '../../settings';

const channels = [...settings.observedChannels];

const openings = {
  "Alekhine Defense": ["Balogh Variation","Brooklyn Variation","Buckley Attack","Exchange Variation","Four Pawns Attack","Hunt Variation","Kmoch Variation","Krejcik Variation","Maróczy Variation","Modern Variation","Mokele Mbembe","Normal Variation","O'Sullivan Gambit","Scandinavian Variation","Spielmann Gambit","Steiner Variation","Sämisch Attack","The Squirrel","Two Pawn Attack","Welling Variation",],
  "Amar Gambit": [],
  "Amar Opening": ["Gent Gambit","Paris Gambit",],
  "Amazon Attack": ["Siberian Attack",],
  "Amsterdam Attack": [],
  "Anderssen Opening": ["Polish Gambit",],
  "Australian Defense": [],
  "Barnes Defense": [],
  "Barnes Opening": ["Fool's Mate","Gedult Gambit","Hammerschlag","Walkerling",],
  "Benko Gambit": ["Fianchetto Variation","Mutkin Countergambit","Nd2 Variation","Nescafe Frappe Attack","Zaitsev System",],
  "Benko Gambit Accepted": ["Central Storming Variation","Dlugy Variation","Fianchetto Variation","Fully Accepted Variation","King Walk Variation","Modern Variation","Pawn Return Variation","Yugoslav",],
  "Benko Gambit Declined": ["Bishop Attack","Hjørring Countergambit","Main Line","Pseudo-Sämisch","Quiet Line","Sosonko Variation",],
  "Benoni Defense": ["Benoni Gambit Accepted","Benoni-Indian Defense","Benoni-Staunton Gambit","Classical","Classical Variation","Cormorant Gambit","Czech Benoni Defense","Fianchetto Variation","Four Pawns Attack","Franco-Sicilian Hybrid","Hawk Variation","Hromádka System","King's Indian System","King's Pawn Line","Knight's Tour Variation","Mikenas Variation","Modern Variation","Old Benoni","Pawn Storm Variation","Snail Variation","Taimanov Variation","Uhlmann Variation","Weenink Variation","Woozle","Zilbermints-Benoni Gambit",],
  "Bird Opening": ["Batavo-Polish Attack","Dutch Variation","From's Gambit","Hobbs Gambit","Hobbs-Zilbermints Gambit","Horsefly Defense","Lasker Gambit","Lasker Variation","Mujannah","Myers Defense","Platz Gambit","Schlechter Gambit","Siegener Gambit","Sturm Gambit","Swiss Gambit","Thomas Gambit","Wagner-Zwitersch Gambit","Williams Gambit","Williams-Zilbermints Gambit",],
  "Bishop's Opening": ["Anderssen Gambit","Berlin Defense","Boden-Kieseritzky Gambit","Boi Variation","Calabrese Countergambit","Four Pawns Gambit","Horwitz Gambit","Khan Gambit","Kitchener Folly","Krejcik Gambit","Lewis Countergambit","Lewis Gambit","Lisitsyn Variation","Lopez Gambit","Lopez Variation","McDonnell Gambit","Pachman Gambit","Philidor Counterattack","Philidor Variation","Ponziani Gambit","Pratt Variation","Stein Gambit","Thorold Gambit","Urusov Gambit","Vienna Hybrid","Warsaw Gambit","del Rio Variation",],
  "Blackburne Shilling Gambit": [],
  "Blackmar Gambit": [],
  "Blackmar-Diemer Gambit": ["Bogoljubov Variation","Diemer-Rosenberg Attack","Euwe Defense","Fritz Attack","Gedult Gambit","Kaulich Defense","Netherlands Variation","Pietrowsky Defense","Rasa-Studier Gambit","Ritter Defense","Rook Pawn Defense","Ryder Gambit","Schlutter Defense","Tartakower Variation","Teichmann Variation","Vienna Variation","Zeller Defense","Ziegler Defense","von Popiel Gambit",],
  "Blackmar-Diemer Gambit Declined": ["Brombacher Countergambit","Elbert Countergambit","Grosshans Defense","Lamb Defense","Langeheinecke Defense","O'Kelly Defense","Weinsbach Declination",],
  "Blumenfeld Countergambit": ["Dus-Khotimirsky Variation","Spielmann Variation",],
  "Blumenfeld Countergambit Accepted": [],
  "Bogo-Indian Defense": ["Exchange Variation","Grünfeld Variation","Haiti Variation","Monticelli Trap","New England Variation","Nimzowitsch Variation","Retreat Variation","Vitolins Variation","Wade-Smyslov Variation",],
  "Bongcloud Attack": [],
  "Borg Defense": ["Borg Gambit","Langhorst Gambit","Troon Gambit",],
  "Borg Opening": ["Zilbermints Gambit",],
  "Bronstein Gambit": [],
  "Budapest Defense": ["Adler Variation","Alekhine Variation","Fajarowicz Variation","Fajarowicz-Steiner Variation","Rubinstein Variation",],
  "Canard Opening": [],
  "Caro-Kann Defense": ["Accelerated Panov Attack","Advance Variation","Alekhine Gambit","Breyer Variation","Bronstein-Larsen Variation","Classical","Classical Variation","De Bruycker Defense","Edinburgh Variation","Euwe Attack","Exchange Variation","Finnish Variation","Forgacs Variation","Goldman Variation","Gurgenidze Counterattack","Gurgenidze System","Hector Gambit","Hillbilly Attack","Karpov Variation","Labahn Attack","Main Line","Maróczy Variation","Masi Variation","Massachusetts Defense","Mieses Gambit","Modern Variation","Panov Attack","Rasa-Studier Gambit","Scorpion-Horus Gambit","Spike Variation","Tartakower Variation","Toikkanen Gambit","Two Knights Attack","Ulysses Gambit","von Hennig Gambit",],
  "Carr Defense": ["Zilbermints Gambit",],
  "Catalan Opening": ["Closed","Closed Variation","Hungarian Gambit","Open Defense",],
  "Center Game": ["Berger Variation","Charousek Variation","Halasz-McDonnell Gambit","Hall Variation","Kieseritzky Variation","Kupreichik Variation","Lanc-Arnold Gambit","Normal Variation","Paulsen Attack Variation","Ross Gambit","l'Hermet Variation","von der Lasa Gambit",],
  "Center Game Accepted": [],
  "Clemenz Opening": ["Spike Lee Gambit",],
  "Colle System": ["Pterodactyl Variation","Rhamphorhynchus Variation","Siroccopteryx Variation","Traditional Colle",],
  "Crab Opening": [],
  "Creepy Crawly Formation": ["Classical Defense",],
  "Czech Defense": [],
  "Danish Gambit": [],
  "Danish Gambit Accepted": ["Chigorin Defense","Classical Defense","Copenhagen Defense","Schlechter Defense","Svenonius Defense",],
  "Danish Gambit Declined": ["Sorensen Defense",],
  "Dresden Opening": ["The Goblin",],
  "Duras Gambit": [],
  "Dutch Defense": ["Alapin Variation","Alekhine Variation","Blackburne Variation","Blackmar's Second Gambit","Bladel Variation","Classical Variation","Fianchetto Attack","Fianchetto Variation","Hevendehl Gambit","Hopton Attack","Hort-Antoshin System","Janzen-Korchnoi Gambit","Kingfisher Gambit","Korchnoi Attack","Krause Variation","Krejcik Gambit","Leningrad Variation","Nimzo-Dutch Variation","Normal Variation","Omega-Isis Gambit","Queen's Knight Variation","Raphael Variation","Rubinstein Variation","Semi-Leningrad Variation","Senechaud Gambit","Spielmann Gambit","Staunton Gambit","Staunton Gambit Accepted","Stonewall","Stonewall Variation","Stonewall: Chekhover Variation",],
  "Döry Defense": [],
  "East Indian Defense": [],
  "Elephant Gambit": ["Maróczy Gambit","Paulsen Countergambit","Wasp Variation",],
  "English Defense": ["Blumenfeld-Hiva Gambit","Eastbourne Gambit","Hartlaub Gambit Accepted","Hartlaub Gambit Declined","Perrin Variation","Poli Gambit",],
  "English Opening": ["Achilles-Omega Gambit","Adorjan Defense","Agincourt Defense","Anglo-Dutch Defense","Anglo-Grünfeld Defense","Anglo-Grünfeld Defense: Korchnoi Variation","Anglo-Indian Defense","Anglo-Lithuanian Variation","Anglo-Scandinavian Defense","Carls-Bremen System","Caro-Kann Defensive System","Drill Variation","Great Snake Variation","Jaenisch Gambit","King's English Variation","Mikenas-Carls Variation","Myers Variation","Neo-Catalan","Neo-Catalan Declined","Porcupine Variation","Romanishin Gambit","Symmetrical Variation","The Whale","Wade Gambit","Wing Gambit","Zilbermints Gambit",],
  "English Orangutan": [],
  "English Rat": ["Pounds Gambit",],
  "Englund Gambit": [],
  "Englund Gambit Complex": ["Englund Gambit","Felbecker Gambit","Hartlaub-Charlick Gambit","Mosquito Gambit","Soller Gambit","Soller Gambit Deferred","Stockholm Variation","Zilbermints Gambit",],
  "Englund Gambit Complex Declined": ["Diemer Counterattack",],
  "Englund Gambit Declined": ["Reversed Alekhine","Reversed Brooklyn","Reversed French","Reversed Krebs","Reversed Mokele Mbembe",],
  "Formation": ["Cabbage Attack","Hippopotamus Attack","Shy Attack",],
  "Four Knights Game": ["Alatortsev Variation","Bardeleben Variation","Double Spanish","Gunsberg Counterattack","Gunsberg Variation","Halloween Gambit","Italian Variation","Janowski Variation","Marshall Variation","Nimzowitsch (Paulsen)","Ranken Variation","Rubinstein Countergambit","Scotch Variation","Scotch Variation Accepted","Spanish Variation","Spielmann Variation","Svenonius Variation",],
  "Franco-Benoni Defense": [],
  "French Defense": ["Advance Variation","Alapin Gambit","Alekhine-Chatard Attack","Baeuerle Gambit","Banzai-Leong Gambit","Bird Invitation","Burn Variation","Carlson Gambit","Chigorin Variation","Classical Variation","Diemer-Duhm Gambit","Exchange Variation","Franco-Hiva Gambit","Franco-Hiva Gambit Accepted","Henneberger Variation","Hoffmann Gambit","Horwitz Attack","King's Indian Attack","Knight Variation","La Bourdonnais Variation","MacCutcheon Variation","Mediterranean Defense","Morphy Gambit","Normal Variation","Orthoschnapp Gambit","Paulsen Variation","Pelikan Variation","Perseus Gambit","Queen's Knight","Reversed Philidor Formation","Rubinstein Variation","Réti-Spielmann Attack","Schlechter Variation","Steiner Variation","Steinitz Attack","Steinitz Variation","Tarrasch Variation","Two Knights Variation","Winawer Variation","Wing Gambit",],
  "Fried Fox Defense": [],
  "Gedult's Opening": [],
  "Giuoco Piano": [],
  "Global Opening": [],
  "Goldsmith Defense": ["Picklepuss Defense",],
  "Grob Opening": ["Alessi Gambit","Double Grob","Grob Gambit","Grob Gambit Declined","Keene Defense","London Defense","Romford Countergambit","Spike Attack","Zilbermints Gambit",],
  "Grünfeld Defense": ["Botvinnik Variation","Brinckmann Attack","Counterthrust Variation","Exchange Variation","Flohr Defense","Flohr Variation","Gibbon Gambit","Lundin Variation","Lutikov Variation","Makogonov Variation","Opocensky Variation","Pachman Variation","Russian Variation","Smyslov Defense","Stockholm Variation","Three Knights Variation","Zaitsev Gambit",],
  "Guatemala Defense": [],
  "Gunderam Defense": [],
  "Hippopotamus Defense": [],
  "Horwitz Defense": ["Zilbermints Gambit",],
  "Hungarian Opening": ["Asten Gambit","Burk Gambit","Bücker Gambit","Catalan Formation","Dutch Defense","Indian Defense","Myers Defense","Pachman Gambit","Paris Gambit","Reversed Alekhine","Reversed Modern Defense","Reversed Norwegian Defense","Sicilian Invitation","Slav Formation","Symmetrical Variation","Van Kuijk Gambit","Wiedenhagen-Beta Gambit","Winterberg Gambit",],
  "Indian Game": ["Anti-Nimzo-Indian","Budapest Defense","Czech-Indian","Devin Gambit","Dzindzi-Indian Defense","Döry Indian","Gibbins-Wiedenhagen Gambit Accepted","Knights Variation","Lazard Gambit","London System","Maddigan Gambit","Medusa Gambit","Normal Variation","Omega Gambit","Pawn Push Variation","Polish Variation","Przepiorka Variation","Pseudo-Benko","Pyrenees Gambit","Reversed Chigorin Defense","Schnepper Gambit","Seirawan Attack","Spielmann-Indian","Tartakower Attack","Wade-Tartakower Defense","West Indian Defense",],
  "Irish Gambit": [],
  "Italian Game": ["Anti-Fried Liver Defense","Bird's Attack","Classical Variation","Deutz Gambit","Evans Gambit","Evans Gambit Accepted","Evans Gambit Declined","Giuoco Pianissimo","Giuoco Piano","Hungarian Defense","Jerome Gambit","Rosentreter Gambit","Rousseau Gambit","Schilling-Kostic Gambit","Scotch Gambit","Scotch Gambit Declined","Two Knights Defense",],
  "Kadas Opening": ["Beginner's Trap","Kadas Gambit","Myers Variation","Schneider Gambit","Steinbok Gambit",],
  "Kangaroo Defense": [],
  "King's Gambit": ["Panteldakis Countergambit","Zilbermints Double Countergambit",],
  "King's Gambit Accepted": ["Abbazia Defense","Allgaier Gambit","Australian Gambit","Basman Gambit","Becker Defense","Bishop's Gambit","Bishop's Gambit (see: Jaenisch Variation)","Blachly Gambit","Bonsch-Osmolovsky Variation","Breyer Gambit","Carrera Gambit","Cunningham Defense","Dodo Variation","Double Muzio Gambit","Eisenberg Variation","Fischer Defense","Gaga Gambit","Ghulam-Kassim Gambit","Gianutio Countergambit","Greco Gambit","Hanstein Gambit","King Knight's Gambit","King's Knight Gambit","Kotov Gambit","Lolli Gambit","MacLeod Defense","Mayet Gambit","McDonnell Gambit","Middleton Countergambit","Modern Defense","Orsini Gambit","Paris Gambit","Philidor Gambit","Polerio Gambit","Quade Gambit","Rosentreter Gambit","Rosentreter-Testa Gambit","Salvio Gambit","Schallopp Defense","Schurig Gambit","Silberschmidt Gambit","Stamma Gambit","Sörensen Gambit","Tartakower Gambit","Traditional Variation","Tumbleweed",],
  "King's Gambit Declined": ["Classical Variation","Falkbeer Countergambit","Hobbs-Zilbermints Gambit","Keene Defense","Keene's Defense","Mafia Defense","Miles Defense","Norwalde Variation","Petrov's Defense","Queen's Knight Defense","Senechaud Countergambit","Soller-Zilbermints Gambit","Zilbermints Double Gambit",],
  "King's Indian Attack": ["Double Fianchetto","French Variation","Keres Variation","Omega-Delta Gambit","Pachman System","Sicilian Variation","Smyslov Variation","Spassky Variation","Symmetrical Defense","Wahls Defense","Yugoslav Variation",],
  "King's Indian Defense": ["Accelerated Averbakh Variation","Averbakh Variation","Exchange Variation","Four Pawns Attack","Kazakh Variation","Kramer Variation","Larsen Variation","Makogonov Variation","Normal Variation","Orthodox Variation","Petrosian Variation","Pomar System","Santasiere Variation","Semi-Averbakh System","Semi-Classical Variation","Smyslov Variation","Steiner Attack","Sämisch Variation","Zinnowitz Variation",],
  "King's Knight Opening": ["Konstantinopolsky","Normal Variation",],
  "King's Pawn": [],
  "King's Pawn Game": ["Alapin Opening","Bavarian Gambit","Beyer Gambit","Busch-Gass Gambit","Damiano Defense","Dresden Opening","Gunderam Gambit","King's Head Opening","La Bourdonnais Gambit","Leonardis Variation","Macleod Attack","Maróczy Defense","McConnell Defense","Mengarini's Opening","Napoleon Attack","Nimzowitsch Defense","Pachman Wing Gambit","Philidor Gambit","Schulze-Müller Gambit","Tayler Opening","Tortoise Opening","Wayward Queen Attack","Weber Gambit",],
  "King's Pawn Opening": ["Speers","Van Hooydoon Gambit",],
  "Lasker Simul Special": [],
  "Latvian Gambit": ["Behting Variation","Clam Gambit","Corkscrew Countergambit","Corkscrew Gambit","Diepstraten Countergambit","Fraser Defense","Greco Variation","Lobster Gambit","Mason Countergambit","Mayet Attack","Mlotkowski Variation","Senechaud Gambit",],
  "Latvian Gambit Accepted": ["Bilguer Variation","Bronstein Attack","Bronstein Gambit","Foltys Variation","Foltys-Leonhardt Variation","Leonhardt Variation","Main Line","Nimzowitsch Attack",],
  "Lemming Defense": [],
  "Lion Defense": ["Anti-Philidor","Bayonet Attack","Lion's Jaw",],
  "London System": ["Poisoned Pawn Variation",],
  "Marienbad System": [],
  "Mexican Defense": ["Horsefly Gambit",],
  "Mieses Opening": ["Myers Spike Attack","Reversed Rat",],
  "Mikenas Defense": ["Cannstatter Variation","Lithuanian Variation","Pozarek Gambit",],
  "Modern Defense": ["Anti-Modern","Averbakh System","Beefeater Variation","Bishop Attack","Dunworthy Variation","Fianchetto Gambit","Geller's System","Gurgenidze Defense","Kotov Variation","Masur Gambit","Modern Pterodactyl","Mongredien Defense","Neo-Modern Defense","Norwegian Defense","Pseudo-Austrian Attack","Pterodactyl Variation","Randspringer Variation","Standard Defense","Standard Line","Three Pawns Attack","Two Knights Variation","Westermann Gambit","Wind Gambit",],
  "Montevideo Defense": [],
  "Neo-Grünfeld Defense": ["Classical Variation","Delayed Exchange Variation","Exchange Variation","Goglidze Attack","Non- or Delayed Fianchetto","Ultra-delayed Exchange Variation",],
  "Nimzo-Indian Defense": ["Classical Variation","Fischer Variation","Hübner Variation","Kmoch Variation","Leningrad Variation","Mikenas Attack","Normal Line","Normal Variation","Ragozin Defense","Reshevsky Variation","Romanishin Variation","Simagin Variation","Spielmann Variation","St. Petersburg Variation","Sämisch Variation","Three Knights Variation",],
  "Nimzo-Larsen Attack": ["Classical Variation","Dutch Variation","English Variation","Graz Attack","Indian Variation","Modern Variation","Norfolk Gambit","Pachman Gambit","Ringelbach Gambit","Spike Variation","Symmetrical Variation",],
  "Nimzowitsch Defense": ["Breyer Variation","El Columpio Defense","Franco-Hiva Gambit","Franco-Nimzowitsch Variation","French Connection","Hornung Gambit","Kennedy Variation","Lean Variation","Mikenas Variation","Neo-Mongoloid Defense","Pirc Connection","Pseudo-Spanish Variation","Scandinavian Variation","Wheeler Gambit","Williams Variation","Woodchuck Variation",],
  "Nimzowitsch-Larsen Attack": ["Polish Variation",],
  "Norwegian Defense": [],
  "Old Benoni Defense": ["Mujannah Formation",],
  "Old Indian": ["Aged Gibbon Gambit","Czech Variation",],
  "Old Indian Defense": ["Dus-Khotimirsky Variation","Janowski Variation","Normal Variation","Tartakower-Indian","Two Knights Variation","Ukrainian Variation",],
  "Owen Defense": ["Hekili-Loa Gambit","Matovinsky Gambit","Naselwaus Gambit","Smith Gambit","Unicorn Variation","Wind Gambit",],
  "Paleface Attack": [],
  "Petrov": ["Modern Attack",],
  "Petrov's Defense": [],
  "Philidor Defense": ["Alapin-Blackburne Gambit","Berger Variation","Bird Gambit","Boden Variation","Exchange Variation","Hanham Variation","Larsen Variation","Lion Variation","Lopez Countergambit","Morphy Gambit","Nimzowitsch Variation","Paulsen Attack","Philidor Countergambit","Philidor Gambit","Steinitz Variation",],
  "Pirc Defense": ["150 Attack","Austrian Attack","Bayonet Attack","Byrne Variation","Chinese Variation","Classical Variation","Kholmov System","Roscher Gambit","Sveshnikov System",],
  "Polish Defense": ["Spassky Gambit Accepted",],
  "Polish Opening": ["Baltic Defense","Birmingham Gambit","Bugayev Advance Variation","Bugayev Attack","Czech Defense","Dutch Defense","German Defense","Grigorian Variation","Karniewski Variation","King's Indian Variation","Myers Variation","Orangutan-Diemer Gambit","Orangutan-Hartlaub Gambit","Outflank Variation","Queen's Indian Variation","Queenside Defense","Rooks Swap Line","Schiffler-Sokolsky Variation","Schuehler Gambit","Symmetrical Variation","Tartakower Gambit","Wolferts Gambit","Zukertort System",],
  "Ponziani Opening": ["Caro Gambit","Jaenisch Counterattack","Leonhardt Variation","Neumann Gambit","Ponziani Countergambit","Romanishin Variation","Réti Variation","Spanish Variation","Steinitz Variation","Vukovic Gambit",],
  "Portuguese Opening": ["Miguel Gambit","Portuguese Gambit",],
  "Pterodactyl Defense": [],
  "Queen's Gambit": [],
  "Queen's Gambit Accepted": ["Accelerated Mannheim Variation","Alekhine Defense","Bogoljubov Defense","Classical Defense","Furman Variation","Godes Variation","Gunsberg Defense","Janowski-Larsen Variation","Linares Variation","Mannheim Variation","Normal Variation","Old Variation","Rosenthal Variation","Saduleto Variation","Schwartz Defense","Showalter Variation","Slav Gambit","Smyslov Variation","Winawer Defense",],
  "Queen's Gambit Declined": ["Alapin Variation","Albin Countergambit","Alekhine Variation","Anti-Tartakower Variation","Austrian Defense","Baltic Defense","Barmen Variation","Been-Koomen Variation","Cambridge Springs Defense","Cambridge Springs Variation","Capablanca","Capablanca Variation","Charousek (Petrosian) Variation","Chigorin Defense","Exchange Variation","Harrwitz Attack","Hastings Variation","Janowski Variation","Lasker Defense","Manhattan Variation","Marshall Defense","Miles Variation","Modern Variation","Neo-Orthodox Variation","Normal Defense","Orthodox Defense","Pillsbury Attack","Pseudo-Tarrasch Variation","Queen's Knight Variation","Ragozin Defense","Rochlin Variation","Semi-Tarrasch","Semi-Tarrasch Defense","Semmering Variation","Spielmann Variation","Stonewall Variation","Tarrasch Defense","Tartakower Defense","Tartakower Variation","Three Knights Variation","Traditional Variation","Uhlmann Variation","Vienna Variation","Westphalian Variation","Zilbermints Gambit",],
  "Queen's Indian Accelerated": [],
  "Queen's Indian Defense": ["Anti-Queen's Indian System","Capablanca Variation","Classical Variation","Euwe Variation","Fianchetto Traditional","Fianchetto Variation","Kasparov Variation","Kasparov-Petrosian Variation","Miles Variation","Opocensky Variation","Petrosian Variation","Riumin Variation","Spassky System","Traditional Variation","Yates Variation",],
  "Queen's Pawn": [],
  "Queen's Pawn Game": ["Anglo-Slav Opening","Anti-Torre","Chandler Gambit","Chigorin Variation","Colle System","Englund Gambit","Franco-Sicilian Defense","Hübsch Gambit","Krause Variation","Levitsky Attack","Liedmann Gambit","London System","Mason Attack","Mason Variation","Morris Countergambit","Steinitz Countergambit","Stonewall Attack","Symmetrical Variation","Torre Attack","Zilbermints Countergambit","Zukertort Variation","Zurich Gambit",],
  "Rapport-Jobava System": [],
  "Rat Defense": ["Accelerated Gurgenidze","Antal Defense","Balogh Defense","English Rat","Fuller Gambit","Harmonist","Petruccioli Attack","Small Center Defense","Spike Attack",],
  "Richter-Veresov Attack": [],
  "Robatsch (Modern) Defense": [],
  "Robatsch Defense": [],
  "Rubinstein Opening": ["Bogoljubov Defense","Classical Defense","Semi-Slav Defense",],
  "Russian Game": ["Classical Attack","Cochrane Gambit","Cozio (Lasker) Attack","Damiano Variation","French Attack","Karklins-Martinovsky Variation","Kaufmann Attack","Millennium Attack","Modern Attack","Moody Gambit","Nimzowitsch Attack","Paulsen Attack","Stafford Gambit","Three Knights Game","Urusov Gambit",],
  "Ruy Lopez": ["Alapin Defense","Berlin Defense","Bird Variation","Bird's Defense Deferred","Brentano Gambit","Brix Variation","Bulgarian Variation","Central Countergambit","Classical Variation","Closed","Closed Variations","Columbus Variation","Cozio Defense","Exchange Variation","Fianchetto Defense","Lucena Variation","Marshall Attack","Morphy Defense","Noah's Ark Trap","Nürnberg Variation","Open","Open Variations","Pollock Defense","Rabinovich Variation","Retreat Variation","Rotary-Albany Gambit","Schliemann Defense","Spanish Countergambit","Steinitz Defense","Vinogradov Variation",],
  "Réti Opening": ["Advance Variation","Penguin Variation","Reversed Blumenfeld Gambit","Réti Accepted","Zilbermints Gambit",],
  "Saragossa Opening": [],
  "Scandinavian Defense": ["Anderssen Counterattack","Blackburne Gambit","Blackburne-Kloosterboer Gambit","Boehnke Gambit","Bronstein Variation","Classical Variation","Grünfeld Variation","Gubinsky-Melts Defense","Icelandic-Palme Gambit","Kadas Gambit","Kiel Variation","Kloosterboer Gambit","Lasker Variation","Main Line","Marshall Variation","Mieses-Kotroc Variation","Modern Variation","Panov Transfer","Portuguese Variation","Richter Variation","Schiller-Pytel Variation","Zilbermints Gambit",],
  "Scotch Gambit": [],
  "Scotch Game": ["Alekhine Gambit","Benima Defense","Berger Variation","Blumenfeld Attack","Braune Variation","Classical Variation","Cochrane Variation","Cochrane-Shumov Defense","Fraser Variation","Ghulam-Kassim Variation","Gottschall Variation","Göring Gambit","Hanneken Variation","Haxo Gambit","Horwitz Attack","Lolli Variation","Malaniuk Variation","Meitner Variation","Mieses Variation","Modern Defense","Napoleon Gambit","Paulsen Attack","Paulsen Variation","Potter Variation","Relfsson Gambit","Romanishin Variation","Rosenthal Variation","Schmidt Variation","Scotch Gambit","Steinitz Variation","Tartakower Variation","Vitzthum Attack",],
  "Semi-Benoni": [],
  "Semi-Slav Defense": ["Accelerated Meran Variation","Accelerated Move Order","Anti-Moscow Gambit","Bogoljubov Variation","Botvinnik System","Botvinnik Variation","Chigorin Defense","Gunderam Gambit","Main Line","Marshall Gambit","Meran Variation","Normal Variation","Noteboom Variation","Quiet Variation","Romih Variation","Rubinstein (Anti-Meran) System","Semi-Meran Variation","Stoltz Variation","Stonewall Defense",],
  "Semi-Slav Defense Accepted": [],
  "Sicilian Defense": ["Accelerated Dragon","Acton Extension","Alapin Variation","Amazon Attack","Big Clamp Formation","Boleslavsky Variation","Bowdler Attack","Brick Variation","Brussels Gambit","Bücker Variation","Chekhover Variation","Classical Variation","Closed","Closed Variation","Coles Sicilian Gambit","Delayed Alapin","Delayed Alapin Variation","Double-Dutch Gambit","Dragon","Dragon Variation","Drazic Variation","Flohr Variation","Four Knights Variation","Franco-Sicilian Variation","Frederico Variation","French Variation","Gaw-Paw Variation","Gloria Variation","Godiva Variation","Grand Prix Attack","Grob Variation","Halasz Gambit","Heidenfeld Variation","Hyperaccelerated Dragon","Hyperaccelerated Fianchetto","Hyperaccelerated Pterodactyl","Jalalabad Variation","Kalashnikov Variation","Kan Variation","Katalimov Variation","Keres Variation","King David's Opening","Kotov Gambit","Kramnik Variation","Kronberger Variation","Kupreichik Variation","Kveinis Variation","Lasker-Dunne Attack","Lasker-Pelikan Variation","Löwenthal Variation","Magnus Smith Trap","Marshall Counterattack","Marshall Gambit","McDonnell Attack","Mengarini Variation","Modern Variations","Mongoose Variation","Morphy Gambit","Moscow Variation","Myers Attack","Najdorf Variation","Nimzo-American Variation","Nimzowitsch Variation","Nyezhmetdinov-Rossolimo Attack","O'Kelly Variation","Old Sicilian","Open","Paulsen Variation","Paulsen-Basman Defense","Pin Variation","Polish Gambit","Prins Variation","Quinteros Variation","Richter-Rauzer","Richter-Rauzer Variation","Scheveningen Variation","Smith-Morra Gambit","Smith-Morra Gambit Deferred","Snyder Variation","Sozin Attack","Spielmann Variation","Staunton-Cochrane Variation","Velimirovic Attack","Venice Attack","Wing Gambit","Wing Gambit Deferred","Yates Variation",],
  "Slav Defense": ["Alapin Variation","Alekhine Variation","Bonet Gambit","Breyer Variation","Chameleon Variation","Czech Variation","Diemer Gambit","Exchange Variation","Geller Gambit","Modern","Modern Line","Quiet Variation","Schlechter Variation","Smyslov Variation","Soultanbeieff Variation","Steiner Variation","Süchting Variation","Three Knights Variation","Two Knights Attack","Winawer Countergambit",],
  "Slav Indian": ["Kudischewitsch Gambit",],
  "Sodium Attack": ["Celadon Variation","Chenoboskion Variation","Durkin Gambit",],
  "St. George Defense": ["Polish Variation","San Jorge Variation","St. George Gambit","Zilbermints Gambit",],
  "System": ["Double Duck Formation",],
  "Tarrasch Defense": ["Classical Variation","Grünfeld Gambit","Marshall Gambit","Prague Variation","Rubinstein System","Schara Gambit","Swedish Variation","Symmetrical Variation","Tarrasch Gambit","Two Knights Variation","Wagner Variation","von Hennig Gambit",],
  "Tayler Opening": [],
  "Three Knights Opening": ["Schlechter Variation","Steinitz Defense","Steinitz-Rosenthal Variation","Winawer Defense",],
  "Torre Attack": ["Wagner Gambit",],
  "Trompowsky Attack": ["Borg Variation","Classical Defense","Edge Variation","Poisoned Pawn Variation","Raptor Variation",],
  "Valencia Opening": [],
  "Van Geet Opening": ["Battambang Variation","Berlin Gambit","Billockus-Johansen Gambit","Damhaug Gambit","Dougherty Gambit","Dunst-Perrenet Gambit","Düsseldorf Gambit","Gladbacher Gambit","Grünfeld Defense","Hector Gambit","Hergert Gambit","Hulsemann Gambit","Jendrossek Gambit","Kluever Gambit","Laroche Gambit","Liebig Gambit","Melleby Gambit","Myers Attack","Napoleon Attack","Novosibirsk Variation","Nowokunski Gambit","Pfeiffer Gambit","Reversed Nimzowitsch","Reversed Scandinavian","Sicilian Two Knights","Sleipnir Gambit","Twyble Attack","Tübingen Gambit","Venezolana Variation","Warsteiner Gambit",],
  "Van't Kruijs Opening": ["Bouncing Bishop Variation","Keoni-Hiva Gambit Delayed",],
  "Venezolana Opening": [],
  "Veresov Opening": ["Malich Gambit",],
  "Vienna Game": ["Adams' Gambit","Anderssen Defense","Falkbeer Variation","Frankenstein-Dracula Variation","Fyfe Gambit","Giraffe Attack","Hamppe-Meitner Variation","Hamppe-Muzio Gambit","Heyde Variation","Max Lange Defense","Mengarini Variation","Mieses Variation","Omaha Gambit","Paulsen Variation","Philidor Countergambit","Stanley Variation","Vienna Gambit","Zhuravlev Countergambit",],
  "Vulture Defense": [],
  "Wade Defense": [],
  "Ware Defense": ["Snagglepuss Defense",],
  "Ware Opening": ["Cologne Gambit","Meadow Hay Trap","Ware Gambit","Wing Gambit",],
  "Yusupov-Rubinstein System": [],
  "Zaire Defense": [],
  "Zukertort Defense": ["Drunken Cavalry Variation","Kingside Variation","Sicilian Knight Variation",],
  "Zukertort Opening": ["Ampel Variation","Arctic Defense","Basman Defense","Black Mustang Defense","Double Fianchetto Attack","Dutch Variation","Grünfeld Reversed","Herrstrom Gambit","Kingside Fianchetto","Lemberger Gambit","Lisitsyn Gambit","Lisitsyn Gambit Deferred","Myers Polish Attack","Nimzo-Larsen Variation","Old Indian Attack","Pachman Gambit","Pirc Invitation","Polish Defense","Queen's Gambit Invitation","Queenside Fianchetto Variation","Quiet System","Regina-Nu Gambit","Reversed Mexican Defense","Ross Gambit","Santasiere's Folly","Shabalov Gambit","Sicilian Invitation","Slav Invitation","Speelsmet Gambit","St. George Defense","Tennison Gambit","The Potato","The Walrus","Vos Gambit","Wade Defense","Ware Defense",],
};

const phases = [
  { label: 'Opening', value: 'opening' },
  { label: 'Middlegame', value: 'middlegame' },
  { label: 'Endgame', value: 'endgame' },
];

const opennesses = [
    { label: 'Closed', value: 'closed' },
    { label: 'Semi-Open', value: 'semi open' },
    { label: 'Open', value: 'open' },
  ];

const pawnStructures = [
  {label: 'Boleslavsky Wall', value: 'boleslavsky wall'},
  {label: 'Botvinnik System', value: 'botvinnik system'},
  {label: 'Carlsbad Formation', value: 'carlsbad formation'},
  {label: 'Caro Formation', value: 'caro formation'},
  {label: 'Closed Sicilian', value: 'closed sicilian'},
  {label: 'd5 Chain', value: 'd5 chain'},
  {label: 'e5 Chain', value: 'e5 chain'},
  {label: 'Giuoco Piano Isolani', value: 'giuoco piano isolani'},
  {label: 'Hanging Pawns', value: 'hanging pawns'},
  {label: 'Hedgehog', value: 'hedgehog'},
  {label: 'Maróczy Bind', value: 'maroczy bind'},
  {label: 'Modern Benoni', value: 'modern benoni'},
  {label: 'Panov Formation', value: 'panov formation'},
  {label: 'Queens Gambit Isolani', value: 'queens gambit isolani'},
  {label: 'Rauzer Formation', value: 'rauzer formation'},
  {label: 'Sicilian Boleslavsky Hole', value: 'sicilian boleslavsky hole'},
  {label: 'Sicilian Dragon', value: 'sicilian dragon'},
  {label: 'Sicilian Scheveningen', value: 'sicilian scheveningen'},
  {label: 'Slav Formation', value: 'slav formation'},
  {label: 'Stonewall', value: 'stonewall'},
];

const endgameTypes = [
  {label: 'Bishop and Pawn Mixed Colors', value: 'bishop and pawn mixed colors'},
  {label: 'Bishop and Pawn Opposite Colors', value: 'bishop and pawn opposite colors'},
  {label: 'Bishop and Pawn Same Colors', value: 'bishop and pawn same colors'},
  {label: 'Bishop vs. Knight', value: 'bishop versus knight'},
  {label: 'King and Pawn', value: 'king and pawn'},
  {label: 'Knight and Pawn', value: 'knight and pawn'},
  {label: 'Pawnless', value: 'pawnless'},
  {label: 'Pieces vs. Pawns', value: 'pieces versus pawns'},
  {label: 'Queen and Pawn', value: 'queen and pawn'},
  {label: 'Queen vs. Rook', value: 'queen versus rook'},
  {label: 'Queen vs. Rook and Minor', value: 'queen versus rook and minor'},
  {label: 'Queen vs. Two Rooks', value: 'queen versus two rooks'},
  {label: 'Rook and Pawn', value: 'rook and pawn'},
  {label: 'Rook vs. Minor', value: 'rook versus minor'},
  {label: 'Two Minors vs. Rook', value: 'two minors versus rook'},
];

const themesOptions = [
  {label: 'Connected Passed Pawns', value: 'connected_passed_pawns'},
  {label: 'Doubled Pawns', value: 'doubled_pawns'},
  {label: 'Exchange Sacrifice', value: 'exchange_sacrifice'},
  {label: 'Lead in Development', value: 'development_lead'},
  {label: 'Opposite Side Castling', value: 'opposite_side_castling'},
  {label: 'Opposition', value: 'opposition'},
  {label: 'Same Side Castling', value: 'same_side_castling'},
  {label: 'Pawn Sacrifice', value: 'pawn_sacrifice'},
  {label: 'Piece Sacrifice', value: 'piece_sacrifice'},
  {label: 'Queen Sacrifice', value: 'queen_sacrifice'},
];

export {
  channels,
  openings,
  phases,
  opennesses,
  pawnStructures,
  endgameTypes,
  themesOptions,
};
