import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Result from './Result';

const useStyles = makeStyles(theme => ({
  container: {
  },
  resultWrapper: {
    paddingBottom: '1rem',
  },
  empty: {
    paddingBottom: '1rem',
    textAlign: 'center',
  },
  emptyHeader: {
    fontSize: '1.5rem',
  }
}));

const Results = ({ results }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      { results.length === 0 && (
        <div className={classes.empty}>
          <Typography className={classes.emptyHeader}>
            No results, try different searches.
          </Typography>
          <Typography variant="body1">
            As we plan to contantly analyze new videos from YouTube, the results might appear here in the future.
          </Typography>
        </div>
      )}
      { results.length > 0 && results.map(e => {
        const key = `${e.video.id}#${e.timestamp}`;
        return (
          <div key={key} className={classes.resultWrapper}>
            <Result {...e} />
          </div>
        );
      })}
    </div>

  );
}

export default Results;
