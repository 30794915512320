import { createTheme } from '@material-ui/core/styles';

import settings from './settings';

export const getTheme = mode => createTheme({
  typography: {
    h2: {
      [`@media (max-width: ${settings.values.maxMobileWidth}px)`]: {
        fontSize: '2.75rem',
      },
    },
    body1: {
      [`@media (max-width: ${settings.values.maxMobileWidth}px)`]: {
        fontSize: '0.9rem',
      },
    },
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        primary: {
          main: '#007bff',
        },
        secondary: {
          main: '#d64f00',
        },
        success: {
          main: '#2e7d32',
        },
        background: {
          default: '#eeecea',
          paper: '#fff',
        },
        gray: {
          main: '#828282',
          dark: '#282828',
        },
      }
      : {
        primary: {
          main: '#007bff',
        },
        secondary: {
          main: '#d64f00',
        },
        success: {
          main: '#66bb6a',
        },
        background: {
          default: '#161512',
          paper: '#262421',
        },
        gray: {
          main: '#828282',
          dark: '#282828',
        },
        text: {
          primary: '#fff',
          secondary: '#999',
          disabled: '#666',
          icon: '#fff',
        },
        action: {
          active: '#828282',
          disabled: '#666',
          disabledBackground: '#333',
        },
      }),
  },
});
